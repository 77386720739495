import React from 'react';
import {toolkitData} from './HomeData';
import ExtensionImg from '../assets/revampHomeassets/ExtensionImg.svg';
import ServiceCards from './ServiceCards';


const ToolkitPage = () => {
    
  return (
    <>
    <div className="toolkitPage w-100 container-fluid p-5">
      <h2 className="text-center blueGradient mt-4 mb-4 fs-1">Your all-in-one lead generation platform</h2>
      <p className="text-center text-secondary mb-5 fs-5">
        DiscoveMail swiftly reveals the necessary contact details for prominent decision-makers in a time-effective manner.
      </p>
      <div className="lg-px-5 px-0">
      <div className="row ">
        <ServiceCards toolkitdata={toolkitData}  />
      </div>
      </div>

      <div className="extension container rounded-2 my-5 bg-white p-2">
       <div className=" extensionInner p-4">
        <div className="row ">
            <div className="col-lg-3">
            <img src={ExtensionImg} alt="ExtensionImg" className='img-fluid' />
            </div>
            <div className="col-lg-9 ">
                <h3>Take discovemail with you.</h3>
                <p className='text-secondary'>Uncover email addresses associated with the LinkedIn profiles you're viewing in just one click.</p>
                <div className="row">
              <div
                className="d-flex col-lg-4 generalBtn rounded-2 p-1"
                id="general"
              >
                <a
                  className="btn heroBtn text-white fs-5 fw-bolder w-100 px-5 py-2 "
                  href="https://chrome.google.com/webstore/detail/discovemail/hbfdhianoohibldpghooaeeamjhcjhjn" target="_blank"
                >
                  Add to chrome
                </a>
              </div>
            </div>
            </div>
        </div>
       </div>
      </div>
    </div>
    </>
  )
}

export default ToolkitPage