import { useEffect } from 'react';
import Chibi from '../assets/revampHomeassets/Chibi.svg';
import Horn from '../assets/revampHomeassets/Horn.svg';
import Lens from '../assets/revampHomeassets/Lens.svg';
import Post from '../assets/revampHomeassets/Post.svg';
import Target from '../assets/revampHomeassets/Target.svg';
import UpArrow from '../assets/revampHomeassets/UpArrow.svg';
import AOS from 'aos';
import 'aos/dist/aos.css';


const Elevate = () => {
    useEffect(()=>{
        AOS.init({duration:1000})
    })
  return (
    <>
    <div className="container-fluid p-5">
        <div className=" elevateDiv w-100 rounded-3 p-5">
        <div className="row">
            <div className="col-lg-3 d-none d-lg-block d-flex flex-column py-5 ">
            <div className='ms-5'  data-aos="fade-up-left"><img src={Horn} alt={Horn} /></div>
            <br />
            <div  data-aos="fade-left"><img src={Target} alt={Target} /></div>
            <br />
            <div className='ms-5'  data-aos="fade-down-left" ><img src={Post} alt={Post} /></div>    
            </div>
            <div className="col-lg-6 d-flex flex-column justify-content-center align-items-center col-12 text-center text-white">
                <h1 className='fs-1 fw-normal lh-base' >
                    <span>Elevate your outreach with </span>
                    <br />
                    <span>targeted campaigns</span>
                </h1>
                <p className='mt-3 lh-base'>Unlock your full potential with our powerful campaign management tools. Craft personalized emails, schedule follow-ups, and analyze performance for maximum impact.</p>
                <a className='btn-primary serviceBtn text-decoration-none rounded-2 mt-3 px-5 py-2' href='/signup' >Get Started</a>
            </div>
            <div className="col-lg-3 d-none d-lg-block">
            <div className="col-lg-3 d-flex flex-column w-100 align-items-end">
            <div className='me-5'  data-aos="fade-up-right"><img src={UpArrow} alt={UpArrow} /></div>
            <br />
            <div  data-aos="fade-right"><img src={Chibi} alt={Chibi} /></div>
            <br />
            <div className='me-5' data-aos="fade-down-right" ><img src={Lens} alt={Lens} /></div>    
            </div>
            </div>
        </div>
        </div>
    </div>
    </>
  )
}

export default Elevate