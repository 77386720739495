import React from "react";
import Navbar from "../../new_home/Navbar_1";
import Footer from "../../new_home/Footer";
import { Helmet } from "react-helmet";
import { MdAddCircleOutline } from "react-icons/md";

import "./Google-sheet-add-on.css";

import SheetData from "../../assets/sheet_data.png";

export default function GoogleSheetAddOn() {
  return (
    <>
      <Helmet>
        <title>Google Sheet Add on | Discovemail - Smart email verification tool for finding and checking emails</title>

        <meta
          name="description"
          content="The Ultimate Email Finder and Verification Tool. Verify, check, and validate email addresses instantly with our top-notch emailid checker."
        />

        <meta
          name="keywords"
          content=" LinkedIn Email Finder,verified email addresses,discovemail,email,linkedin,emailfinder,get email,search email,location finder,linkedin details,
          linkedin user details,linkedin id "
        />
      </Helmet>
      <Navbar />

      <div className="container">
        <div className="row">
          <div className="google-sheet-addon-area">
            <h1 className="google-sheet-addon-area__title">
              Discove mail for Google sheets
            </h1>
            <h3 className="text-secondary">
              Export verified email address directly to CSV.
            </h3>

            <a className="btn-animate" href="/dashboard">
              Got to add on <MdAddCircleOutline />
            </a>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-md-10 my-3">
            <img
              src={SheetData}
              alt="Discove mail sheet add on data"
              className="w-100 p-2 rounded-1 shadow-1"
            />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
