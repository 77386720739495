import React, { useEffect, useState } from 'react'
import Navbar from '../../pages/dashboard/Navbar';
import Loader from "../loader/Loader";
import requestInstance from "../../services/request";
import { API_ENDPOINT } from "../../services/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './Subscription.css'
import moment from 'moment';
import Spinner from "../../pages/loader/SimpleLoader";
import { planStatus } from '../../redux/slices/user/UserSlice';
import { useDispatch, useSelector } from 'react-redux';


export default function Subscription() {

  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [currentPlan, setCurrentPlan] = useState("");
  const dispatch = useDispatch();
  const planData = useSelector((state)=>state?.user?.planStatus);
  const [expiryDateString, setexpiryDateString] = useState("");
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    handleSubscribe();
  }, [])
  
  const RenderFundingData = () => {
    const d = new Date("2023-12-16T12:43:19.489940+05:30");
  }

  const handleSubscribe = () => {
    setLoading(true);
    requestInstance
      .get(API_ENDPOINT.ACTIVE_PLAN_STATUS)
      .then((res) => {
        setLoading(false);
        // console.log(res);
        dispatch(planStatus(res?.data?.data));
        RenderFundingData(res?.data?.data?.user_data?.expiry_date);
        setCurrentPlan(res?.data?.data.user_data.plan.plan_type);
        setexpiryDateString(res?.data?.data.user_data.expiry_date)
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  useEffect(()=>{
    const checkExpiryDate = () => {
      const expiryDate = new Date(expiryDateString);
      const currentDate = new Date();
      if (currentDate > expiryDate) {
        setIsExpired(true);
      } else {
        setIsExpired(false);
      }
    };

    checkExpiryDate();
    const interval = setInterval(checkExpiryDate, 1000 * 60 *60);

    return () => clearInterval(interval);
  },[expiryDateString])


  return (
    <>
      <Navbar />
      {loading ? <Spinner /> : <div className='SubscriptionPage'>
        <div className=' container p-5'>
          <div className='row'>
            <div className='col-lg-12 bg-white p-4'>
              <h1 className='mainTitle'>Subscription</h1>
              <h4 className='subTitle text-capitalize'>{planData?.user_data?.plan?.plan_type} Plan -  <span className={planData?.user_data?.is_plan_active === true ? 'badge rounded-pill text-bg-success fw-bold' : 'badge rounded-pill text-bg-danger fw-bold'}>{planData?.user_data?.is_plan_active === true ? 'Active' : 'InActive'}</span></h4>
              <div className=' d-flex  flex-row justify-content-between'>
                <div>
                  <h5 className='subTitle2'>Limit</h5>
                  <p className='txt mb-0'>{planData?.user_data?.plan?.domain_credit_limit} Domain searches</p>
                  <p className='txt mb-0'>{planData?.user_data?.plan?.email_credit_limit} Email searches</p>
                  <p className='txt mb-0'>{planData?.user_data?.plan?.nametoemail_credit_limit} Name and Email searches</p>
                  <p className='txt mb-0'>{planData?.user_data?.plan?.linkedin_credit_limit} LinkedIn searches</p>
                  <p className='txt mb-0'>{planData?.user_data?.plan?.campaign_credit_limit} Campaign</p>
                </div>
                <div>
                  <h5 className='subTitle2'>Price</h5>
                  <p className='txt mb-0'>{planData?.user_data?.plan?.plan_amount}</p>
                </div>
                <div className="text-center">
                  <a
                    className="mx-3 mb-2 btn btn-discove text-capitalize"
                    href="/dashboard/price1"
                  >
                    {!isExpired && currentPlan!=='free' ? currentPlan : "Plan Update"}
                  </a>
                </div>
              </div>
              <h4 className='subTitle mt-2'>Subscription Status</h4>
              <p className='txt'>Plan expires on {moment(planData?.user_data?.expiry_date).format('MMMM Do YYYY, h:mm:ss a')} (India Standard Time - Kolkata).</p>

              <h4 className='subTitle mt-2'>Support</h4>
              {/* <p className='txt'>{planData?.user_data?.plan?.user_support}</p> */}
              <p className='txt'><a target='_blank' href="mailto: contact@discovemail.com" >contact@discovemail.com</a></p>
            </div>
          </div>
        </div>
      </div>}
    </>
  )
}
