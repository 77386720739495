import React, { useState } from 'react'
import './contactUsSection.css'
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Loader from "../../pages/loader/Loader";
import requestInstance from "../../services/request";
import { API_ENDPOINT } from "../../services/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = "Please enter the email address";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.message) {
    errors.message = "Please write a message";
  } else if (values.message[0].includes(" ")) {
    errors.message = "Must not include space at start";
  }

  return errors;
};

export default function ContactUsSection() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const navigateToEmail = () => {
    navigate("/email-verifier");
    window.scroll(0, 0);
  }

  const formik = useFormik({
    initialValues: {
      email: "",
      message: "",
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      setButtonDisabled(true);
      handleContactUs(values, { resetForm });
    },
  });

  const handleContactUs = (values, { resetForm }) => {
    
    let data = JSON.stringify({
      email: values.email,
      message: values.message,
      data_type:"with_message",
    });
    requestInstance
      .post(API_ENDPOINT.CONTACT_API, data)
      .then((res) => {
        if (res.status == 200) {
          toast.success(res.data.status); 
          setLoading(false);
          resetForm();
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };


  return (
    <section className='contactUsSection py-md-5 py-lg-5 py-2 fontInter'>
      <div className='container py-5'>
        <div className='row justify-content-center'>
          <div className='col-12 col-lg-5 align-self-center mb-lg-0 mb-5'>
            <h5 className='titleText mb-4'>Our Founder and CEO is talking About Discovemail</h5>
            
            <p className='subTitleText mb-4'>Discovemail is your all-in-one email outreach platform. Find and connect with the people that matter to your business.</p>
            <img src='/pic/contact/break.svg' alt='img not found' className='img-fluid mb-4' />
            <p className='subTitleText mb-4'>Harsh Vardhan Singh</p>
            <img src='/pic/contact/contactPerson.png' alt='img not found' className='img-fluid' />
          </div>
          <div className='col-12 col-lg-6'>
            <div className='formSection px-md-5 py-md-5 px-3 py-4 mx-md-4'>
              <div className='text-center'>
                <img src='/pic/contact/DiscoveMail Logo.svg' alt='img not found' className='img-fluid mb-3' />
                <h6 className='getStartTxt mb-4'>Get Started</h6>
              </div>
              <div className='row justify-content-center'>
                <div className='col-lg-10 col-md-10 col-12'>
                  <form onSubmit={formik.handleSubmit} className="">
                    <div className='formControls'>
                      <p className='formLabel mb-3'>Email</p>
                      <input
                        type='email'
                        id="email"
                        name="email"
                        placeholder='Enter your email'
                        className='w-100 mb-3'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div className="errormsg">{formik.errors.email}</div>
                      ) : null}

                      <p className='formLabel mb-3'>Message</p>
                      <textarea
                        type='text'
                        id="message"
                        name="message"
                        placeholder='Message...'
                        className='w-100 mb-3'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.message}
                      />
                      {formik.touched.message && formik.errors.message ? (
                        <div className="errormsg">{formik.errors.message}</div>
                      ) : null}

                      <button
                        className="requestMemoBtn border-0 w-100"
                        type="submit"
                        disabled={loading}
                      >{loading ? <Loader /> : <>Request Demo</>}</button>
                      <p className='freeTrailTxt text-end mt-3'><span className='orTxt'>or</span> <span className='cursorPointer' onClick={() => navigateToEmail()}>Start Free Trial</span></p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
