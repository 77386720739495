import React from 'react';
import './Homeravamp.css';
import { feedBackData } from '../resource/data/feedback';
import Clientcarousel from './Clientcarousel';

const Testimonials = () => {
    
  return (
    <div className='p-5 testimonials'>
        <Clientcarousel  />
    </div>
  )
}

export default Testimonials