import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { BiLinkExternal } from "react-icons/bi";
import Loader from "pages/loader/Loader";

export default function CompanyOverview({ name, phone, email, info, onClose }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (info) {
      setLoading(false); // Stop loading when info is available
    }
  }, [info]);

  const RenderPhone = () => {
    return phone?.map((num) => (
      <a href={`tel:${num}`} key={num} className="d-inline-block mx-1">
        {num},
      </a>
    ))
  };

  
  const RenderEmail = () => {
    return email?.map((email) => (
      <a href={`mailto:${email}`} key={email} className="d-inline-block mx-1">
        {email}
      </a>
    ))
  };
  

  const RenderSpecialities = (specialities) => {
    if (specialities?.length) {
      return specialities?.map((specality) => (
        <li className="list-group-item" key={specality}>
          {specality}
        </li>
      ));
    } else {
      return <p>Not Found</p>;
    }
  };

  const RenderLocations = (location) => {
    if (location.length === 0) {
      return "";
    } else {
      return location?.map((location, index) => (
        <li className="list-group-item" key={index}>
          <strong>
            {location.city}, {location.geographicArea}, {location.country}
          </strong>
          <br />
          {location.line1}
          <br />
          {location.postalCode}
        </li>
      ));
    }
  };

  const RenderHeadQuarters = (place) => {
    return (
      <small className="text-small">
        {place.city} {place.geographicArea} {place.line1} {place.line2 || " "}{" "}
        {place.postalCode}
      </small>
    );
  };

  const RenderFundingData = (data) => {
    let formattedDate;
    if (!!Object.keys(data.announcedOn).length) {
      const date = data.announcedOn;

      formattedDate = new Date(
        date.year,
        date.month - 1,
        date.day
      ).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    }

    const RenderInvesters = (data) => {
      if (data.length === 0) {
        return "N/A";
      }
      return (
        <ul>
          {data.map((investor) => {
            return (
              <li key={investor.name}>
                <a href={`${investor.investorsCrunchbaseUrl}`}>
                  {investor.name}
                </a>
              </li>
            );
          })}
        </ul>
      );
    };

    return (
      <>
        <ul>
          <li>Announced: {formattedDate || "N/A"}</li>
          <li>Type: {data.fundingType || "N/A"}</li>
          <li>Lead investers: {RenderInvesters(data.leadInvestors)}</li>
          <li>
            Money raised: {data.moneyRaised.amount || "N/A"}{" "}
            {data.moneyRaised.currencyCode || ""}
          </li>
          <li>
            {name} crunch base url:{" "}
            {data.fundingRoundListCrunchbaseUrl ? (
              <a href={`${data.fundingRoundListCrunchbaseUrl || ""}`}>
                Click here
              </a>
            ) : (
              "N/A"
            )}
          </li>
          <li>
            Investor crunch base url:{" "}
            {data.investorsCrunchbaseUrl ? (
              <a href={`${data.investorsCrunchbaseUrl || ""}`}>Click here</a>
            ) : (
              "N/A"
            )}
          </li>
          <li>Funding Round: {data.numFundingRounds || "N/A"}</li>
          <li>Number of other investors: {data.numOtherInvestors || "N/A"}</li>
        </ul>
      </>
    );
  };

  return (
    <>
    {loading ? <div className="d-flex justify-content-center">
      <Loader/>
      </div> : (
      <div className="container mt-4">
        <div className="card mb-2">
          <div className="card-body">
            <>
              <div className="d-flex justify-content-between text-primary">
                <h5 className="card-title">
                  {info?.company_name ? (
                    <>
                      {info?.universal_name.charAt(0).toUpperCase() +
                        info?.universal_name.slice(1)}
                      ,<small> ({info?.company_type}) </small> |
                      {info?.industries ? info?.industries[0] : ""}
                    </>
                  ) : (
                    name
                  )}
                </h5>
                <h5 className="card-title">
                  Founded on: {info?.founded_on || "N/A"}
                </h5>
              </div>
              <h6 className="card-subtitle mb-2 text-secondary">
                {info?.tagline}
              </h6>

              <h6 className="card-subtitle mb-2 text-muted">
                --{" "}
                {info?.headquarter ? RenderHeadQuarters(info?.headquarter) : ""}
              </h6>

              <h6 className="card-subtitle mb-1 fs-5 text-dark mt-3">
                Description:
              </h6>

              <p className="card-text">{info?.description || "N/A"}</p>

              <div className="row">
                <div className="col-md-6">
                  <h6 className="card-subtitle mb-2 fs-5 text-dark">
                    Specialities:
                  </h6>
                  <ul className="list-group">
                    {info?.specialities
                      ? RenderSpecialities(info?.specialities)
                      : "N/A"}
                  </ul>
                </div>
                <div className="col-md-6">
                  <h6 className="card-subtitle mb-2 fs-5 text-dark">
                    Overview
                  </h6>
                  <ul className="list-group">
                    <li className="list-group-item">
                      Company rank:
                      {info?.rank_for_top_companies || " N/A"}
                    </li>
                    <li className="list-group-item">
                      Staff count: {info?.staff_count} (
                      {info?.staff_count_range?.start} -
                      {info?.staff_count_range?.end})
                    </li>
                    <li className="list-group-item">
                      Staffing company:
                      {info?.staffing_company ? " Yes" : " No"}
                    </li>
                    <li className="list-group-item">
                      Paid company:
                      {info?.paid_company ? " Yes" : " No"}
                    </li>
                    <li className="list-group-item">
                      Funding:
                      {info?.funding_data
                        ? RenderFundingData(info?.funding_data)
                        : ""}
                    </li>
                    <li className="list-group-item">
                      <a
                        href={info?.company_page_url || "#"}
                        target="_blank"
                        className="inline-block me-2 text-decoration-none"
                      >
                        Website <BiLinkExternal />
                      </a>

                      <a
                        href={info?.job_search_page_url || "#"}
                        target="_blank"
                        className="inline-block me-2 text-decoration-none"
                      >
                        Job portal page <BiLinkExternal />
                      </a>

                      <a
                        href={info?.linkedin_url || "#"}
                        target="_blank"
                        className="inline-block me-2 text-decoration-none"
                      >
                        LinkedIn Profile <BiLinkExternal />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12 mt-3">
                  <h6 className="card-subtitle mb-2 text-dark fs-5">
                    Contact Information
                  </h6>
                  <ul className="list-group">
                    <li className="list-group-item">
                      Phone:
                      <a
                        href={`tel:${info?.phone_number || "#"}`}
                        className="d-inline-block mx-1"
                        style={
                          info?.phone_number
                            ? { display: "inline-block" }
                            : { display: "none" }
                        }
                      >
                        {info?.phone_number}
                      </a>
                      {RenderPhone()}
                    </li>
                    <li className="list-group-item">
                      Email:
                      {email.length !== 0 ? <RenderEmail /> : " Not Found"}
                    </li>
                  </ul>
                </div>
              </div>

              <h6 className="card-subtitle mt-4 mb-2 text-dark fs-5">
                Locations:
              </h6>
              <ul className="list-group default270pxHeight">
                {info?.confirmed_locations?.length > 0
                  ? RenderLocations(info?.confirmed_locations)
                  : "Not Found"}
              </ul>
            </>
            <div className="text-end mt-3">
              <Button onClick={() => onClose(false)} variant="primary">
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>
      )}
    </>
  );
}
