import moment from "moment";

export const log = (info1, info2) => {
    if (window.location.host.includes("localhost")) {
      // console.log(info1, info2);
    }
  };
  
  export const getCurrentUser = () => {
    let user = null;
    try {
      user =
        localStorage.getItem("find_email") != null
          ? localStorage.getItem("find_email")
          : null;
    } catch (error) {
      log(">>>>: src/Utils/Utils.js  : getCurrentUser -> error", error);
      user = null;
    }
    return user;
  };
  
  export const setCurrentUser = (user) => {
    try {
      if (user) {
        log("USER:", user);
        localStorage.setItem("find_email", JSON.stringify(user));
      } else {
        localStorage.removeItem("find_email");
      }
    } catch (error) {
      log(">>>>: src/Utils/Utils.js : setCurrentUser -> error", error);
    }
};

export const formatMobileNumber = (phonenumber, countryCode) => {
  const processMobileNumber = phonenumber.split('+' + countryCode.dialCode)
  return ('+' + countryCode.dialCode + '-' + processMobileNumber[1]).toString()
}

export const formatDate = (date) => {
  const day = date?.getDate().toString().padStart(2, '0');
  const month = (date?.getMonth() + 1).toString().padStart(2, '0');
  const year = date?.getFullYear();
  return `${year}-${month}-${day}`;
};

export const getDateFormat = (value, type) => {
  switch (type) {
    case "fromNowFormat":
      return moment(value).fromNow();
    case "reservationDateFormat":
      return moment(value).format("MM/DD/YYYY");
    case "onboardingDateFormat":
      return moment(value).format("YYYY-MM-DD");
    case "tenureDateFormat":
      return moment(value).format("MM-DD-YYYY");
    case "dashboardTable":
      return moment(value).format("DD-MM-YYYY");
    default:
      return moment(value).format("DD/MM/YYYY");
  }
};
  
export const appRoot = "/app";