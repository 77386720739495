import React, { useEffect, useState } from 'react';
import noData from '../../../assets/no-data.jpg';
import '../Campaign.css';
import { useNavigate } from 'react-router-dom';
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import requestInstance from '../../../services/request';
import { API_ENDPOINT } from '../../../services/Api';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';


export default function CampaignAudience({handleAudience, handleAllSelectLeads}) {
  const [allLeadList, setAllLeadList] = useState([]);
  const [customLeadInput, setCustomLeadInput] = useState('');
  const [leadCount, setLeadCount] = useState(0);
  const [leadPreview, setLeadPreview] = useState(false);
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  const [checkedValue, setCheckedValue] = useState(false);
  const [pending, setPending] = useState(true);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const navigate = useNavigate();
  const [isToggled, setIsToggled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [nextData, setNextData] = useState();
  const [prevData, setPrevData] = useState();
  const [mailList, setMailList] = useState('');
  const [newMailList, setNewMailList] = useState([]);
  const [buttonState, setButtonState] = useState(true);
  const [selectData, setSelectData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [listName, setListName] = useState(null);


    const leadsNameListData = () => {
      requestInstance
        .get(API_ENDPOINT.LEAD_NAME_LIST)
        .then((res) => {
          setSelectData(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    const handleNewMailList = async () => {
      const separatedArray = mailList
        .split(',')
        .map(item => item.trim())
        .filter(item => item !== '');

        setNewMailList(separatedArray);

        let postData = {
          "leads_name" : listName,
          "emails": separatedArray,
        }
        
        try {
          const response = await requestInstance.post(API_ENDPOINT.CAMPAIGN_MANUAL_LEAD_CREATE, postData);
          leadsNameListData();   
          
          response?.data?.data?.map((item) => {
            if (item.status) {
              toast.success(item.message);
            } else {
              toast.info(item.message);
            }
          })

          setListName('');
          setMailList('');
        } catch (error) {
          toast.error(error.response.data.message);
        }
    };
    

    const columns = [
      {
        name: 'Email',
        selector: row => row.email,
        sortable: true,
      },
      {
          name: 'First name',
          selector: row => row.first_name,
          sortable: true,
      },
      {
          name: 'Last name',
          selector: row => row.last_name,
          sortable: true,
      },
      {
          name: 'Company',
          selector: row => row.company_name,
          sortable: true,
      },
      {
          name: 'Sending status',
          selector: row => row.sending_status,
          sortable: true,
      },
  ];

  const columnsMain = [
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true,
    },
    {
        name: 'First name',
        selector: row => row.first_name,
        sortable: true,
    },
    {
        name: 'Last name',
        selector: row => row.last_name,
        sortable: true,
    },
    {
        name: 'Company',
        selector: row => row.company_name,
        sortable: true,
    },
    {
        name: 'Actions',
        // selector: row => row.designation,
        button: true,
        width:'76px',
    },
];

   //   ---API for leads list details---
  const allLeadsListData = () => {
    requestInstance
      .get(API_ENDPOINT.LEAD_DATA_FETCH + `?&limit=${10}&offset=${0}`)
      .then((res) => {
        setLeadCount(res.data.count);
        setAllLeadList(res.data.results);
        setTotalRows(res.data.count);
        setData(res.data.results);
        setPending(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const selectLeadsListData = () => {
    console.log(customLeadInput, "customLeadInput")
    requestInstance
      .get(API_ENDPOINT.LEAD_DATA_FETCH + '?leads_name=' + customLeadInput + `&limit=${10}&offset=${0}` )
      .then((res) => {
        setLeadCount(res.data.count);
        setAllLeadList(res.data.results);
        setTotalRows(res.data.count);
        setData(res.data.results);
        setNextData(res.data.next);
        setPrevData(res.data.next);
        setPending(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSelectedRowsChange = (selectedRows) => {
    setSelectedRecipients(selectedRows);
    handleAudience(selectedRows);
  };


  const fetchUsers = async page => {
    setLoading(true);
    console.log(customLeadInput, "customLeadInput")
    let apiPath = '';
    if (customLeadInput === '' || customLeadInput === 'all-leads') {
      apiPath = API_ENDPOINT.LEAD_DATA_FETCH + `?&limit=${10}&offset=${0}`;
    } else {
      apiPath = API_ENDPOINT.LEAD_DATA_FETCH + '?leads_name=' + customLeadInput + `&limit=${10}&offset=${0}`
    }
    const response = await requestInstance.get(apiPath);
    setNextData(response.data.next);
    setPrevData(response.data.previous);
    setAllLeadList(response.data.results);
    setData(response.data.results);
    setLeadCount(response.data.count);
		setTotalRows(response.data.count);
    setCurrentPage(1)
		setLoading(false);
	};


  const handlePageChange = async (page) => {
    setLoading(true);
    let nextPageData = nextData;
    let prevPageData = prevData;
  
    if (page > currentPage) {
      // Going to the next page
      if (nextPageData) {
        const response = await requestInstance.get(nextPageData);
        setData(response.data.results);
        setAllLeadList(response.data.results);
        setLeadCount(response.data.count);
        setTotalRows(response.data.count);
        setCurrentPage(page);
        nextPageData = response.data.next;
        prevPageData = response.data.previous;
      }
    } else if (page < currentPage) {
      // Going to the previous page
      if (prevPageData) {
        const response = await requestInstance.get(prevPageData);
        setData(response.data.results);
        setAllLeadList(response.data.results);
        setLeadCount(response.data.count);
        setTotalRows(response.data.count);
        setCurrentPage(page);
        nextPageData = response.data.next;
        prevPageData = response.data.previous;
      }
    }
  
    setNextData(nextPageData);
    setPrevData(prevPageData);
  
    setLoading(false);
  };
  

	const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);

    let newOffset = 0; // Set the offset to 0 for the first page
    if (page > 1) {
        newOffset = (page - 1) * newPerPage; // Calculate new offset based on the current page and new per page count
    }
    let apiPath = '';
    if (customLeadInput === '' || customLeadInput === 'all-leads') {
      apiPath = API_ENDPOINT.LEAD_DATA_FETCH + `?&limit=${newPerPage}&offset=${newOffset}`;
    } else {
      apiPath = API_ENDPOINT.LEAD_DATA_FETCH + '?leads_name=' + customLeadInput + `&limit=${newPerPage}&offset=${newOffset}`;
    }

    const response = await requestInstance.get(apiPath);
    setData(response.data.results);
    setAllLeadList(response.data.results);
    setLeadCount(response.data.count);
    setPerPage(newPerPage);
    setCurrentPage(page); // Update the current page

    // Update nextData and prevData based on the new page size
    if (response.data.next) {
        setNextData(response.data.next);
    } else {
        setNextData(null);
    }

    if (response.data.previous) {
        setPrevData(response.data.previous);
    } else {
        setPrevData(null);
    }

    setLoading(false);
};


  const handleSelectAllLeads = () =>{
    setIsToggled(!isToggled);
    
    handleAllSelectLeads(!isToggled);
  }

  const buttonColor = isToggled ? '#e75a5a' : 'green';

  useEffect(() => {
		fetchUsers(1); // fetch page 1 of users
  }, []);

  useEffect(() => {
    if (customLeadInput) {
      selectLeadsListData();
    }
    else { allLeadsListData() }
  }, [customLeadInput])
  
  useEffect(() => {    
    leadsNameListData();      
  }, [])
  

  const customRowsPerPageOptions = [10, 25, 50, 100, 200];


  return (
    <>
     <div className='col-12 align-self-center'>
     {data && leadPreview ? 
             <div className='mt-3'>
             {allLeadList.length > 0 ? <div className='d-flex gap-3 align-items-center'>
                <h5 className='text-dark fw-bold'>{leadCount} recipients</h5>
                <button className='btn' style={{ backgroundColor: buttonColor, color: 'white' }} onClick={()=>{handleSelectAllLeads()}}>{isToggled ?  'Deselect All'  :`Select All ${leadCount}`}</button>
              </div> : null}
              {selectedRecipients?.selectedCount > 0 ? <small className='text-dark fw-bold'>
              {selectedRecipients?.selectedCount} recipients selected</small> : null}
             <DataTable
              columns={columnsMain}
              data={data}
              progressPending={loading}
              selectableRows
              onSelectedRowsChange={handleSelectedRowsChange}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              // below line for custom rows per
              paginationRowsPerPageOptions={customRowsPerPageOptions}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
             />
           </div> : 
        <div className='d-flex flex-column justify-content-center container align-items-center'>
            <div>
                <img className='noDataImg img-fluid' src={noData} alt="No Data Found" />
            </div>
            <div className='text-center'>
                <h4 className='fw-bold'>Add people to the campaign</h4>
                <p>Choose recipients from your leads or manually select them. You have the option to include additional individuals at a later time.</p>
                <button className='btn themeRed' data-bs-toggle="modal" data-bs-target="#exampleModal">Add people to the campaign</button>
            </div>
        </div>
          }
      </div>  

      {/* <!-- First Modal starts --> */}
      <div class="modal fade campaign" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body p-5 pb-5">
              <h2 class="modal-title fs-3 pb-5" id="exampleModalLabel">Add people to the campaign</h2>
              <div className='row'>
                  <div className='col-4 hoverColor p-4' data-bs-toggle="modal" data-bs-target="#exampleModal1" onClick={allLeadsListData} >
                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="#E75A5A" class="bi bi-people mb-3" viewBox="0 0 16 16">
                      <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8Zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816ZM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"/>
                    </svg>
                      <h6 className='text-dark fw-bold'>From leads</h6>
                      <p>Select leads already saved in Discovemail</p>
                  </div>
                  <div className='col-4 hoverColor p-4' data-bs-dismiss="modal" aria-label="Close" onClick={()=>navigate('/lead/import-leads')}>
                    <svg xmlns="http://www.w3.org/2000/svg"  width="64" height="64" fill="#E75A5A" class="bi bi-file-earmark-arrow-up mb-3" viewBox="0 0 16 16">
                      <path d="M8.5 11.5a.5.5 0 0 1-1 0V7.707L6.354 8.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 7.707V11.5z"/>
                      <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/>
                    </svg>
                      <h6 className='text-dark fw-bold'>Upload a Csv</h6>
                      <p>Import leads with attributes.</p>
                  </div>
                  <div className='col-4 hoverColor p-4' data-bs-toggle="modal" data-bs-target="#exampleModal2" >
                      <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="#E75A5A" class="bi bi-pen mb-3" viewBox="0 0 16 16">
                        <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z"/>
                      </svg>
                      <h6 className='text-dark fw-bold'>Manually</h6>
                      <p>Create leads from a list of email addresses</p>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* First Modal Ends  */}
      {/* Lead Modal starts */}

      <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header gap-2">
            <BsFillArrowLeftCircleFill className='cursorPointer' data-bs-toggle="modal" data-bs-target="#exampleModal" />
              <h6 class="modal-title fw-bold" id="exampleModalLabel">Add people from my leads</h6>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div className="input-group w-25 mb-4">
                <select
                  className="form-select"
                  id="select-example"
                  name="industry"
                  placeholder="Select lead list"
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    setCustomLeadInput(selectedValue);
                  }}
                  value={customLeadInput}
                >
                  <option value="">All lead list</option>
                  {selectData?.map((val) => (
                    <option key={val.id} value={val.leads_name}>
                      {val.leads_name}
                    </option>
                  ))}
                </select>
              </div>
            <h5 className='text-dark fw-bold'>{leadCount} leads selected</h5>
              <div className='mt-3'>
                <DataTable
                  columns={columns}
                  data={allLeadList}
                  progressPending={pending}
                  // pagination
                />
              </div>
            </div>
            <div class="modal-footer">
            {leadCount>0 ? <button type="button" class="btn themeRed" data-bs-dismiss="modal" aria-label="Close" onClick={()=>setLeadPreview(true)}>Add {leadCount} People</button> : <></>}
              
            </div>
          </div>
        </div>
      </div>

      {/* Lead Modal ends */}
      {/* Lead Modal starts */}

      <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <div class="modal-header gap-2">
            <BsFillArrowLeftCircleFill className='cursorPointer' data-bs-toggle="modal" data-bs-target="#exampleModal" />
              <h6 class="modal-title fw-bold" id="exampleModalLabel">Add people manually</h6>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div>
                <label>Leads list name</label>
                <input type="text" className='form-control p-2 mb-3' onChange={(e)=>{setListName(e.target.value)}} value={listName}/>
              </div>
              <div>
                <label>Email addresses (comma-separated)</label>
                <textarea className='form-control p-2' name="" id="" cols="56" rows="5" onChange={(e)=>{setMailList(e.target.value)}} value={mailList}></textarea>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn themeRed" data-bs-dismiss="modal" onClick={handleNewMailList} disabled={!mailList || !listName}>Add People</button>
            </div>
          </div>
        </div>
      </div>

      {/* Lead Modal ends */}
    </>
  )
}
