import React from 'react';
import BlueIcon from '../assets/homeassets/BlueIcon.svg';
import GreenIcon from '../assets/homeassets/GreenIcon.svg';
import PinkIcon from '../assets/homeassets/PinkIcon.svg';

const EmailCards = () => {
  return (
    <>
        <div className="container text-center py-5">
      <div className="row">
        {/* First Icon Div */}
        <div className="col-md-4 mb-4">
          <div className="icon-box">
            <img 
              src={PinkIcon}
              alt="Email Icon" 
              className="icon-img mb-3"
            />
            <h5>Comprehensive email management and data-driven insights</h5>
          </div>
        </div>

        {/* Second Icon Div */}
        <div className="col-md-4 mb-4">
          <div className="icon-box">
            <img 
              src={GreenIcon}
              alt="Deliverability Icon" 
              className="icon-img mb-3"
            />
            <h5>Enhanced deliverability and powerful campaign management</h5>
          </div>
        </div>

        {/* Third Icon Div */}
        <div className="col-md-4 mb-4">
          <div className="icon-box">
            <img 
              src={BlueIcon}
              alt="Insights Icon" 
              className="icon-img mb-3"
            />
            <h5>Unleash the power of company insights</h5>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default EmailCards