import React from 'react'
import { Row, Col } from 'react-bootstrap';

const VisionCard = (props) => {
  return (
    <>
    <Row className='d-flex flex-column gap-3 py-5' >
        {props?.visionData?.map((elem,index)=>(
            <Col key={index} className='d-flex '>
                <div className="w-20">

                </div>
                <div className="w-20 ">
                    <img src={elem.imgSrc} alt="image not found" className='w-100' />
                </div>
                <div className="w-80 ps-2">
                <h5>{elem.heading}</h5>
                <p className='text-secondary'>{elem.text1}</p>
                </div>
            </Col>
        ))}
    </Row>
    </>
  )
}

export default VisionCard