import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { API_ENDPOINT } from "services/Api";
import requestInstance from "services/request";

// Async thunks for API calls
export const fetchDashboardCardData = createAsyncThunk(
  'dashboardCard/fetchDashboardCardData',
  async () => {
    const response = await requestInstance.get(`${API_ENDPOINT.DASHBOARD_CARD_API}`);
    return response.data;
  }
);

export const fetchDashboardLineChartData = createAsyncThunk(
  'dashboardCard/fetchDashboardLineChartData',
  async () => {
    const response = await requestInstance.get(API_ENDPOINT.DASHBOARD_LINE_CHART_API);
    return response.data;
  }
);

export const fetchDashboardBarChartData = createAsyncThunk(
  'dashboardCard/fetchDashboardBarChartData',
  async () => {
    const response = await requestInstance.get(API_ENDPOINT.DASHBOARD_DOUGHNUT_CHART_API);
    return response.data;
  }
);

const initialState = {
  dashboardCardData: null,
  dashboardLineChartData: null,
  dashboardBarChartData: null,
  loading: false,
  error: null
};

export const dashboardCardSlice = createSlice({
  name: "dashboardCard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Handle fetchDashboardCardData actions
    builder
      .addCase(fetchDashboardCardData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDashboardCardData.fulfilled, (state, action) => {
        state.loading = false;
        state.dashboardCardData = action.payload;
      })
      .addCase(fetchDashboardCardData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    // Handle fetchDashboardLineChartData actions
    builder
      .addCase(fetchDashboardLineChartData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDashboardLineChartData.fulfilled, (state, action) => {
        state.loading = false;
        state.dashboardLineChartData = action.payload;
      })
      .addCase(fetchDashboardLineChartData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    // Handle fetchDashboardBarChartData actions
    builder
      .addCase(fetchDashboardBarChartData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDashboardBarChartData.fulfilled, (state, action) => {
        state.loading = false;
        state.dashboardBarChartData = action.payload;
      })
      .addCase(fetchDashboardBarChartData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export default dashboardCardSlice.reducer;