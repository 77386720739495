import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './SettingsLayout.css';
import { useSelector } from 'react-redux';

const SettingSidebar = () => {
    const location = useLocation();
    const currentPath = location.pathname.split('/')[2];
    const role = useSelector((state) => state?.user?.value?.role);
    


  return (
        <div className="vl px-1">
            <div className='listItems'>
                <div className="accordion text-start" id="accordionPanelsStayOpenExample">
                    <div className={currentPath === 'profile' ? 'accordion-body cursorPointer activeLink' : 'accordion-body'}>
                        <Link to={`/settings/profile`}>
                            <div className='text-secondary fw-bold px-3 py-2'>Profile</div>
                        </Link>
                    </div>
                  {
                      ['Owner', 'Admin'].includes(role) &&
                      <div className={currentPath === 'team' ? 'accordion-body cursorPointer activeLink' : 'accordion-body'}>
                          <Link to={`/settings/team`}>
                              <div className='text-secondary fw-bold px-3 py-2'>Team</div>
                          </Link>
                      </div>
                  }
                    <div className={currentPath === 'subscription' ? 'accordion-body cursorPointer activeLink' : 'accordion-body'}>
                        <Link to={`/settings/subscription`} >
                            <div className='text-secondary fw-bold px-3 py-2'>Subscription</div>
                        </Link>
                    </div>
                </div>
            </div>
            </div>
  )
}

export default SettingSidebar;