import React from "react";
import { PopupMenu } from "react-simple-widgets";
import "./Profile.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FiLogOut } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { resetCampaign } from "../../redux/slices/campaign/CampaignSlice";

function Profile() {
  const profileuser = useSelector((state) => state?.user?.value);
  const firstletter = useSelector((state) => state?.user?.value?.first_name?.charAt(0));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const planUser = useSelector((state) => state?.user?.planStatus);
  const role = useSelector((state) => state?.user?.value?.role);

  const onLogOut = () => {
    navigate("/signin");
    localStorage.clear();
    dispatch(resetCampaign());
    toast.success("Logged out successfully.");
  };

  return (
    <div>
      <div className="text-end">
        <PopupMenu>
          <button
            className="mx-3 profile_btn bg-transparent fs-2 ">
            <div id="avatar" className="text-center mx-4">
              <span className="profile_firstapl">{profileuser?.profile_pic ? <img src={profileuser?.profile_pic} className="profilePic_nav" alt="Profile" /> : firstletter}</span>
            </div>
          </button>

          <div className="card text-start profile-Card">
            <div className="card-body p-0">
              <div className="d-flex flex-row profile-sidebar-heading py-2 pt-3">
                <div id="circle-avatar" className=" mx-3">
                  {profileuser?.profile_pic ? <img src={profileuser?.profile_pic} className="profilePic_dropdown" alt="Profile" /> : <span className="profile_firstletter">{firstletter}</span>}
                </div>
                <div className="">
                  <h5 className="profileName mb-0">
                    {profileuser?.full_name || " "}
                  </h5>
                  <p className="profileEmail ">
                    {profileuser?.email || " "}
                  </p>
                </div>
              </div>
              <div className="list-group list-group-flush p-3">
                <div className="d-flex justify-content-between">
                  <small className="text-decoration-none text-gray fw-semibold">
                    {/* Monthly Usage */}
                    Usage
                  </small>
                  <small className="text-success">
                    ({planUser?.user_data?.plan?.plan_type ? planUser?.user_data?.plan?.plan_type : null} plan)
                  </small>
                </div>


                <div className="text-gray d-flex justify-content-between mt-2">
                  <small>Domain</small>
                  <small>{planUser?.user_data?.credit_details?.remaining_domain_credit_limit}/{planUser?.user_data?.credit_details?.total_domain_credit_limit}</small>
                </div>
                <div>
                  <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                    <div class="progress-bar" style={{ 'width': `${planUser?.user_data?.credit_details?.remaining_domain_credit_limit / planUser?.user_data?.credit_details?.total_domain_credit_limit * 100}%` }}></div>
                  </div>
                </div>

                <div className="text-gray d-flex justify-content-between mt-2">
                  <small>Email</small>
                  <small>{planUser?.user_data?.credit_details?.remaining_email_credit_limit}/{planUser?.user_data?.credit_details?.total_email_credit_limit}</small>
                </div>
                <div>
                  <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                    <div class="progress-bar" style={{ 'width': `${planUser?.user_data?.credit_details?.remaining_email_credit_limit / planUser?.user_data?.credit_details?.total_email_credit_limit * 100}%` }}></div>
                  </div>
                </div>

                <div className="text-gray d-flex justify-content-between mt-2">
                  <small>LinkedIn</small>
                  <small>{planUser?.user_data?.credit_details?.remaining_linkedin_credit_limit}/{planUser?.user_data?.credit_details?.total_linkedin_credit_limit}</small>
                </div>
                <div>
                  <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                    <div class="progress-bar" style={{ 'width': `${planUser?.user_data?.credit_details?.remaining_linkedin_credit_limit / planUser?.user_data?.credit_details?.total_linkedin_credit_limit * 100}%` }}></div>
                  </div>
                </div>


                <div className="text-gray d-flex justify-content-between mt-2">
                  <small>Email(Name-Domain)</small>
                  <small>{planUser?.user_data?.credit_details?.remaining_nametoemail_credit_limit}/{planUser?.user_data?.credit_details?.total_nametoemail_credit_limit}</small>
                </div>
                <div>
                  <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                    <div class="progress-bar" style={{ 'width': `${planUser?.user_data?.credit_details?.remaining_nametoemail_credit_limit / planUser?.user_data?.credit_details?.total_nametoemail_credit_limit * 100}%` }}></div>
                  </div>
                </div>


                <div className="text-gray d-flex justify-content-between mt-2">
                  <small>Campaign</small>
                  <small>{planUser?.user_data?.credit_details?.remaining_campaign_credit_limit}/{planUser?.user_data?.credit_details?.total_campaign_credit_limit}</small>
                </div>
                <div>
                  <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                    <div class="progress-bar" style={{ 'width': `${planUser?.user_data?.credit_details?.remaining_campaign_credit_limit / planUser?.user_data?.credit_details?.total_campaign_credit_limit * 100}%` }}></div>
                  </div>
                </div>

              </div>

              <Link
                className="text-decoration-none text-black"
                to="/settings/profile"
              >
                <div
                  className="list-group list-group-flush"
                >
                  <button className="list-group-item list-group-item-action border-1">
                    Edit Profile
                  </button>
                </div>
              </Link>
              {
                ['Owner', 'Admin'].includes(role) &&
                <Link
                  className="text-decoration-none text-black"
                  to={"/settings/team"}
                >
                  <div
                    className="list-group list-group-flush"
                  >
                    <button className="list-group-item list-group-item-action border-1">
                      Team
                    </button>
                  </div>
                </Link>
              }
              <Link
                className="text-decoration-none text-black"
                to="/settings/subscription"
              >
                <div
                  className="list-group list-group-flush"
                >
                  <button className="list-group-item list-group-item-action border-1">
                    Subscription
                  </button>
                </div>
              </Link>

              <div className="text-center">
                <button
                  className="btn btn-secondary my-3 px-5 py-2 "
                  onClick={() => onLogOut()}
                >
                  <small>
                    <FiLogOut /> Logout
                  </small>
                </button>
              </div>
            </div>
          </div>
        </PopupMenu>
      </div>
    </div>
  );
}

export default Profile;
