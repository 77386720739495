// import fetchAdapter from "@vespaiach/axios-fetch-adapter";
// import { clear } from "@testing-library/user-event/dist/clear";
import axios from "axios";
import { toast } from "react-toastify";

const requestInstance = axios.create();

const API_PROD = process.env.REACT_APP_DISCOVEMAIL_API;
// Configuration can be invoked here before every request we make by axios
// Add a request interceptor
requestInstance.interceptors.request.use(
  (config) => {
    const connection = navigator.onLine;
    if (connection === false) {
      alert("No Internet Connection");
      return false;
    }
    config.baseURL = process.env.NODE_ENV == "production" ? API_PROD : API_PROD;
    // config.adapter = fetchAdapter;
    const token = JSON.parse(localStorage.getItem("find_email"));
    if (token) {
      config.headers = {
        "Content-Type": "application/json" || "multipart/form-data",
        Authorization: `Bearer ${token}`,
      };
    } else {
      config.headers = {
        "Content-Type": "application/json",
      };
    }

    return config;
  },
  (error) => {
    alert(error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
requestInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // const navigate = useNavigate();

    if (error?.response?.data?.status) {
      toast.error(error?.response?.data?.message);
    }
    if (error?.response?.status == 403) {
      localStorage.clear();
      window.location.pathname = "/signin";
    }

    // if (error.message === "Network Error") {

    // }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default requestInstance;
