import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { API_ENDPOINT } from "../../../services/Api";
import requestInstance from "../../../services/request";
import { useDispatch, useSelector } from "react-redux";
import { SiMinutemailer } from "react-icons/si";
import { MdClose } from "react-icons/md";
import ReactQuill from "react-quill";
import {
  campaignContentAddSign,
  campaignContentBody,
  campaignContentPersonifyMail,
  campaignContentPersonifyMailAttribute,
  campaignContentSubject,
  resetPersonifyMail,
  campaignContentAttachments,
  campaignId,
  campaignListId,
} from "../../../../src/redux/slices/campaign/CampaignSlice";

const personify_dropdown = [
  { key: "First Name", value: "first_name" },
  { key: "Last Name", value: "last_name" },
  { key: "Email", value: "email" },
  { key: "Phone", value: "phone_number" },
  { key: "Company", value: "company_name" },
  { key: "Company Website", value: "company_website_url" },
  { key: "Designation", value: "designation" },
];

const ContentSummary = () => {
  const email = useSelector((state) => state?.gAuth?.signValue?.email);
  const subject = useSelector(
    (state) => state?.campaign?.campaignContentSubject
  );
  const message = useSelector((state) => state?.campaign?.campaignContentBody);
  const id = useSelector((state) => state?.campaign?.campaignId);
  const [testMailLoader, setTestMailLoader] = useState(false);
  const [bccSectionVisible, setBCCSectionVisible] = useState(false);
  const [mailCC, setMailCC] = useState([]);
  const [mailBCC, setMailBCC] = useState([]);
  const [ccSectionVisible, setCCSectionVisible] = useState(false);
  const [templatesData, setTemplatesData] = useState([]);
  const [receiverEmail, setReceiverEmail] = useState("");
  const quillRef = useRef();
  const subId = useRef();
  const fileInputRef = useRef();
  const dispatch = useDispatch();
  const attachments = useSelector(
    (state) => state?.campaign?.campaignContentAttachments
  );
  const selectedAttribute = useSelector(
    (state) => state?.campaign?.campaignContentPersonifyMailAttribute
  );
  const [addCursorType, setAddCursorType] = useState(null);
  const personifyName = useSelector(
    (state) => state?.campaign?.campaignContentPersonifyMail
  );

  const handelSend = () => {
    if (subject === "") {
      toast.warn("Please enter subject");
    }

    if (message === "") {
      toast.warn("Please enter message");
    }

    if (subject !== "" && message !== "") {
      setTestMailLoader(true);
      const formData = new FormData();
      formData.append("receiver", email);
      formData.append("cc", mailCC || []);
      formData.append("bcc", mailBCC || []);
      formData.append("subject", subject);
      formData.append(
        "message",
        message?.replaceAll("<p>", '<p style="margin: 0"/>')
      );
      for (let i = 0; i < attachments.length; i++) {
        formData.append(`${"files"}`, attachments[i]);
      }
      requestInstance
        .post(API_ENDPOINT.CAMPAIGN_GMAIL_API, formData)
        .then((res) => {
          if (res.status === 200) {
            toast.success(res?.data?.message);
            setMailCC([]);
            setMailBCC([]);
          }
        })
        .catch((err) => toast.error(err.data.message))
        .finally(() => {
          setTestMailLoader(false);
        });
    }
  };

  const handleMailCCChange = (e) => {
    const inputValue = e.target.value;
    const emailArray = inputValue.split(",").map((email) => email.trim());
    setMailCC(emailArray);
  };

  const handleMailBCCChange = (e) => {
    const inputValue = e.target.value;
    const emailArray = inputValue.split(",").map((email) => email.trim());
    setMailBCC(emailArray);
  };

  const displayedCCEmails = mailCC?.join(", ");

  const displayedBCCEmails = mailBCC?.join(", ");

  const toggleCCSection = () => {
    setCCSectionVisible(!ccSectionVisible);
  };

  const toggleBCCSection = () => {
    setBCCSectionVisible(!bccSectionVisible);
  };

  const insertTextAtCursor = () => {
    if (quillRef.current && selectedAttribute && addCursorType === "body") {
      const quill = quillRef.current.getEditor();
      const mergeFieldText = `{{${selectedAttribute}|${personifyName}}}`;
      const selection = quill.getSelection(true);
      quill.insertText(selection.index, mergeFieldText);
    }

    if (subId.current && selectedAttribute && addCursorType === "subject") {
      const currentSubject = subject || ""; // Ensure subject is initialized
      const mergeFieldText = `{{${selectedAttribute}|${personifyName}}}`;
      const cursorPosition = subId.current.selectionStart || 0; // Get cursor position
      const newSubject = `${currentSubject.slice(
        0,
        cursorPosition
      )}${mergeFieldText}${currentSubject.slice(cursorPosition)}`;
      dispatch(campaignContentSubject(newSubject));
    }
    dispatch(resetPersonifyMail());
  };

  const handleImagesChange = (files) => {
    dispatch(campaignContentAttachments(Array.from(files)));
  };

  const removeAttachment = (index) => {
    const updatedAttachments = [...attachments];
    updatedAttachments.splice(index, 1);
    dispatch(campaignContentAttachments(Array.from(updatedAttachments)));

    const fileInput = fileInputRef.current;
    if (fileInput && fileInput.files && fileInput.files.length > 0) {
      const updatedFiles = Array.from(fileInput.files);
      updatedFiles.splice(index, 1);
      const dataTransfer = new DataTransfer();
      updatedFiles.forEach((file) => dataTransfer.items.add(file));
      fileInput.files = dataTransfer.files;
    }
  };

  const templatesDataFetch = () => {
    requestInstance
      .get(API_ENDPOINT.CAMPAIGN_DATA_USER_TEMPLATES)
      .then((res) => {
        setTemplatesData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const templatesDataById = (id) => {
    requestInstance
      .get(API_ENDPOINT.CAMPAIGN_DATA_EACH_TEMPLATES + `?template_id=${id}`)
      .then((res) => {
        dispatch(campaignContentSubject(res?.data?.user_templates[0].subject));
        dispatch(campaignContentBody(res?.data?.user_templates[0].body));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
    }
  }, [quillRef]);

  useEffect(() => {
    setReceiverEmail(localStorage.getItem("email"));
    templatesDataFetch();
  }, []);

  // THIS IS THE CONTENT API ........
  useEffect(() => {
    requestInstance
      .get(API_ENDPOINT.CAMPAIGN_SUMMARY_CONTENTS + `/${id}`)
      .then((res) => {
        console.log(res.data.data);
        const data = res.data.data;
        dispatch(campaignContentSubject(data.email_subject));
        dispatch(campaignContentBody(data.email_msg_body));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div>
      <div className="col-md-9">
        <div className="row">
          <div className="col-md-12 col-lg-5">
            <div className="campaign-header">
              <span className="campaign-header__subtitle">From</span>
              <select
                className="form-select form-select-lg mb-3"
                aria-label=".form-select-lg example"
              >
                <option selected value="email" className="">
                  {email}
                </option>
              </select>
              <div className="d-flex gap-3">
                <div>
                  <a
                    href="#"
                    className="campaign-header__subtitle campaign-link"
                  >
                    Add an Account
                  </a>
                </div>
                <div>
                  <a
                    onClick={toggleBCCSection}
                    className="campaign-header__subtitle campaign-link cursorPointer"
                  >
                    Add BCC
                  </a>
                </div>
                <div>
                  <a
                    onClick={toggleCCSection}
                    className="campaign-header__subtitle campaign-link cursorPointer"
                  >
                    Add CC
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* to implementation starts */}

        {bccSectionVisible ? (
          <div className="row">
            <div className="col-md-12 col-lg-5">
              <div className="mt-1">
              <span className='font13px'>BCC</span>
                <div className="input-group">
                  <input
                    type="text"
                    value={displayedBCCEmails}
                    onChange={handleMailBCCChange}
                    className="form-control text-secondary"
                    placeholder="example@email.com"
                    aria-label="bcc"
                    aria-describedby="basic-addon1"
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {ccSectionVisible ? <div className="row">
          <div className="col-md-12 col-lg-5">
            <div className="mt-2">
            <span className='font13px'>CC</span>
              <div className="input-group">
                <input
                  type="text"
                  value={displayedCCEmails}
                  onChange={handleMailCCChange}
                  className="form-control text-secondary"
                  placeholder="example@email.com"
                  aria-label="cc"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>
          </div>
        </div> : null}

        <hr />

        {/* to implementation ends */}
        <div className="row mt-2">
          <div className="col-3">
            <select
              disabled
              className="form-select"
              id="select-example"
              name="template"
              placeholder="Select a template"
              onChange={(e) => {
                const selectedValue = e.target.value;
                if (selectedValue !== "") {
                  templatesDataById(selectedValue);
                } else {
                  dispatch(campaignContentSubject(""));
                  dispatch(campaignContentBody(""));
                }
              }}
            >
              <option value="">Select a template</option>
              {templatesData?.map((val) => (
                <option key={val.id} value={val.id}>
                  {val.template_name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-3">
            <div className="d-flex justify-content-center">
              <button
                className="btn test-mail-btn"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                Personify Mail
              </button>
            </div>
          </div>
          <div className="col-4">
            <button className="btn test-mail-btn w-100" onClick={handelSend}>
              {testMailLoader ? (
                <div class="text-center">
                  <div class="spinner-border text-danger" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              ) : (
                <div>
                  <SiMinutemailer /> Send myself a test mail{" "}
                </div>
              )}
            </button>
          </div>
        </div>

        <input
          multiple
          disabled
          type="file"
          ref={fileInputRef}
          onChange={(e) => handleImagesChange(e.target.files)}
          style={{ marginBottom: "16px" }}
        />
        {attachments.length > 0 && (
          <div className="d-flex flex-wrap">
            {attachments.map((item, i) => (
              <div key={i} className="d-flex flex-row border py-2 me-2 mb-2">
                <p className="px-3 m-0">{item.name}</p>
                <div onClick={() => removeAttachment(i)}>
                  <MdClose className="fs-4 cursorPointer" />
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="row h-100">
          <div className="col-md-10 w-100">
            <div className="campaign-subject input-group">
              <input
                ref={subId}
                type="text"
                disabled
                className="campaign-subject__input form-control border-bottom-0"
                placeholder="Subject"
                name="subject"
                onChange={(e) =>
                  dispatch(campaignContentSubject(e.target.value))
                }
                value={subject}
                onClick={() => {
                  setAddCursorType("subject");
                }}
                onBlur={() => {
                  setAddCursorType("subject");
                }}
              />
            </div>

            <div className="campaign-message input-group">
              <ReactQuill
                readOnly
                ref={quillRef}
                value={message}
                onChange={(e) => dispatch(campaignContentBody(e))}
                className="form-control campaign-message__textarea h-100 p-0 border-0"
                onClick={() => {
                  setAddCursorType("body");
                }}
                onBlur={() => {
                  setAddCursorType("body");
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentSummary;
