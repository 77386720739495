import React from "react";
import Footer from "../../new_home/Footer";
import { Helmet } from "react-helmet";
import Navbar from "../../new_home/Navbar_1";
import UnderConstructionImg from "../../assets/underconstruction.jpg";
import "./UnderConstruction.css";

export default function UnderConstruction() {
  return (
    <>
      <Helmet>
        <title>Discovemail - Smart email verification tool for finding and checking emails</title>

        <meta
          name="description"
          content="The Ultimate Email Finder and Verification Tool. Verify, check, and validate email addresses instantly with our top-notch emailid checker."
        />

        <meta
          name="keywords"
          content=" LinkedIn Email Finder,verified email addresses,discovemail,email,linkedin,emailfinder,get email,search email,location finder,linkedin details,
          linkedin user details,linkedin id "
        />
      </Helmet>
      <Navbar />

      <div className="layout"></div>
      <section className="construction-area">
        <div className="container">
          <div className="row justify-content-center">
            <div className="construction-title">
            <h3 className="construction-main-title">Stay Tuned</h3>
            <h3 className="construction__sub_title">Coming Soon</h3>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
