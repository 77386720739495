import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import Navbar from '../../new_home/Navbar_1'
import requestInstance from "../../services/request";
import { API_ENDPOINT } from "../../services/Api";
import { formatMobileNumber } from "../../utils/utils";
import NewFooter from 'home_revamp/NewFooter';
import Faq from 'home_revamp/Faq';
import Vision from 'company_revamp/Vision';
import VikgolTeam from 'company_revamp/VikgolTeam';
import Rockstar from 'company_revamp/Rockstar';
import Company from 'company_revamp/Company';
import Progress from 'company_revamp/Progress';
import contactBanner from '../../assets/about/contactBanner2.svg'
import './AboutUs.css'

const validate = (values) => {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = "Please Enter your Name";
  } else if (values.firstName[0].includes(" ")) {
    errors.firstName = "Must not include space at start";
  } else if (/^(?=.*?[0-9])/i.test(values.firstName)) {
    errors.firstName = "Must not contain number";
  } else if (/(?=.*?[#?!@$%^&*-])/i.test(values.firstName)) {
    errors.firstName = "Must not contain special characters";
  }
  if (!values.email) {
    errors.email = "Please enter email address";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = "Please enter your Phone Number";
  } else if (values.phoneNumber.length < 10) {
    errors.phoneNumber = "Must be of 10 digits";
  }
  if (!values.message) {
    errors.message = "Please write a message";
  } else if (values.message[0].includes(" ")) {
    errors.message = "Must not include space at start";
  }

  return errors;
};

export default function AboutUs() {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      message: "",
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      setButtonDisabled(true);
      handleContactUs(values, { resetForm });
    },
  });

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  console.log(query.get("plan"))
  let plan = query.get("plan");

  const handleContactUs = (values, { resetForm }) => {
    let data = JSON.stringify({
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      phone_number: values.phoneNumber,
      message: values.message,
      data_type: "contact_message",
    });
    
    requestInstance
      .post(API_ENDPOINT.CONTACT_API, data)
      .then((res) => {
        if (res.status == 200) {
          toast.success(res.data.status);
          setLoading(false);
          resetForm();
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div className='overflow-hidden'>
      <Helmet>
        <title>Discovemail</title>
      </Helmet>

      <Navbar />
      <Company />
      <Progress />
      <VikgolTeam />
      <Vision />
      <Rockstar />
      <section className='letsTalk'>
        <div className=' container  py-5'>
          <div className='row'>
          <div className='col-lg-6 '>
              <div>
                <img src={contactBanner} alt='img not found' className='img-fluid w-100' />
              </div>
            </div>
            <div className='col-lg-6 contactForm'>
              <h2 className='title blueGradient ms-lg-5 mb-4'>Send us a message</h2>
              <form onSubmit={formik.handleSubmit} className="ms-lg-5">
                <div className="row mb-4">
                  <div className="col-md-6">
                    <div className="input-group">
                    <p className='mb-0'>First Name</p>
                      <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.firstName}
                        className="form-control py-3"
                        placeholder="John"
                      />
                    </div>
                    {formik.touched.firstName && formik.errors.firstName ? (
                      <div className="errormsg">
                        {formik.errors.firstName}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <div className="input-group">
                    <p className='mb-0'>Last Name</p>
                      <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                        className="form-control py-3"
                        placeholder="Doe"
                      />
                    </div>
                  </div>
                </div>

                <div className="row mb-4 ">
                  <div className="col-md-6 mt-3">
                    <div className="input-group">
                    <p className='mb-0'>Email Address</p>
                      <input
                        type="text"
                        id="email"
                        name="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        className="form-control py-3"
                        placeholder="john@gmail.com"
                      />
                    </div>
                    {formik.touched.email && formik.errors.email ? (
                      <div className="errormsg">{formik.errors.email}</div>
                    ) : null}
                  </div>
                  <div className="col-md-6 mt-3">
                    <div className="input-group">
                    <p className='mb-0'>Phone Number</p>
                      <PhoneInput
                        inputProps={{
                          name: "phoneNumber",
                          required: true,
                          placeholder: "Phone Number",
                          className: "form-control py-3",
                        }}
                        country={"in"}
                        name="phoneNumber"
                        enableSearch={true}
                        autoFormat={false}
                        value={formik.values.phoneNumber}
                        onChange={(value, data, event, formattedValue) => formik.setFieldValue('phoneNumber', formatMobileNumber(formattedValue, data))}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    {formik.touched.phoneNumber &&
                      formik.errors.phoneNumber ? (
                      <div className="errormsg">
                        {formik.errors.phoneNumber}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="input-group mb-lg-4 mb-3">
                <p className='mb-0'>Your Message</p>
                  <textarea
                    type="text"
                    className="form-control "
                    id="message"
                    name="message"
                    rows={6}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.message}
                    placeholder="Type Here"
                  ></textarea>
                </div>
                {formik.touched.message && formik.errors.message ? (
                  <div className="errormsg">
                    {formik.errors.message}
                  </div>
                ) : null}

                <div className="d-flex justify-content-start">
                  <button type="submit" className="btn btn-disco rounded-2 fw-normal fs-6">
                    Submit
                  </button>
                </div>

              </form>
            </div>
            
          </div>
        </div>
      </section>


      <Faq />
      <NewFooter />

    </div>
  )
}
