import React from "react";
import "./Homeravamp.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import centerImg from "../assets/revampHomeassets/centerImg.png";
import ClientCards from "./ClientCards";
import { feedBackData } from "../resource/data/feedback";

const Clientcarousel = () => {
  var settings = {
    className: "center",
    // centerMode: 'false',
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
    <h1 className="blueGradient fs-1 text-center mb-5">What our clients say about us.?</h1>
      <Slider {...settings}>
        {feedBackData.map((item) => (
          <div className="container-fluid testCard position-relative">
            <img
              src={item.bgImg}
              alt="bgImg"
              className="position-absolute centerImg img-fluid"
            />
            <div className="textDiv">
              <h3 className="">{item.name}</h3>
              <h4 className="">{item.head}</h4>
              <p className="">{item.comment}</p>
            </div>
          </div>
        ))}
          {/* <ClientCards feedBackData={feedBackData} /> */}
        
      </Slider>
    </div>
  );
};

export default Clientcarousel;
