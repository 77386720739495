import React, {useState , useEffect} from 'react';
import requestInstance from '../../../services/request';
import { API_ENDPOINT } from '../../../services/Api';
import { useParams } from "react-router-dom";
import '../../campaign/Campaign.css';
import { toast } from 'react-toastify';


const EmailAudience = () => {
  const { id } = useParams();
  const[campaignAudienceData, setcampaignAudienceData] = useState([]);
  
  useEffect(()=>{
    requestInstance
    .get(API_ENDPOINT.CAMPAIGN_AUDIENCE_DETAILS + `${id}`)
    .then((res)=>{
      // console.log(res.data.data);
      setcampaignAudienceData(res.data?.data)
    })
  },[])
  

  const campaignAudienceExcel = async () => {
    try {
      const response = await requestInstance.get(`${API_ENDPOINT.CAMPAIGN_AUDIENCE_DOWNLOADS}/${id}`, { responseType: 'blob' });

      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      toast.success("Download successfull");
      link.setAttribute("download", "Discovemail.csv");
      link.click();
      
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  }
  
  return (
    <div className='emailCampaignAudience'>
    <button className='btn btn-danger audienceDwnldBtn' onClick={campaignAudienceExcel}>Download CSV</button>
    {campaignAudienceData.length > 0 ? <h5 className='audienceRecipientsHead'>{campaignAudienceData.length} recipients</h5> : <></> }
    <div className="audienceTableContainer">
    <table className='audienceTable'>
      {campaignAudienceData.length>0 ? <thead>
        <tr>
          <th className='px-4'>Email</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Company</th>
          <th>Sending Status</th>
        </tr>
      </thead> : <div className='d-flex justify-content-center align-items-center'>There are no records to display</div> }
      <tbody>
        {
          campaignAudienceData && campaignAudienceData.map((elem,index)=>(
            <tr className='cursorPointer bgBlueHover' >
              <td className='py-2 px-4' >{elem.user_lead_details.email}</td>
              <td className='py-2' >{elem.user_lead_details.first_name}</td>
              <td className='py-2' >{elem.user_lead_details.last_name}</td>
              <td className='py-2' >{elem.user_lead_details.company_name}</td>
              <td className='py-2' >{elem.sending_status}</td>
            </tr>
          ))
        }
      </tbody>


    </table>
    </div>


    </div>
  )
}

export default EmailAudience