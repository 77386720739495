import React, { useState, useEffect } from 'react'
import {
  LineChart,
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush,
} from 'recharts';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Spinner from '../../loader/SimpleLoader';
import requestInstance from '../../../services/request';
import { API_ENDPOINT } from '../../../services/Api';


const Reporting = () => {
  const [isLoading, setIsLoading] = useState();
  const [chartData, setChartData] = useState();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(new Date());

  const maxDate = new Date();

  const getChartData = () => {

    let apiPath = `${API_ENDPOINT.REPORTING_CHART_LIST}`
    if (startDate && endDate) {
      apiPath = apiPath + `?start_date=${formatDate(startDate)}&end_date=${formatDate(endDate)}`
    }
    requestInstance
      .get(apiPath)
      .then((res) => {
        setIsLoading(true)
        setChartData(
          res?.data?.data?.map((item) => {
            return {
              id: item?.campaign_id,
              Name: item?.campaign_name,
              "Total Emails": item?.total_emails,
              "Emails Sent": item?.emails_sent,
              "Opened Emails": item?.opened_emails,
              "Bounced Emails": item?.bounced_emails
            };
          })
        );
        toast.success(res.data.message);
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getChartData();
  }, [endDate, startDate])

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const handleStartDateChange = (selectedDate) => {
    if (selectedDate && selectedDate instanceof Date && !isNaN(selectedDate)) {
      if (selectedDate > endDate) {
        setEndDate(selectedDate);
      }
      setStartDate(selectedDate);
    } else {
      console.error("Invalid start date");
    }
  };

  const handleEndDateChange = (selectedDate) => {
    if (selectedDate && selectedDate instanceof Date && !isNaN(selectedDate)) {
      if (selectedDate < startDate) {
        setEndDate(startDate);
      } else {
        setEndDate(selectedDate);
      }
    } else {
      console.error("Invalid end date");
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className='col-12 minHeight84vh'>
          <div className='d-flex align-items-center justify-content-between p-4 flex-column flex-md-row gap-3 gap-md-0'>
            <h5 className='text-dark fw-bold'>Campaigns Reporting</h5>
            <div className="d-flex flex-row mt-3">
              <div>
                <label >Start Date:</label>
                <DatePicker selected={startDate} onChange={handleStartDateChange} dateFormat="dd-MM-yyyy" maxDate={maxDate} />
              </div>
              <div>
                  <label className='ms-3'>End Date:</label>
                <DatePicker selected={endDate} onChange={handleEndDateChange} dateFormat="dd-MM-yyyy" maxDate={maxDate} />
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-12 overflow-auto p-4'>
              <ResponsiveContainer width="100%" height={500}>
                {!isLoading && chartData?.length > 0 ?
                    <ComposedChart
                    width={1100}
                    height={500}
                    data={chartData}
                    margin={{
                      top: 20, right: 30, left: 10, bottom: 5,
                    }}
                  >
                      <CartesianGrid strokeDasharray="2 2" stroke="#f5f5f5" />
                    <XAxis dataKey="Name" padding={{ left: 30 }} tick={false} />
                    <YAxis />
                    <Tooltip />
                      <Legend />
                      <Bar dataKey="Total Emails" barSize={20} fill="#413ea0" />
                      {/* <Line type="monotone" dataKey="Total Emails" stroke="#bf40bf" activeDot={{ r: 8 }} /> */}
                      <Area type="monotone" dataKey="Opened Emails" fill="#8884d8" stroke="#8884d8" />
                      <Line type="monotone" dataKey="Emails Sent" stroke="#82ca9d" />
                      <Line type="monotone" dataKey="Bounced Emails" stroke="#ff0000" />
                      {/* <Line type="monotone" dataKey="Opened Emails" stroke="#ffc658" />                     */}
                    <Brush />
                    </ComposedChart> :
                  <div className='d-flex justify-content-center align-items-center'>There are no records to display</div>
                }
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Reporting;