import React, { useEffect, useState } from "react";
import requestInstance from "services/request";
import useDebounce from "hooks/useDebounce";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { API_ENDPOINT } from "services/Api";
import moment from "moment";
import DatePicker from "react-datepicker";
import { FaExternalLinkAlt } from "react-icons/fa";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import "./TableDashboard.css";
import calender from "assets/Calendar.svg";
import message from "assets/Message.svg";
import CompanyOverview from "pages/dashboard/CompanyOverview";
import ReactPaginate from "react-paginate";
import DateTab from "components/commonDateRangePicker/CommonDateRangePicker";
import { getDateFormat } from "utils/utils";

const DataTable = (props) => {
  const [pending, setPending] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [tablelist, setTablelist] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [forPhone, setForPhone] = useState({ company: null, id: null });
  const [companyName, setCompanyName] = useState("");
  const [showCompanyModel, setShowCompanyModel] = useState(false);
  const [compPhoneNum, setcompPhoneNum] = useState("");
  const [companyInfo, setCompanyInfo] = useState([]);
  const [isCompanyInfoLoading, setIsCompanyInfoLoading] = useState(true);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [emails, setEmails] = useState([]);
  const [currIndex, setcurrIndex] = useState();
  const [debouncedSearchValue, setDebouncedSearchValue] = useState(searchTerm);
  const [startDate, setStartDate] = useState(null);
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValue(searchTerm);
    }, 500); // Debounce delay

    // Cleanup function to clear timeout
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  useEffect(() => {
    let ignore = false;

    // -----Table Data-----
    const getTableData = async () => {
      try {
        setPending(true);
        const response = await requestInstance.get(
          `${API_ENDPOINT.GET_ALL_SCRAPPED_DATA_API}?limit=${perPage}&offset=${
            (currentPage - 1) * perPage
          }&search=${debouncedSearchValue}&start_date=${dateFrom}&end_date=${dateTo}`
        );
        if (!ignore) {
          setTablelist(response.data.results["linkedin details"]);
          setTotalRows(response.data.count);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
        setPending(false);
      }
    };

    getTableData();

    return () => {
      ignore = true;
    };
  }, [debouncedSearchValue, currentPage, perPage,dateFrom, dateTo]);

  useEffect(() => {
    setFilteredData(
      tablelist.filter((data) =>
        Object.values(data)
          .join("")
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
    );
  }, [tablelist]);

  // ---Company Info---
  const fetchCompanyContact = (company) => {
    setcompPhoneNum("");
    setCompanyInfo([]);

    requestInstance
      .post(API_ENDPOINT.COMPANY_INFO, { company })
      .then((res) => {
        if (res.data.status === "success") {
          setCompanyInfo(res.data.data);
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        setIsCompanyInfoLoading(false);
      })
      .catch(() => {
        setIsCompanyInfoLoading(false);
      });
  };

  useEffect(() => {
    if (forPhone.id) {
      requestInstance
        .post(`${API_ENDPOINT.COMPANY_PHONE_INFO}${forPhone.id}`, {
          company: forPhone.company,
        })
        .then((res) => {
          if (res.data.status === "success") {
            setPhoneNumbers(res.data.data.company_numbers);
            setEmails(res.data.data.company_emails);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch(() => {
          console.log("err");
        });
    }
  }, [forPhone]);

  // Handle Click on Designation
  const handleDesignationClick = (row, index) => {
    setcurrIndex(index + 1);
    setShowCompanyModel(true);
    fetchCompanyContact(row.company);
    setCompanyName(row.company);
    setForPhone({ company: row.company, id: row.id });
  };

  // Handle Start Date
  const maxDate = new Date();
  const handleStartDateChange = (selectedDate) => {
    if (selectedDate && selectedDate instanceof Date && !isNaN(selectedDate)) {
      setStartDate(selectedDate);
      // console.log(formattedDate, "start date");
    } else {
      console.error("Invalid start date");
    }
  };

  // Pagination
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1); // `data.selected` starts from 0, so add 1 to match the page number
  };

  const pageCount = Math.ceil(totalRows / perPage); // Calculate total pages
  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  //Handle Date
  const onSelectDateRange = (newValue) => {
    if (newValue === null || newValue === 0) {
      setDateFrom("");
      setDateTo("");
    } else {
      setDateFrom(getDateFormat(newValue[0], "dashboardTable"));
      setDateTo(getDateFormat(newValue[1], "dashboardTable"));
    }
  };

  return (
    <div className="table-container mx-2 mt-4">
      {props?.name === "newTableData" ? (
        <div>
          <div className="d-flex justify-content-between align-items-baseline">
            <div className="d-flex align-items-baseline">
            <a className="table-title" href="/dashboard">
              <span className="me-1 fs-6"><IoChevronBackCircleOutline /></span> </a>
              <h5 className="table-title">Recently fetched from extension </h5>
              </div>
              <div className="d-flex align-items-center">
            <div className="me-4">
              {/* <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
                dateFormat="dd-MM-yyyy"
                maxDate={maxDate}
              /> */}
              <input
              type="text"
              placeholder="Search..."
              className="form-control"
              value={searchTerm}
              onChange={handleSearch}
            />
            </div>
            <div>
            <DateTab onSelectDateRange={onSelectDateRange} dateFrom={dateFrom}
            dateTo={dateTo} maxDate={maxDate}/>
            </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-between align-items-baseline">
          <h5 className="table-title">Recently fetched from extension</h5>
          {totalRows > 10 && (
          <div className="view-all-container">
            <a href="/dashboardtable" className="view-all-link">
              View all
            </a>
          </div>
        )}
        </div>
      )}
      <div className="table-responsive tableMain">
        <table className="custom-table table-hover">
          <thead>
            <tr>
              <th>S.No</th>
              <th>First name</th>
              <th>Last name</th>
              <th>Email</th>
              <th>LinkedIn Username</th>
              <th>Phone</th>
              <th>Company</th>
              <th>Designation</th>
              <th>Joining date</th>
              <th>Last updated</th>
              <th>Profile URL</th>
            </tr>
          </thead>
          <tbody>
            {tablelist.map((row, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{row.first_name}</td>
                <td>{row.last_name}</td>
                <td>
                  <div className="icon-text">
                    <img src={message} alt="email" className="icon" />
                    {row?.active_guess_email ? (
                      <a href={`mailto:${row?.active_guess_email}`}>
                        {row?.active_guess_email}
                      </a>
                    ) : row?.company_emails.length !== 0 ? (
                      <>
                        <a href={`mailto:${row?.company_emails[0]}`}>
                          {row?.company_emails[0]}
                        </a>
                        <span className="text-dark text-decoration-none mx-1">
                          {" "}
                          (HQ)
                        </span>
                      </>
                    ) : (
                      "NA"
                    )}
                  </div>
                </td>
                <td>{row.linkedin_user_name}</td>
                <td>
                  {row?.phone_number
                    ? row?.phone_number
                    : row?.company_numbers.length !== 0
                    ? row?.company_numbers[0] + " (HQ)"
                    : "NA"}
                </td>
                <td>
                  {row?.company ? (
                    <>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevents the row click event from firing
                          handleDesignationClick(row, index);
                        }}
                      >
                        {row.company}
                      </a>
                      <br />
                      {currIndex === index + 1 && compPhoneNum}
                    </>
                  ) : (
                    "NA"
                  )}
                </td>
                <td>
                  {row.designation || row?.headline?.split("|")[0] || "NA"}
                </td>
                <td>
                  <div className="icon-text">
                    <img src={calender} alt="calendar" className="icon" />
                    {moment(row.created_at).format("DD/MM/YYYY")}
                  </div>
                </td>
                <td>
                  <div className="icon-text">
                    <img src={calender} alt="calendar" className="icon" />
                    {moment(row.updated_at).format("DD/MM/YYYY")}
                  </div>
                </td>
                <td>
                  {row.profile_url && (
                    <a href={row.profile_url} target="_blank">
                      <FaExternalLinkAlt />
                    </a>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {pageCount > 1 && props?.name === "newTableData" && (
        <ReactPaginate
          previousLabel={"<"}
          nextLabel={">"}
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={3}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center mt-3"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      )}

      {/* Show Company Info using popup */}

      {showCompanyModel && (
        <Modal
          show={showCompanyModel}
          onHide={() => setShowCompanyModel(false)}
          centered
          size="lg"
        >
          <Modal.Body className="default850pxModelHeight">
            <CompanyOverview
              phone={phoneNumbers}
              email={emails}
              info={companyInfo}
              name={companyName}
              onClose={() => setShowCompanyModel(false)}
            />
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default DataTable;
