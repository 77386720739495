import React from 'react';
import errorImg from '../../assets/revampHomeassets/errorImg.svg';
import Navbar from 'new_home/Navbar_1';
import '../../home_revamp/Homeravamp.css';

const ErrorPage = () => {
  return (
    <div className='errorPage bgcover bgcenter bgNoRepeat'>
    <Navbar />
    <div className="align-items-center d-flex height100vh innerNotFoundPage justify-content-center w-100">
    <div className="innerContent w-50 text-center">
    <img src={errorImg} alt="image-not-found" className='my-4' />
        <h1 className='blueGradient mb-3' >404: Page Not Found</h1>
        <p className='mb-3'>We couldn't find the page you were looking for. Please check the URL or use the navigation menu to find what you're searching for.</p>
        <a href="/signup" className="btn btn-primary btn-lg serviceBtn custom-gradient col-sm-4 col-12 mt-3 bg-transparent ms-xl-5" role="button" aria-disabled="true" tabindex="-1">Take me home</a>
    </div>
        

    </div>
    </div>
  )
}

export default ErrorPage