import React from 'react';
import teamImg from "../assets/about/teamImg.svg";

const Rockstar = () => {
  return (
    <div className='rockstarDiv bgcover bgcenter bgNoRepeat'>
        <div className="row ps-lg-5 py-5">
            <div className="col-md-6 container d-flex flex-column gap-2 letterspace lh-base p-5 text-white">
                <h1 className='fw-light ms-lg-4'>Join the Discovemail 20+ rockstars family</h1>
                <p className=' ms-lg-4'>Discover the power of Discovemail and streamline your email marketing efforts. Our comprehensive platform offers a suite of tools to optimize your campaigns and achieve maximum results.</p>
                <a className='btn-primary ms-lg-4 col-lg-6 mt-3 px-5 py-2 rounded-2 serviceBtn text-center text-decoration-none' href='/signup' >Get Started</a>
            </div>
            <div className="col-md-6 d-flex justify-content-lg-end">
                <img src={teamImg} alt="image not found" className=' img-fluid ' />
            </div>
        </div>
    </div>
  )
}

export default Rockstar