import React, { useEffect, useState } from 'react';
import './CampaignReview.css';
import requestInstance from '../../../services/request';
import { API_ENDPOINT } from '../../../services/Api';
import Spinner from "../../../pages/loader/SimpleLoader";

export default function CampaignReview() {
  const [sendBulkMailData, setSendBulkMailData] = useState();
  const [loading, setLoading] = useState(false);
  const review_id = localStorage.getItem('Review_Data_Id')

  const handleReviewData = () => {
    setLoading(true);
    const newBody = {
      'user_campaign_create_id':review_id,
    }
    requestInstance
        .post(API_ENDPOINT.CAMPAIGN_REVIEW_DATA, newBody)
        .then((response) => {
            setSendBulkMailData(response?.data?.data);
            setLoading(false);
        })
        .catch((error) => {
            console.error('Error sending data:', error);
            setLoading(false);
        });
  };


  useEffect(() => {
    handleReviewData()
  }, [review_id]);


  return (
    <>
      {loading ? <Spinner /> : <div className='CampaignReview col-12 p-5'>
        <div className='gap-4 justify-content-between row'>
          
        {sendBulkMailData?.map((val)=>(
          <div class="card col-3 cursorPointer">
          <div class="card-body">
            <h6 class="card-title fw-bold">{val?.user_lead?.email}</h6>
            <h6 class="card-subtitle mb-2 text-body-secondary">{val?.email_subject}</h6>
            <p class="card-text" dangerouslySetInnerHTML={{ __html: val?.email_msg_body }}></p>
          </div>
        </div>
        ))}
        
        </div>
      </div>}
    </>
  )
}