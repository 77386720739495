import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { API_ENDPOINT } from "services/Api";
import requestInstance from "services/request";

export const fetchDashboardTableData = createAsyncThunk(
    'dashboardTable/fetchDashboardTableData',
    async () => {
        const response = await requestInstance.get(`${API_ENDPOINT.GET_ALL_SCRAPPED_DATA_API}`);
        return response.data;
    }
);

const initialState = {
    dashboardTableData: null
}

export const dashboardTableSlice = createSlice({
    name: 'dashboardTable',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDashboardTableData.pending, (state) => {
                state.loading = true
            })
            .addCase(fetchDashboardTableData.fulfilled, (state, action) => {
                state.dashboardTableData = action.payload
            })
            .addCase(fetchDashboardTableData.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message
            })
    }
})

export default dashboardTableSlice.reducer