import { useNavigate } from 'react-router-dom'
import TeamImage from '../../assets/team.webp'
import requestInstance from '../../services/request';
import { API_ENDPOINT } from '../../services/Api';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Spinner from '../loader/SimpleLoader';
import { BiSearch } from "react-icons/bi";
import useDebounce from '../../hooks/useDebounce';

const Team = () => {
  const navigate = useNavigate();
  const [userList, setUserList] = useState(null);
  const userId = useSelector(state => state?.user?.value.id);
  const [loading, setLoading] = useState(false);
  const [userRole, setUserRole] = useState('All');
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchValue = useDebounce(searchTerm, 900);

  useEffect(() => {
    let ignore = false;

    const getTeamUserData = async () => {
      try {
        setLoading(true);
        const response = await requestInstance.get(`${API_ENDPOINT.TEAM_USER_LIST}?head_user_id=${userId}&role=${userRole === 'All' ? '' : userRole}&search=${debouncedSearchValue}`);
        if (!ignore) {
          setUserList(response?.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    getTeamUserData();

    return () => { ignore = true };
  }, [userRole, debouncedSearchValue]);

  return (
    <div className='position-relative'>
      <div className='d-flex justify-content-between px-4 py-3'>
        <h3 className='f'>Team</h3>
        <button className='btn btn-danger' onClick={() => navigate('/settings/team/invite')}>+ Invite</button>
      </div>
      {loading ? <Spinner /> : userList?.is_team_created ?
        <div className='px-4 my-2'>
          <div className='d-flex justify-content-between align-items-center'>
            <div>
              {['All', 'Admin', 'Member'].map((role, index) => (
                <button key={index} className={`btn ${userRole === role ? 'btn-danger' : 'btn-outline-danger'} me-2`} onClick={() => setUserRole(role)}>{role}</button>
              ))}
            </div>
            <div>
            <div className=" input-group campaignSearch">
                <input
                  placeholder="Search..."
                  type="text"
                  className=" form-control py-2"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <span className="input-group-text login_icon">
                  <BiSearch />
                </span>
              </div>
            </div>
          </div>
          {
            userList?.data?.length === 0 ? <p className='text-center mt-5'>No Data Found</p> :
              <table className='campaignTable table'>
                <thead>
                  <tr>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {userList?.data?.map((user, index) => (
                    <tr key={index} className='bgBlueHover'>
                      <td className='px-3 py-4'>{user?.assigned_user?.email}</td>
                      <td>{user?.role}</td>
                      <td>{user?.assigned_user?.is_email_confirmed ? 'Active' : 'Pending'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
          }
        </div>
        : <div className='text-center mt-5'>
          <img className='w-50' src={TeamImage} alt="team" />
          <h4 className='m-0 mb-2'>Create your team in Discovemail</h4>
          <p>Invite people to your team to collaborate and benefit from a common subscription.</p>
        </div>}
    </div>
  )
}

export default Team