import React from 'react';
import TeamCards from './TeamCards';
import { teamData } from 'home_revamp/HomeData';
import '../home_revamp/Homeravamp.css';

const VikgolTeam = () => {
  return (
    <div className='vikTeam bgcenter bgcover py-5' >
    <div className="container">
    <h1 className="blueGradient text-center mb-4">Meet Our Team</h1>
    <TeamCards teamData={teamData} />
    </div>  
    </div>
  )
}

export default VikgolTeam