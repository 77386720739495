import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import Navbar from '../../new_home/Navbar_1'
import Footer from '../../new_home/Footer'
import { useFormik } from "formik";
import Loader from "../loader/Loader";
import requestInstance from "../../services/request";
import { API_ENDPOINT } from "../../services/Api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './Claim.css'
import ContactUsSection from '../../components/contact/ContactUsSection'

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "Please enter the email address";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  return errors;
};

export default function Claim() {

  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: ""
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      setButtonDisabled(true);
      handleSubscribeEmail(values, { resetForm });
    },
  });

  const handleSubscribeEmail = (values, { resetForm }) => {
    let data = JSON.stringify({
      email: values.email,
      data_type: "only_email",
    });
    requestInstance
      .post(API_ENDPOINT.CONTACT_API, data)
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.status);
          setLoading(false);
          resetForm();
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>Discovemail - Terms of service</title>
      </Helmet>

      <Navbar />

      <section className='d-flex align-items-center justify-content-center pt-4 claimSection'>
        <div className='container-fluid'>
          <div className='row align-items-center'>
            <div className='col-12 text-center pt-5 my-5 '>
              <h2 className='title'>Claim your <span className=' discove-primary'>email </span>address</h2>
              <p className='description my-4'>Claim your email address to delete the information connected.</p>
              <div className='row justify-content-center '>
                <div className='col-lg-8 text-start minHeight85'>
                  <form className="d-lg-flex align-items-lg-center gap-lg-3" onSubmit={formik.handleSubmit}>
                    <div className="flex-lg-fill mb-3 mb-lg-0">
                      <input
                        type="email"
                        className="form-control w-100 py-3"
                        id="email"
                        name="email"
                        placeholder="Enter your email address"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    <div className="p-0 findEmailbtn">
                      <button type="submit" className="btn btn-primary w-100 py-3 border-0" disabled={loading}>
                        {loading ? <Loader /> : "Claim the email address"}
                      </button>
                    </div>
                  </form>
                  {formik.touched.email && formik.errors.email ? (
                    <div className="errormsg">{formik.errors.email}</div>
                  ) : null}
                </div>
              </div>

            </div>
          </div>
        </div>
       
      </section>
      <ContactUsSection />
      <Footer />
    </>
  )
}
