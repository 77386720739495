import React, { useEffect, useState } from 'react'
import ReactPaginate from "react-paginate";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
import { IconContext } from "react-icons";
import '../allCampaigns/AllCampaigns.css';
import { useNavigate } from 'react-router-dom';
import { BiSearch } from "react-icons/bi";
import { API_ENDPOINT } from '../../../services/Api';
import requestInstance from '../../../services/request';
import { toast } from 'react-toastify';
import pauseIcon from '../../../assets/pause.png';
import resumeIcon from '../../../assets/play.png';
import archiveIcon from '../../../assets/archive.png';
import editIcon from '../../../assets/edit.png';
import deleteIcon from '../../../assets/delete.png';
import Spinner from "../../loader/SimpleLoader";
import { campaignId, campaignListId, campaignName, resetCampaign, campaignStatus, campaignSummaryId } from '../../../redux/slices/campaign/CampaignSlice';
import { useDispatch, useSelector } from 'react-redux';

export default function AllCampaigns({handleCampaignId, handleCampaignIndicator}) {
  const [campaign, setCampaign] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);
  const [deleteCampaign, setDeleteCampaign] = useState(null);
  const [archiveCampaign, setArchiveCampaign] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const email = useSelector((state)=>state?.gAuth?.signValue?.email);


  const [currentPage, setCurrentPage] = useState(0);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentCampaigns = campaign.slice(offset, offset + itemsPerPage);
  const pageCount = Math.ceil(campaign.length / itemsPerPage);


 
  const campaignDataFetch = (search) => {
    setIsLoading(true);
    requestInstance.get(API_ENDPOINT.CAMPAIGN_SUMMARY_DATA + `?search=${search ? search : ''}`)
        .then((res) => {
          setCampaign(res?.data?.data);
          dispatch(campaignStatus(res?.data?.data));
          setIsLoading(false)
          setTimeout(() => {
            handleTool();
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
        });  
  };

  // THIS PART OF THE CODE IS FOR API TESTING PURPOSE ..... 
  // const testCampaign =()=>{
  //   const ourUrl = `${API_ENDPOINT.CAMPAIGN_SUMMARY_DATA}?limit=10&offset=0` ;
  //   requestInstance.get(ourUrl).then((res)=>{
  //     console.log(res.data.data);
  //   })
  // }
  // testCampaign();

  const onSearch = (value) => {
    setSearchTerm(value);
  }

  const handleCampaignPause = (id) => {
    const newBody = {
      'campaign_list_id': id,
    }
    requestInstance
      .post(API_ENDPOINT.CAMPAIGN_PAUSE, newBody)
      .then((res) => {
        campaignDataFetch();
        toast.success(res.data.message);
      })
      .catch((error) => {
        console.error('Error sending data:', error);
      });
  };

  const handleCampaignResume = (id) => {
    const newBody = {
      'campaign_list_id': id,
    }
    requestInstance
      .post(API_ENDPOINT.CAMPAIGN_RESUME, newBody)
      .then((res) => {
        campaignDataFetch();
        toast.success(res.data.message);
      })
      .catch((error) => {
        navigate('/campaign/settings');
        console.error('Error sending data:', error);
      });
  };

  const handleCampaignArchive = (name) => {
    const newBody = {
      'campaign_name': name,
    }
    requestInstance
      .post(API_ENDPOINT.CAMPAIGN_ARCHIVE, newBody)
      .then((res) => {
        campaignDataFetch();
        toast.success(res.data.message);
      })
      .catch((error) => {
        console.error('Error sending data:', error);
      });
  };

  const handleCampaignEdit = (id) => {
    const newBody = {
      'campaign_list_id': id,
      'campaign_body': message,
    }
    requestInstance
      .post(API_ENDPOINT.CAMPAIGN_EDIT, newBody)
      .then((res) => {
        toast.success(res.data.message);
      })
      .catch((error) => {
        console.error('Error sending data:', error);
      });
  };

  const handleCampaignDelete = (name) => {
    const newBody = {
      'campaign_name': name,
    }
    requestInstance
      .post(API_ENDPOINT.CAMPAIGN_DELETE, newBody)
      .then((res) => {
        campaignDataFetch();
        toast.success(res.data.message);
      })
      .catch((error) => {
        console.error('Error sending data:', error);
      });
  };
  
  

  const calculateRelativeTime = (dateStr) => {
    const currentDate = new Date();
    const inputDate = new Date(dateStr);

    const timeDifferenceInSeconds = Math.floor((currentDate - inputDate) / 1000);

    if (timeDifferenceInSeconds < 60) {
      return 'Just now';
    } else if (timeDifferenceInSeconds < 3600) {
      const minutes = Math.floor(timeDifferenceInSeconds / 60);
      return `About ${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
    } else if (timeDifferenceInSeconds < 86400) {
      const hours = Math.floor(timeDifferenceInSeconds / 3600);
      return `About ${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
    } else {
      const days = Math.floor(timeDifferenceInSeconds / 86400);
      return `${days} ${days === 1 ? 'day' : 'days'} ago`;
    }
  };

  const colorPallet = {
    "Draft":'text-secondary',
    "In-Progress":"text-warning",
    "Completed":"text-success",
    "Scheduled":"text-info"
  }

  //   ---API for newCampaignApi---
  const newCampaignApi = () => {
    requestInstance
      .get(API_ENDPOINT.CAMPAIGN_DATA_CREATE)
      .then((res) => {
        dispatch(campaignName(res.data?.data?.campaign_name));
        dispatch(campaignListId(res.data?.data?.id));
        toast.success(res.data.message);
        navigate('/campaign/content');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //   ---API Tracker---
  const trackerApi = (campaignDetails) => {
    setIsLoading(true)
    dispatch(campaignName(campaignDetails?.campaign_name));
    dispatch(campaignId(campaignDetails?.id));
    dispatch(campaignSummaryId(campaignDetails?.campaign_summary?.campaign_id));
    handleCampaignId(campaignDetails?.id);
    handleCampaignIndicator(campaign);
    requestInstance
      .get(API_ENDPOINT.TRACKER_API+`/${campaignDetails?.campaign_name}`)
      .then((res) => {
        setIsLoading(false);
        if (res?.data?.screens_list?.length > 0) {
          switch (res?.data?.screens_list[0]) {
            case 'content_screen':
              navigate('/campaign/content');
              break;
            case 'audience_screen':
              navigate('/campaign/audience');
              break;
            case 'setting_screen':
              navigate('/campaign/settings');
              break;
            case 'review_screen':
              navigate('/campaign/review');
              break;
            default:
              navigate('/campaign/summary');
              break;
          }
        }else{
          navigate(`/campaign/summary/${campaignDetails?.id}/emails`);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const handleTool = ()=> {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new window.bootstrap.Tooltip(tooltipTriggerEl);
    });
  }

  useEffect(() => {
    let timer = setTimeout(() => {     
        campaignDataFetch(searchTerm);      
    }, 300);
    return () => clearTimeout(timer);
  }, [searchTerm]);

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
  };


  return (
    <>
    {isLoading ? <Spinner /> : 
    <div className='col-12 minHeight84vh'>
      <div className='d-flex align-items-center justify-content-between p-4 flex-column flex-md-row gap-3 gap-md-0'>
            <h5 className='text-dark fw-bold'>Campaigns</h5>
            <div className='d-flex align-items-center justify-content-between'>
              <div className=" input-group campaignSearch me-2">
                <span className="input-group-text login_icon">
                  <BiSearch />
                </span>
                <input
                  placeholder=" Search name"
                  type="text"
                  className=" form-control py-2"
                  value={searchTerm}
                  onChange={(e) => onSearch(e.target.value)}
                />
              </div>
              <button onClick={() => {newCampaignApi(); dispatch(resetCampaign())}} className='btn btn-disco px-3' >+ New campaign</button>
            </div>
      </div>

      <div className='row'>
        <div className='col-12 overflow-auto p-4'>
          <table className="campaignTable table">
           {campaign?.length > 0 ? <thead>
              <tr>
                <th scope="col" className='px-3'>NAME </th>
                <th scope="col" className='px-4'>STATUS</th>
                <th scope="col" className='px-2'>CREATED</th>
                <th scope="col" className='px-5'>PERFORMANCE</th>
                <th scope="col" className='px-3'></th>
                <th scope="col" className='px-3'></th>
              </tr>
            </thead> : <div className='d-flex justify-content-center align-items-center'>There are no records to display</div>}
            <tbody>
              {
                
                currentCampaigns.map((res, index) => (
                    <tr className='cursorPointer bgBlueHover' onClick={()=>trackerApi(res)}>
                      <td className='px-3 py-4'>
                        <div className=''>
                          <p className='campaignTitle mb-2'>{res?.campaign_name }</p>
                          <p className='campaignRecipients m-0'>{res?.lead_count} recipients {email}</p>
                        </div>
                      </td>
                      <td className='px-3 py-4'>
                      <div className={colorPallet[res?.campaign_summary?.campaign_status]}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-circle mx-2" viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                          <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z"/>
                        </svg>{res?.campaign_summary?.campaign_status}
                      </div>
                      </td>
                      <td className='px-2 py-4'>{calculateRelativeTime(res?.created_at)}</td>
                      <td className='px-0 py-4'>
                        <div className='d-flex justify-content-between'>
                            <div className='text-secondary'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-hourglass mx-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Scheduled" viewBox="0 0 16 16">
                              <path d="M2 1.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1-.5-.5zm2.5.5v1a3.5 3.5 0 0 0 1.989 3.158c.533.256 1.011.791 1.011 1.491v.702c0 .7-.478 1.235-1.011 1.491A3.5 3.5 0 0 0 4.5 13v1h7v-1a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351v-.702c0-.7.478-1.235 1.011-1.491A3.5 3.5 0 0 0 11.5 3V2h-7z"/>
                            </svg>{res?.campaign_summary?.total_scheduled_leads} 
                          </div>
                          <div className='text-primary'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope-check mx-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Sent" viewBox="0 0 16 16">
                              <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z"/>
                              <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-1.993-1.679a.5.5 0 0 0-.686.172l-1.17 1.95-.547-.547a.5.5 0 0 0-.708.708l.774.773a.75.75 0 0 0 1.174-.144l1.335-2.226a.5.5 0 0 0-.172-.686Z"/>
                            </svg>{res?.campaign_summary?.total_sent_emails}
                          </div>
                          <div className='text-success'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-hand-thumbs-up mx-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Successful" viewBox="0 0 16 16">
                              <path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z"/>
                            </svg>{res?.campaign_summary?.total_sent_emails}
                          </div>
                          <div className='text-danger'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle mx-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Failed" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                          </svg>{res?.campaign_summary?.total_error_emails}
                          </div>

                          {/* THIS IS FOR TEXT OPENED SECTION .............. */}
                          {/* <div className='text-danger'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle mx-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Opened" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                          </svg>{res?.campaign_summary?.total_error_emails}
                          </div> */}

                          <div className='text-warning'>
                          <svg aria-describedby="desc" aria-labelledby="title" role="img" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle mx-2" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Opened">
                          <title>Eye</title>
                          <desc>A solid styled icon from Orion Icon Library.</desc>
                          <path
    d="M63.1 30.9C62.6 30.1 50 12.5 32 12.5S1.4 30.1.9 30.9L.1 32l.8 1.1c.5.8 13.1 18.4 31.1 18.4s30.6-17.6 31.1-18.4l.8-1.1zM32 47.5C18.5 47.5 8 35.8 5 32c3-3.8 13.5-15.5 27-15.5S56 28.2 59 32c-3 3.8-13.5 15.5-27 15.5z"
    data-name="layer2"
    fill="#ffc107"
  />
  <path
    d="M32 19.5a12 12 0 1 0 12 12 12 12 0 0 0-12-12zm0 18a6 6 0 0 1-5.2-9 2 2 0 0 1 3.5 2 2 2 0 0 0-.3 1 2 2 0 0 0 2 2 2 2 0 1 1 0 4z"
    data-name="layer1"
    fill="#ffc107"
  />

  </svg>{res?.campaign_summary?.total_opened_emails} </div>


                          



                        </div>
                      </td>
                      <td className='px-3 text-center py-4'>
                        <div className='d-flex'>
                          {(res?.campaign_summary?.campaign_status !== "Paused" && res?.campaign_summary?.campaign_status !== "Draft") &&
                            <div className='me-3 ' data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Paused"
                              disabled={(res?.campaign_summary?.campaign_status === "In-Progress" || res?.campaign_summary?.campaign_status === "Scheduled" || res?.campaign_summary?.campaign_status === "Completed")?false:true}
                              onClick={(e) => {
                              e.stopPropagation();
                                handleCampaignPause(res?.id)
                            }}>
                              <img src={pauseIcon} alt='img not found' width='20px' className='img-fluid' />
                            </div>
                          }
                          {res?.campaign_summary?.campaign_status === "Paused" &&
                          <div className='me-3' data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Resume" onClick={(e) => {
                            e.stopPropagation();
                              handleCampaignResume(res?.id)
                          }}>
                            <img src={resumeIcon} alt='img not found' width='20px' className='img-fluid' />
                          </div>
                          } 
                          {res?.campaign_summary?.campaign_status !== "Draft" &&
                            <div data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Archive">
                              <div data-bs-toggle="modal" className='me-3' data-bs-target="#archiveModal" onClick={(e) => {
                                e.stopPropagation();
                                setArchiveCampaign(res?.campaign_name);
                              }}>
                                <img src={archiveIcon} alt='img not found' width='20px' className='img-fluid' />
                              </div>
                            </div>
                          }
                          {res?.campaign_summary?.campaign_status !== "Draft" &&
                            <div data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Edit">
                              <div className='me-3'
                                // disabled={res?.campaign_summary?.campaign_status !== "Draft" ? false : true}
                                data-bs-toggle="modal" data-bs-target="#editModal" onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedCampaignId(res?.id);
                                }}>
                                <img src={editIcon} alt='img not found' width='20px' className='img-fluid' />
                              </div>
                            </div>
                          }
                          {res?.campaign_summary?.campaign_status === "Draft" &&
                            <div data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Delete">
                              <div data-bs-toggle="modal" data-bs-target="#deleteModal" onClick={(e) => {
                              e.stopPropagation();
                                setDeleteCampaign(res?.campaign_name);
                            }}>
                              <img src={deleteIcon} alt='img not found' width='22px' className='img-fluid' />
                              </div>
                            </div>
                          }
                        </div>
                      </td>
                      <td className='px-3 text-end py-4'>{'>'}</td>
                    </tr>
                  ))
              }
            </tbody>
          </table>
          
          {campaign?.length > itemsPerPage && (
            <div className='paginationContainer'>
            <div>
            <label htmlFor="campaignRownumbers">Number of rows</label>
            <select name="" id="campaignRownumbers" onChange={handleItemsPerPageChange} value={itemsPerPage} >
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="30">30</option>
              <option value="50">50</option>
            </select>
            {/* <p>this is {itemsPerPage}</p> */}
            </div>
            
            <ReactPaginate
            previousLabel={"< previous  "}
            nextLabel={"  next >"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />

          </div>
          
        )}
        </div>
      </div>
        </div>}
      
      {/* ----------------------Edit Modal---------------------- */}
      <div className="modal fade" id="editModal" tabindex="-1" aria-labelledby="editModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="editModalLabel">Campaign Edit</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className=''>
                <label className='input-group text-dark fw-semibold mb-2'>Message</label>
                <textarea rows="5" className="form-control" placeholder="Message"
                  name="message"
                  onChange={(e) => setMessage(e.target.value)}
                  value={message} ></textarea>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={(e) => {
                handleCampaignEdit(selectedCampaignId)
              }}>Update</button>
            </div>
          </div>
        </div>
      </div>

      {/* ------------------------Delete Modal----------------------- */}
      <div className="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-4" id="deleteModalLabel">Delete campaign</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className=''>
                <p>Are you sure to delete the campaign "{deleteCampaign}"?</p>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
              <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={(e) => {
                handleCampaignDelete(deleteCampaign)
              }}>Delete</button>
            </div>
          </div>
        </div>
      </div>

      {/* ------------------------Archive Modal----------------------- */}
      <div className="modal fade" id="archiveModal" tabindex="-1" aria-labelledby="archiveModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="archiveModalLabel">Archive campaign</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className=''>
                <p>Archived campaigns cannot send emails and cannot be restored.</p>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
              <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={(e) => {
                handleCampaignArchive(archiveCampaign)
              }}>Archive</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
