import React, { useEffect, useRef, useState } from 'react'
import ReactQuill from 'react-quill';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import requestInstance from "../../../services/request";
import { API_ENDPOINT } from "../../../services/Api";
import { campaignContentPersonifyMail, campaignContentPersonifyMailAttribute, resetPersonifyMail } from '../../../redux/slices/campaign/CampaignSlice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import './CampaignEmailTemplate.css'

const personify_dropdown = [{ key: "First Name", value: 'first_name' }, { key: "Last Name", value: 'last_name' }, { key: "Email", value: 'email' }, { key: "Phone", value: 'phone_number' }, { key: "Company", value: 'company_name' }, { key: "Company Website", value: 'company_website_url' }, { key: "Designation", value: 'designation' }]


const CreateEmailTemplate = () => {
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [addCursorType, setAddCursorType] = useState(null)
  const quillRef = useRef();
  const subId = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const personifyName = useSelector((state) => state?.campaign?.campaignContentPersonifyMail);
  const selectedAttribute = useSelector((state) => state?.campaign?.campaignContentPersonifyMailAttribute);
  const {state: templateData} = useLocation();

  useEffect(() => {
    if (templateData) {
      setMessage(templateData?.template_body)
      setSubject(templateData?.template_subject)
    }
  }, []);

  const insertTextAtCursor = () => {
    if (quillRef.current && selectedAttribute && addCursorType === 'body') {
      const quill = quillRef.current.getEditor();
      const mergeFieldText = `{{${selectedAttribute}|${personifyName}}}`;
      const selection = quill.getSelection(true);
      quill.insertText(selection.index, mergeFieldText);
    }

    if (subId.current && selectedAttribute && addCursorType === 'subject') {
      const currentSubject = subject || ''; // Ensure subject is initialized
      const mergeFieldText = `{{${selectedAttribute}|${personifyName}}}`;
      const cursorPosition = subId.current.selectionStart || 0; // Get cursor position
      const newSubject = `${currentSubject.slice(0, cursorPosition)}${mergeFieldText}${currentSubject.slice(cursorPosition)}`;
      setSubject(newSubject);
    }
    dispatch(resetPersonifyMail());
  };

  const handelSend = () => {
    if (templateName === "") {
      toast.warn("Please enter template name")
      return;
    }
    if (subject === "") {
      toast.warn("Please enter subject")
      return;
    }

    if (message === "") {
      toast.warn("Please enter message")
      return;
    }    
      setIsLoading(true);
      const formData = new FormData();
      formData.append('temp_name', templateName);
      formData.append("subject", subject);
      formData.append("body", message);
      requestInstance.post(API_ENDPOINT.CAMPAIGN_CREATE_TEMPLATES, formData)
        .then(res => {
          if (res.status === 201) {
            toast.success(res?.data?.message)
            setIsLoading(false);
            navigate(`/campaign/all-template`);
          }
        })
        
        .catch(err => toast.error(err.data.message))
        .finally(() => {
          setIsLoading(false);
        });
  }

  return (
    <>

      <div className='mt-4 d-flex justify-content-between align-items-center'>
        <div className='d-flex cursorPointer'>
          <div>
            <Link to="/campaign/all-template">
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-left-circle text-dark" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
              </svg>
            </Link>
          </div>
          <div>
            <span className='fs-5 fw-semibold px-2'>Create new template</span>
          </div>
        </div>
      </div>

      <div className="col-md-9 mt-5">

        <div className="row mt-2">
          <div className="col-4 mb-3">
            <input type="text" className='form-control' placeholder='Template Name' name="templateName"
              onChange={(e) => setTemplateName(e.target.value)}
              value={templateName} />
          </div>
          <div className="col-3">
            <div className='d-flex justify-content-center'>
              <button className="btn test-mail-btn" data-bs-toggle="modal" data-bs-target="#exampleModal">
                Personify Mail
              </button>
            </div>
          </div>
        </div>


        <div className="row h-100">
          <div className="col-md-10">
            <div className="campaign-subject input-group">
              <input
                ref={subId}
                type="text"
                className="campaign-subject__input form-control border-bottom-0"
                placeholder="Subject"
                name="subject"
                onChange={(e) => setSubject(e.target.value)}
                value={subject}
                onClick={() => { setAddCursorType('subject') }}
                onBlur={() => { setAddCursorType('subject') }}
              />
            </div>

            <div className="campaign-template input-group">
              <ReactQuill
                ref={quillRef}
                value={message}
                onChange={(e) => setMessage(e)}
                className="form-control campaign-message__textarea h-100 p-0 border-0"
                onClick={() => { setAddCursorType('body') }}
                onBlur={() => { setAddCursorType('body') }}
              />
            </div>
            <button disabled={isLoading} className='btn btn-danger mt-2' onClick={handelSend}>Create</button>
          </div>
        </div>
      </div>

      {/* modal starts */}

      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">Add an attribute</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className='my-4'>
                <label className='input-group text-dark fw-semibold'>Attribute</label>
                <select className="form-select" aria-label="Default select example" onChange={(e) => dispatch(campaignContentPersonifyMailAttribute(e.target.value))}>
                  {personify_dropdown.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.key}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input-group mb-3">
                <label className='input-group text-dark fw-semibold'>Fallback</label>
                <input type="text" value={personifyName} onChange={(e) => dispatch(campaignContentPersonifyMail(e.target.value))} className="form-control" placeholder="Default value for empty attribute" aria-label="Username" aria-describedby="basic-addon1" required />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={insertTextAtCursor} disabled={personifyName === ''}>Insert</button>
            </div>
          </div>
        </div>
      </div>

      {/* modal ends */}
    </>
  )
}

export default CreateEmailTemplate;