import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from "react-router-dom";
import Navbar from '../../new_home/Navbar_1'
import Footer from '../../new_home/Footer'
import emailVerifier from '../../assets/email-verifier-icon.svg'
import companySearch from '../../assets/company-search-icon.svg'
import './Finder.css'
import NavbarDash from '../dashboard/Navbar'

export default function Finder() {

  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState();
  const token = localStorage.getItem('find_email')

  useEffect(() => {
    setIsLogin(token?.length > 0 ? true : false);
  }, []);

  const handleEmailFromNameDomain = () => {
    navigate('/email-from-name-and-domain');
  };

  const handleSearchCompany = () => {
    navigate('/search-company');
  };

  return (
    <>
      <Helmet>
        <title>Discovemail</title>
      </Helmet>

      {isLogin ? <NavbarDash /> : <Navbar />}
      <section className='d-flex align-items-center justify-content-center pt-4 finderSection'>
        <div className='container-fluid'>
          <div className='row align-items-center'>
            <div>
              <h1 className="m-0 text-center mainTitle mb-3">Ensuring Accuracy and Validity</h1>
              {/* <h2>hiii</h2> */}
              <div className='col-12'>
                <div className='row justify-content-center'>
                  <div className='col-md-8 col-lg-6'>
                    <p className="mainDescription  text-center px-lg-4 mb-5">We pride ourselves on offering the most accurate email verification on the market, with unprecedented customer support and an easy to use platform.</p>
                  </div>
                </div>
                <div className='row gap-4 justify-content-center'>
                  <div className='col-md-4 col-xl-2 finder-card me-2 cursorPointer'>
                    <div className="text-center py-5" onClick={handleEmailFromNameDomain}>
                      <img src={emailVerifier} alt='img not found' className='img-fluid' />
                      <h2 className="cardTitle pt-2">Email finder</h2>
                    </div>
                  </div>
                  <div className='col-md-4 col-xl-2 finder-card cursorPointer'>
                    <div className="text-center py-5" onClick={handleSearchCompany}>
                      <img src={companySearch} alt='img not found' className='img-fluid' />
                      <h2 className="cardTitle pt-2">Find company</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {!isLogin ? <Footer /> : null}
    </>
  )
}
