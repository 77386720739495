import React from 'react';
import "../assets/about/accordFrame.svg"
import { accordionData } from 'home_revamp/HomeData';
import HorizontalAccordion from 'components/HorizontalAccordion';

const Progress = () => {
  return (
    <div className='progressDiv bgcover bgcenter p-5'>
        <HorizontalAccordion accordionData ={accordionData} />
    </div>
  )
}

export default Progress