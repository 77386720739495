import React, { useEffect, useState } from 'react';
import requestInstance from '../../services/request';
import { API_ENDPOINT } from '../../services/Api';
import { Link, useNavigate } from 'react-router-dom';
import './LeadConfig.css';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { leadSummaryData } from '../../redux/slices/lead/LeadSlice';


const tableHeader = [
    { id: 1, value: 'S.no', postKey: 'sr_no' },
    { id: 2, value: 'First name', postKey: 'first_name' },
    { id: 3, value: 'Last name', postKey: 'last_name' },
    { id: 4, value: 'Email (required)', postKey: 'email' },
    { id: 5, value: 'Linkedin username', postKey: 'linkedin_username' },
    { id: 6, value: 'Phone number', postKey: 'phone_number' },
    { id: 7, value: 'Company name', postKey: 'company_name' },
    { id: 8, value: 'Designation', postKey: 'designation' },
    { id: 9, value: 'Location', postKey: 'location' },
    { id: 10, value: 'Company numbers', postKey: 'company_numbers' },
    { id: 11, value: 'Company emails', postKey: 'company_emails' },
    { id: 12, value: 'Profile urls', postKey: 'profile_url' },
];



const LeadConfig = () => {
    const [buttonState, setButtonState] = useState(true);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const leadName = useSelector((state) => state.lead.leadNameData);
    const tableDataList = useSelector((state) => state.lead.leadTableData);
    const [selectedValues, setSelectedValues] = useState({});
    const [selectedKeys, setSelectedKeys] = useState([]);

    console.log('tableDataList', tableDataList)
    const handleSelectChange = (e, postKey, index) => {

        const existingItemIndex = selectedKeys.findIndex(item => item?.index === index);
        if (existingItemIndex !== -1) {
            let newArray = [...selectedKeys]
            newArray[existingItemIndex] = { 'index': index, 'key': e.target.value };
            setSelectedKeys([...newArray])
        } else {
            setSelectedKeys([...selectedKeys, { 'index': index, 'key': e.target.value }])
        }
        const selectedValue = e.target.value;       
            setSelectedValues(prevState => ({
                ...prevState,
                [postKey]: selectedValue
            }));
    };

    const handleImportData = () => {
        setButtonState(false);
        let filteredData = [...tableDataList];

        const isEmailSelected = Object.values(selectedValues).includes('email');
        if (!isEmailSelected) {
            setButtonState(true);
            toast.error('please Select email id');
            return;
        }

        // Create a new array to store the modified data
        const newDataArray = [];

        // Iterate over filteredData
        filteredData.forEach((dataItem) => {
            // Create an object to store the modified data for each item
            const modifiedDataItem = {};
            // Iterate over selectedValues
            for (const [key, value] of Object.entries(selectedValues)) {
                // Check if the key exists in the current dataItem
                if (dataItem.hasOwnProperty(key)) {
                    // If it exists, add it to the modifiedDataItem with the value from selectedValues
                    modifiedDataItem[value] = dataItem[key];
                    console.log('--------------->4', modifiedDataItem[value])
                } else {
                    // If it doesn't exist, add it to the modifiedDataItem with the original value from dataItem
                    modifiedDataItem[value] = '';
                }
            }

            // Push the modifiedDataItem to the newDataArray
            newDataArray.push(modifiedDataItem);
        });


        const newBody = {
            "source": 'import_file',
            "leads_name": leadName,
            "data": newDataArray,
        }
        requestInstance
            .post(API_ENDPOINT.LEAD_CONFIG, newBody)
            .then((response) => {
                localStorage.setItem('tableData', '');
                toast.success(response?.data?.message);
                dispatch(leadSummaryData((response.data)));
                navigate('/lead/import-leads/leads-summary');
            })
            .catch((error) => {
                setButtonState(true)
                console.error('Error sending data:', error);
            });
    };

    const checkPostKey = (postKey) => {
        let data = selectedKeys.filter(item => item.key === postKey);
        return data.length > 0 ? true : false;
    }


    return (
        <div className='container'>
            <div className='row mt-4'>
                <div className='col-12 d-flex cursorPointer'>
                    <div>
                        <Link to={'/lead/import-leads'}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-left-circle text-dark" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                            </svg>
                        </Link>
                    </div>
                    <div>
                        {/* <span className='fs-5 fw-semibold px-2'>Select the columns in your file</span> */}
                        <span className='fs-5 fw-semibold px-2'>Go Back</span>
                    </div>
                </div>

                {/* Table implementation */}
                <div className='tableConfig overflow-x-scroll mt-4'>
                    <h4 className='text-start fw-semibold text-decoration-underline'>Preview</h4>
                    <table className="table w-100">
                        <thead>
                            <tr>
                                {tableHeader.map((header, index) => (
                                    <th key={index}>
                                        <select
                                            onChange={(e) => handleSelectChange(e, header.postKey, index)}
                                            value={selectedValues[header.postKey] || ''}
                                        >
                                            <option value="">Select</option>
                                            {tableHeader.map((option, idx) => (
                                                <option key={idx} value={option.postKey}
                                                    disabled={checkPostKey(option?.postKey)}>
                                                    {option.value}
                                                </option>
                                            ))}
                                        </select>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {tableDataList?.slice(0, 10)?.map((val, index) => (
                                <tr key={val.id}>
                                    <td>{index + 1}</td>
                                    <td>{val.first_name}</td>
                                    <td>{val.last_name}</td>
                                    <td>{val.email}</td>
                                    <td>{val.linkedin_username}</td>
                                    <td>{val.phone_number}</td>
                                    <td>{val.company_name}</td>
                                    <td>{val.designation}</td>
                                    <td>{val.location}</td>
                                    <td>{val.company_numbers}</td>
                                    <td>{val.company_emails}</td>
                                    <td>{val.profile_url}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {/* Table implementation ends */}
            </div>
            <button className='btn btn-disco mt-3' onClick={handleImportData} disabled={buttonState === false}>Start import</button>
        </div>
    )
}

export default LeadConfig;