export const optionsDoughnutCab = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "60%",
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          padding: 20,
          boxWidth: 10,
          boxHeight: 11,
          font: {
            size: 14,
            weight: "600",
          },
        },
        title: {
          padding: 20,
        },
      },
      title: {
        display: true,
        text: "Analytics",
        align: "start",
        font: {
          size: 20,
          weight: 600,
        },
        padding: {
          top: 0,
          bottom: 20,
        },
        color: "#282828",
      },
    },
  };

  export const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        border: {
          color: "#000000",
        },
        ticks: {
          display: false,
        },
      },
      y: {
        border: {
          color: "#000000",
        },
        ticks: {
          callback(value) {
            return value;
          },
          color: "#000000",
        },
      },
    },
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          mode: 'x'
      },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'x',
        },
      },
      
      transitions: {
        zoom: {
          animation: {
            duration: 100,
            easing: 'easeOutCubic'
          }
        }
      },
      pan: {
        enabled: true,
        mode: 'x',
      },
      legend: {
        position: "bottom",
        onClick: (event, legendItem, legend) => {
          handleLegendsOnclick(event, legendItem, legend)
        },
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          boxWidth: 10,
          boxHeight: 8,
          padding: 20,
          generateLabels: (chart) => {
            return handleLegendsColor(chart)
          },
          font: {
            size: 14,
            opacity: 1,
            weight: "600",
          },
        },
      },
      title: {
        display: true,
        text: "Monthly lead ratio",
        align: "start",
        font: {
          size: 20,
          weight: 600,
        },
        padding: {
          top: 0,
          bottom: 20,
        },
        color: "#282828",
      },
    },
    elements: {
      point: {
        borderWidth: 5,
        radius: 2,
        backgroundColor: "white",
        borderColor: "white",
      },
    },
  };

  //handle legends pointer onClick
const handleLegendsOnclick = (event, legendItem, legend) => {
  const datasets = legend.legendItems.map((dataset, index) => {
    return dataset.text;
  });

  const index = datasets.indexOf(legendItem.text);
  if (legend.chart.isDatasetVisible(index) === true) {
    legend.chart.hide(index);
  } else {
    legend.chart.show(index);
  }
}

//handle legends font textColor
const handleLegendsColor = (chart) => {
  let visiblity = [];

  for (let i = 0; i < chart?.data?.datasets?.length; i++) {
    if (chart?.isDatasetVisible(i) === false) {
      visiblity.push(true);
    } else {
      visiblity.push(false);
    }
  }
  return chart?.data?.datasets?.length && chart.data.datasets.map((dataset, index) => ({
    text: dataset.label,
    fillStyle: dataset?.backgroundColor,
    strokeStyle: dataset.borderColor,
    //TODO: this key is needed for to change font size color
    // fontColor: dataset?.backgroundColor,
    fontColor: "#666666",
    hidden: visiblity[index],
  }));
}