import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: null,
    planStatus: null,
  bulkFinderTableData: null,
  bulkFinderummaryData: null,
  bulkFinderNameData: null,
  totalUserRows: 0,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userProfile: (state, action) => {
      state.value = action.payload;
    },
    totalUserRows: (state, action) => {
      state.totalUserRows = action.payload;
    },
    planStatus: (state, action) => {
      state.planStatus = action.payload;
    },
    bulkFinderTableData: (state, action) => {
      state.bulkFinderTableData = action.payload;
    },
    bulkFinderummaryData: (state, action) => {
      state.bulkFinderummaryData = action.payload;
    },
    bulkFinderNameData: (state, action) => {
      state.bulkFinderNameData = action.payload;
    },
  },
})

export const { userProfile, planStatus, bulkFinderTableData, bulkFinderummaryData, totalUserRows, bulkFinderNameData } = userSlice.actions

export default userSlice.reducer