import React, { useEffect, useState } from 'react';
import './BulkSummary.css';
import Navbar_Dash from '../dashboard/Navbar';
import Spinner from "../../pages/loader/SimpleLoader";
import { useNavigate, useParams } from 'react-router-dom';
import requestInstance from '../../services/request';
import { API_ENDPOINT } from '../../services/Api';
import editIcon from '../../assets/edit.png';
import { bulkFinderummaryData, bulkFinderNameData } from '../../redux/slices/user/UserSlice';
import { toast } from 'react-toastify';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';

ChartJS.register(ArcElement, Tooltip, Legend);

const BulkFinderSummary = () => {
  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState(false);
  const [allData, setAllData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [lastIndex, setLastIndex] = useState(0);
  const [nextData, setNextData] = useState();
  const [prevData, setPrevData] = useState();
  const [emailFilter, setEmailFilter] = useState();
  const [edit, setEdit] = useState(false);
  const finderNameData = useSelector((state) => state.user.bulkFinderNameData);
  const [editValue, setEditValue] = useState(finderNameData);


  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const handleFilterChange = (event) => {
    setEmailFilter(event.target.value);
  };
  const columns = [
    {
      name: 'First Name',
      selector: row => row?.first_name,
      sortable: true,
    },
    {
      name: 'Last Name',
      selector: row => row?.last_name,
      sortable: true,
    },
    {
      name: 'Domain',
      selector: row => row?.domain,
      sortable: true,
    },
    {
      name: 'email',
      selector: row => row?.email,
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => row?.email_count === 1?'valid':'Invalid',
      sortable: true,
    },

  ];

 

  const handleDownload = () => {
    requestInstance
      .get(API_ENDPOINT.BULK_EMAIL_FINDER_INDIVIDUAL_DOWNLOAD + `?bulk_email_list_id=${params?.id}&include_emails=${emailFilter}`)
      .then((response) => {
        console.log(response); // Log the response for debugging
        const contentType = response.headers['content-type']; // Get the content type from headers

        if (contentType === 'text/csv' || contentType === 'application/csv') {
          const href = URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = href;

          toast.success("Download successful");
          link.setAttribute("download", "Discovemail.csv");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        } else {
          // Handle the case where the server responded with unexpected content type
          toast.error("Unexpected content type: " + contentType);
        }
      })
      .catch((err) => {
        if (err?.response?.status == 401) {
          navigate("/signin");
          toast.error(err?.response?.statusText);
        } else {
          // Handle other errors
          console.error(err);
        }
      });
  }

  const fetchUsers = async (page) => {
    setLoading(true);
    try {
      const apiPath = `${API_ENDPOINT.BULK_EMAIL_FINDER_EACH_COUNT}?bulk_email_list_id=${params?.id}&limit=${perPage}&offset=${(page - 1) * perPage}`;
      const response = await requestInstance.get(apiPath);
      // dispatch(bulkFinderummaryData((response?.data)));
      setAllData(response?.data);
      setTotalRows(response.data.count);
      setCurrentPage(page);
      setLastIndex((page - 1) * perPage);
      setNextData(response.data.next || null);
      setPrevData(response.data.previous || null);
      setLoading(false);
      
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const handlePageChange = async (page) => {
    try {
      setPending(true);
      const apiPath = `${API_ENDPOINT.BULK_EMAIL_FINDER_EACH_COUNT}?bulk_email_list_id=${params?.id}?&limit=${perPage}&offset=${(page - 1) * perPage}`;
      const response = await requestInstance.get(apiPath);
      setAllData(response.data.results);
      setCurrentPage(page);
      setLastIndex((page - 1) * perPage);
      setTotalRows(response.data.count);
      setNextData(response.data.next);
      setPrevData(response.data.previous);
      setPending(false);
    } catch (error) {
      setPending(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {    
      fetchUsers(1);
    
    console.log(allData?.data?.status, "status");
  }, []);

  const handleEmailFinderRename = () => {
    const newBody = {
      'new_name': editValue,
    }
    requestInstance
      .put(API_ENDPOINT.BULK_EMAIL_FINDER_RENAME + `/${params?.id}`, newBody)
      .then((res) => {
        fetchUsers(1);
        setEdit(false);
        navigate('/bulk-tasks-finder/bulk-email-finder');
        toast.success(res.data.message);
      })
      .catch((error) => {
        console.error('Error sending data:', error);
      });
  };

  const handleEditChange = (event) => {
    setEditValue(event.target.value);
  };

  return (
    <>
      <Navbar_Dash />


      {loading ? <Spinner /> :
        <div className="bulkSummary_main py-5 mt_6rem">
          <div className="bulkSummary_card bg-white">
            <div className="p-4">
              <div className="mt-3">
                <div className='d-flex justify-content-between container align-items-baseline'>
                  <div className="d-flex gap-3 align-items-baseline">
                    <div onClick={() => { navigate('/bulk-tasks-finder/bulk-email-finder') }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-left-circle cursorPointer" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                      </svg>
                    </div>
                    <div>
                      <h6 className='fw-bold'>
                        {edit ? (
                          <div>
                            <input
                              className='py-1 fs-6 ms-2'
                              type="text"
                              value={editValue}
                              onChange={handleEditChange}
                              placeholder="Enter something..."
                            />
                            <button className=' ms-2 btn btn-danger btn-sm' onClick={handleEmailFinderRename}>Update</button>
                            <button className='ms-2 btn btn-secondary btn-sm' onClick={() => { setEdit(false) }}>Cancel</button>
                          </div>) :
                          <div>
                            <span className='fs-6 fw-bold ms-2'>{editValue} <img src={editIcon} alt='img not found' width='20px' className='img-fluid cursorPointer' onClick={() => setEdit(true)} /></span>
                          </div>
                        }
                      </h6>
                      <small className='text-secondary'>
                        {/* {allData?.verified_emails_count} email addresses verify */}
                        Name and domain verify
                      </small>
                    </div>
                  </div>
                </div>
                <div className='hrLine my-4'></div>
                {/* DOWNLOAD SECTION */}
                
                  
                  <div className='d-flex justify-content-between align-items-baseline'>
                    <div>
                      <h6 className='fw-bold'>Download in CSV</h6>
                    </div>
                    <div>
                      <button className='btn btn-danger' data-bs-toggle="modal" data-bs-target="#exampleModal">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download mx-2" viewBox="0 0 16 16">
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                        </svg>Download
                      </button>
                    </div>
                  </div>
                
                <div className='hrLine my-4'></div>
                {/* CHART SECTION */}
                
                {/* Bulk List  */}
                <div>
                  <h6 className='fw-bold'>Valid {allData?.total_email_count} out of {allData?.count}</h6>
                  <div>
                    <div className='col-12 minHeight84vh'>
                      <div className='row'>
                        <div className='col-12 bulkSummaryTable overflow-auto p-4'>
                          <DataTable
                            columns={columns}
                            data={allData?.data}
                            progressPending={pending}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            // onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}

      {/* -------Download modal------- */}

      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">Download the results in CSV</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className='my-4'>
                <h5>Valid {allData?.total_email_count} out of {allData?.count}</h5>
                <div className='form-check form-check-inline'>
                  <input type='radio' className='form-check-input' id='allListEmailsRadio' name='emailFilter' value='' checked={!emailFilter} onChange={handleFilterChange} />
                  <label className='form-check-label' htmlFor='allListEmailsRadio'>All list</label>
                </div>
                <div className='form-check form-check-inline'>
                  <input type='radio' className='form-check-input' id='validEmailsRadio' name='emailFilter' value='with_valid_emails' checked={emailFilter} onChange={handleFilterChange} />
                  <label className='form-check-label' htmlFor='validEmailsRadio'>Valid only</label>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={handleDownload}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download mx-2" viewBox="0 0 16 16">
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                </svg>Download</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BulkFinderSummary;