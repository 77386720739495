import React, { useEffect, useState } from "react";
import Navbar from "new_home/Navbar_1";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import Helmet from "react-helmet";
import Loader from "../loader/Loader";
import requestInstance from "../../services/request";
import { Typewriter, Cursor } from "react-simple-typewriter";
import { API_ENDPOINT } from "../../services/Api";
import { setCurrentUser } from "../../utils/utils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { useDispatch } from "react-redux";
import {
  googleAuth,
  googleSign,
} from "../../redux/slices/googleAuth/GoogleAuthSlice";
import signInImg from "../../assets/revampHomeassets/signInIng.svg";
import signInBtn from "../../assets/revampHomeassets/signInBtn.svg";
import GoogleBtn from "../../assets/btn_google_signin_dark_normal_web.png";
import GoogleBtnPressed from "../../assets/btn_google_signin_dark_pressed_web.png";
import "./NewSignIn.css";

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = "Please enter the email address";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.password) {
    errors.password = "Please enter the password";
  } else if (!/^(?=.*?[a-z])/i.test(values.password)) {
    errors.password = "Must contain lowercase";
  } else if (!/^(?=.*?[0-9])/i.test(values.password)) {
    errors.password = "Must contain number";
  } else if (!/.{4,}/i.test(values.password)) {
    errors.password = "Must have 4 characters";
  }

  return errors;
};

const RevampSignIn = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [hidePassword, setHidePassword] = useState(true);
  const dispatch = useDispatch();
  const [currentImage, setCurrentImage] = useState(signInBtn);
  const searchParams = new URLSearchParams(document.location.search);

  useEffect(() => {
    if (searchParams.get("code")) {
      requestInstance.post(API_ENDPOINT.GOOGLE_SIGN_IN_VERIFY, {
        "code": searchParams.get("code"),
        "state": searchParams.get("state")
      }).then((res) => {
        setCurrentUser(res?.data?.access);
        toast.success(res?.data?.status);
        navigate("/dashboard");
      }).catch((err) => {
        console.log(err);
      })
    }
  }, []);

  const handleGoogleSignIn = async () => {
    setCurrentImage(signInBtn);

    try {
      const response = await requestInstance.get(API_ENDPOINT.GOOGLE_SIGN_IN);
      window.open(response?.data?.url, '_self');
    } catch (error) {
      console.log(error);
    }
  }

  function togglePasswordVisibility() {
    setHidePassword(!hidePassword);
  }

  const handleClickSignUp = () => {
    navigate("/signup");
  };

  const verifyUser = () => {
    requestInstance
      .get(API_ENDPOINT.CAMPAIGN_SIGNATURE_API)
      .then((res) => {
        if (res.data.status !== 'fail') {
          dispatch(googleAuth(true));
          dispatch(googleSign(res.data.data))
        }
      })
      .catch((err) => {
        console.log(err);
      })
  }

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("find_email"));
    if (user) {
      navigate("/dashboard");
    }
    window.scroll(0, 0);
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate,
    onSubmit: (login) => {
      setLoading(true);
      setButtonDisabled(true);
      handleSignIn(login);
    },
  });

  const handleSignIn = (login) => {
    let value = {
      email: login.email,
      password: login.password,
    };

    requestInstance
      .post(API_ENDPOINT.LOGIN_API, value)
      .then((res) => {
        if (res.status === 200) {
          setCurrentUser(res.data.access);
          toast.success(res.data.status);
          verifyUser();
          navigate("/dashboard");
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const TITLE = "Discovemail-Login";
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      
      <section className="container-fluid loginPageBg mx-0 px-0">
      <Navbar />
        <div className="row pt-4 height100vh px-0 mx-0">
          <div className="col-lg-6 d-flex flex-column gap-1 pt-5 px-md-5">
            <h1 className="px-lg-5 mt-md-5 fw600 fs50 lh-1 hexbabfc2">
              Your all-in-one lead generation platform{" "}
            </h1>
            <h1 className="px-lg-5 fw600 fs50 ">
              <span className="gradient-text">
                <Typewriter
                  words={[
                    " Finder",
                    " Verifier",
                    "Discover Companies",
                    "Find People by Domain",
                    "Campaigns",
                  ]}
                  loop={true}
                  cursor3
                  cursorStyle="|"
                  typeSpeed={70}
                  deleteSpeed={50}
                  delaySpeed={1000}
                />
              </span>
              <span style={{ color: "#bc20b5" }}>
                <Cursor />
              </span>
            </h1>
            <img src={signInImg} className="px-lg-5" alt="image not found" />
          </div>
          <div className="col-lg-6  loginRightBg pt-lg-4">
            <div className="row justify-content-center pt-5 px-md-5">
              <div className="col-lg-10">
                <h1 className="loginTitle fs2 my-2">Welcome back 👋</h1>
                <p className="hex707070" >
                  Today is a new day. It's your day. You shape it. Sign in and
                  enjoy our comprehensive lead generation features
                </p>
                <div className="">
                  <form onSubmit={formik.handleSubmit} className="">
                  <label htmlFor="email">Email</label>
                    <div className="form-label mb-lg-4 mb-3">
                      <div className="input-group">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          className="form-control loginPlaceholder py11"
                          placeholder="Enter email address"
                        />
                      </div>

                      {formik.touched.email && formik.errors.email ? (
                        <div className="errormsg">{formik.errors.email}</div>
                      ) : null}
                    </div>
                    <label htmlFor="password">Password</label>
                    <div className="input-group">
                      <input
                        type={hidePassword ? "password" : "text"}
                        id="password"
                        name="password"
                        title="Password must contain atleast 4 characters and atleast 1 number"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        className="form-control loginPlaceholder password py11"
                        placeholder="Password"
                      />
                      <span
                        className="input-group-text login_icon"
                        onClick={togglePasswordVisibility}
                      >
                        {" "}
                        {hidePassword ? (
                          <AiOutlineEye />
                        ) : (
                          <AiOutlineEyeInvisible />
                        )}
                      </span>
                    </div>
                    {formik.touched.password && formik.errors.password ? (
                      <div className="errormsg">{formik.errors.password}</div>
                    ) : null}
                    <div className="d-flex justify-content-end my-2">
                      <a
                        href="/forget"
                        className="text-decoration-none login_forget"
                      >
                        <span className="gradient-text" >Forgot Password?</span>
                      </a>
                    </div>

                    <div>
                      <button
                        className="btn btn-disco fs-6 w-100 py12 mb-3 rounded-2"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? <Loader /> : <>Sign In</>}
                      </button>
                    </div>
                  </form>
                </div>
                <div className="text-center" style={{ color: "#ccc" }}>
                  OR
                </div>
                <div className="w-100 d-flex justify-content-center mt-3 cursorPointer">
                  <img
                    onClick={handleGoogleSignIn}
                    src={currentImage}
                    alt="Sign in with Google"
                    className="w-100"
                  />
                </div>
                <p className="text-decoration-none text-center signUpTxt pt-3 pb-2">
                  Don't have an Account?
                  <span
                    onClick={handleClickSignUp}
                    role="button"
                    className="gradient-text login_navigation ms-2"
                  >
                    SignUp
                  </span>
                </p>
                <p className="text-center hex959cbc fs-6" >© 2024 ALL RIGHTS RESERVED</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RevampSignIn;