import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./MainBody.css";
import { BsHouseDoor } from "react-icons/bs";
import { BsFillPersonFill } from "react-icons/bs";
import { FiSend } from "react-icons/fi";
import { BsGlobe } from "react-icons/bs";
import { BiSearch } from "react-icons/bi";
import Navbar_Dash from "./Navbar";
import requestInstance from "../../services/request";
import { API_ENDPOINT } from "../../services/Api";
import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ProgressBar2 from "../../components/progressBar/ProgressBar";
import { useFormik } from "formik";

const validate = (values) => {
  var errors = {};

  if (values.first_name || values.last_name) {
    errors = "";
  } else {
    errors.first_name = "Please enter firstname or lastname";
  }
  return errors;
};

// const pageSize = 10;
function MainBody() {
  const navigate = useNavigate();
  // navigate("/dashboard");

  // const handlePrice = (e) => {
  //   navigate("/dashboard/price1");
  // };

  const [tablelist, setTablelist] = useState([]);
  // const [paginationPosts, setpaginationPosts] = useState();
  // const [currentPage, setcurrentPage] = useState(1);
  const [showProgressBar, setshowProgressBar] = useState(false);
  // const [emailname, setEmailname] = useState({
  //   first_name: "",
  //   last_name: "",
  //   company: "",
  // });
  const [errMessage, setErrMessage] = useState(false);
  const [errValue, setErrValue] = useState("");
  const [message, setMessage] = useState(false);
  const [data, setData] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      company: "",
    },
    validate,
    onSubmit: (search) => {
      setErrMessage(false);
      setMessage(false);
      setshowProgressBar(true);
      emailfromname(search);
    },
  });

  // API for Table
  const tabledata = () => {
    requestInstance
      .get(API_ENDPOINT.GET_ALL_SCRAPPED_DATA_API)
      .then((res) => {
        // console.log(res.data.logged_in_user_details.email)
        // console.log(res.data["linkedin details"])
        localStorage.setItem("email", res.data.logged_in_user_details.email);
        setTablelist(res.data["linkedin details"]);
        //setpaginationPosts(_(res.data["linkedin details"]).slice(0).take(pageSize).value())
      })
      .catch((error) => {
        // if(error?.response?.status === 403){
        //    localStorage.clear()
        //   window.location.pathname='/signin'
        // }
        console.log(error);
      });

    return;
  };
  useEffect(() => {
    tabledata();
  }, []);

  const products = filteredData.map((data, idx) => {
    return {
      SNo: idx + 1,
      IMAGE: data?.image_url ? (
        <img className="img" src={data?.image_url} alt="images"></img>
      ) : (
        <img className="img" src="pic/blankperson.png" alt="images"></img>
      ),
      FIRST_NAME: data.first_name,
      LAST_NAME: data.last_name,
      EMAIL: data?.email || "NA",
      LINKEDIN_USERNAME: data.linkedin_user_name,
      Phone_No: data?.phone_number || "NA",
      COMPANY: data?.company || "NA",
      DESIGNATION: data?.designation || data?.headline?.split("|")[0] || "NA",
      JOINED_ON: moment(data.created_at).format("DD/MM/YYYY"),
      LAST_UPDATED: moment(data.updated_at).format("DD/MM/YYYY"),
      prof: data?.profile_url ? (
        <a href={data?.profile_url} target="_blank">
          User Profile
        </a>
      ) : (
        "NA"
      ),
    };
  });

  const columns = [
    { dataField: "SNo", text: "S.NO" },
    { dataField: "IMAGE", text: "IMAGE" },
    { dataField: "FIRST_NAME", text: "FIRST NAME" },
    { dataField: "LAST_NAME", text: "LAST NAME" },
    { dataField: "EMAIL", text: "EMAIL" },
    { dataField: "LINKEDIN_USERNAME", text: "LINKEDIN USERNAME" },
    { dataField: "Phone_No", text: "PHONE NO" },
    { dataField: "COMPANY", text: "COMPANY" },
    { dataField: "DESIGNATION", text: "DESIGNATION" },
    { dataField: "JOINED_ON", text: "JOINED ON" },
    { dataField: "LAST_UPDATED", text: "LAST UPDATED" },
    { dataField: "prof", text: "PROFILE URL" },
  ];

  paginationFactory({
    sizePerPage: 10,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
  });

  // API for download excelsheet
  const handleExportToFile = () => {
    const token = JSON.parse(localStorage.getItem("find_email"));
    axios({
      url: "https://api.discovemail.com/user/get/excel/data",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${token}  `,
      },
    })
      .then((response) => {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;
        toast.success("Download successfull");
        link.setAttribute("download", "Discovemail.csv"); //or any other extension    document.body.appendChild(link);
        // link.setAttribute("rel", "noreferrer");
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((err) => {
        console.log(err.response);
        // window.location.reload(true)
        if (err?.response?.status === 401) {
          navigate("/signin");
          toast.error(err?.response?.statusText);
        }
      });
  };

  //API for gettting Email through name and company
  const emailfromname = (search) => {
    // let value = {
    //   first_name: search.first_name,
    //   last_name: search.last_name,
    //   company: search.company,
    // };
    requestInstance
      .post(API_ENDPOINT.EMAIL_FROM_NAME, search)
      .then((res) => {
        setshowProgressBar(false);
        setMessage(true);
        // console.log(res.data);
        toast.success(res.data.message);
        setData(res?.data?.data || "Not Found");
      })
      .catch((err) => {
        console.log(err.response);
        setshowProgressBar(false);
        setErrMessage(true);
        setErrValue(err?.response?.statusText || "Something went wrong");
        if (err?.response?.status === 500) {
          navigate("/signin");
          toast.error(err?.response?.statusText);
        }
      });
  };

  //  const handleChange = (prop) => (event) => {
  //   setEmailname({ ...emailname, [prop]: event.target.value });
  // };

  // const handleEmailName = (e) => {
  //   e.preventDefault();
  //   setErrMessage(false);
  //   setMessage(false);
  //   setshowProgressBar(true);
  //   emailfromname(emailname);
  // };

  //   let data = '';

  // let config = {
  //   method: 'get',
  //   url: 'http://43.205.239.195:8000/user/get/user/collected/data',
  //   headers: {
  //     'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjczMTc5NzM5LCJpYXQiOjE2NzI3NDc3MzksImp0aSI6IjBiM2ZjNTY5Mjg2ZTQxZDJiYjhlZmNlM2Q5MTAyZGVkIiwidXNlcl9pZCI6NH0.uu3ODYMsxw_UNVRjeySou51UJuLiuQ1zRvcho-kWz4c'
  //   },
  //   data : data
  // };

  // axios(config)
  // .then(function (response) {
  //   console.log(JSON.stringify(response.data));
  // })
  // .catch(function (error) {
  //   console.log(error);
  // });

  // For search filter
  const handleSearch = (x) => {
    setSearchTerm(x);
  };
  useEffect(() => {
    setFilteredData(
      tablelist.filter((data) =>
        Object.values(data)
          .join("")
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
    );
  }, [tablelist, searchTerm]);

  return (
    <div>
      <Navbar_Dash />
      <div>
        <div className="header">
          <h3 className="main-head"> Your Prospect List </h3>
          <p className="home">
            <BsHouseDoor />
            <span className="gr-icon"> {">"} </span>
          </p>
          <p id="protectedprice" role="button">
            Your List
          </p>
        </div>
        {/* ---3 INPUTS--- */}
        <form onSubmit={formik.handleSubmit}>
          <div className="d-flex align-items-center bg-dark justify-content-around inpt">
            <div className="d-flex">
              <BsFillPersonFill className="icon1" />
              <input
                placeholder="First name"
                id="first_name"
                name="first_name"
                className=" form-control up_int"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.first_name}
              />
            </div>
            {formik.touched.first_name && formik.errors.first_name ? (
              <div className="errorname">{formik.errors.first_name}</div>
            ) : null}

            <div>
              <BsFillPersonFill className="icon1" />
              <input
                id="last_name"
                name="last_name"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.last_name}
                placeholder="Last name"
                className="form-control"
              />
            </div>
            <div>
              <BsGlobe className="icon1" />
              <input
                id="company"
                name="company"
                placeholder="mysite.com or company site"
                onChange={formik.handleChange}
                value={formik.values.company}
                className="form-control"
              />
            </div>
            <div>
              <button className="btn-outline-danger btn btns ms-4 mb-2">
                <FiSend />
                Discovemail
              </button>
            </div>
          </div>
        </form>
        {showProgressBar ? <ProgressBar2 bgcolor={"#6a1b9a"} /> : null}
        {errMessage && (
          <div className=" text-center">
            <div className="fw-semibold"> {errValue}</div>
          </div>
        )}
        {message && (
          <div className="fw-semibold text-start ms-5 mt-2">
            {" "}
            Email Found:
            <span className="fw-normal bg-warning">
              {data?.email_data &&
                data?.email_data?.map((dat, i) => {
                  return (
                    <p key={i}>
                      <p className="mb-1">
                        <span className="fw-normal bg-warning w-25">
                          {dat}{" "}
                        </span>
                      </p>
                    </p>
                  );
                })}
            </span>
          </div>
        )}
      </div>

      <div className="card shadow-lg p-3 mb-5 bg-body rounded mt-3">
        {/* <div className="d-flex align-items-center border-bottom border-secondary my-3 p-4">
          <h5>List Management</h5>
          <select className="select">
            <option>Show All</option>
          </select>
          <h5 className="last fs-6 ">
            24 emails found/ 31 total unique searches -- success rate 77%
          </h5>
          <hr></hr>
        </div> */}

        {/* --- 3 Button--- */}
        <div className="3btn d-flex align-items-center justify-content-between py-3 bg-secondary-subtle">
          <div>
            {/* <select className="btn btn-success mx-1">
              <option>Download CSV</option>
            </select> */}
            <button
              type="button"
              onClick={handleExportToFile}
              className="btn btn-outline-danger mx-1 new_btn"
            >
              Export to SpreadSheet
              <span className="text-warning position-absolute new">New</span>
            </button>
            {/* <button className="btn btn-success mx-1">
              Add list of properties
            </button> */}
          </div>
          <div className="d-flex align-items-baseline">
            <BiSearch className="iconsearch" />
            <input
              className="form-control me-2 search"
              type="text"
              placeholder="search"
              value={searchTerm}
              onChange={(e) => handleSearch(e.target.value)}
            />
            {/* <button className="btn btn-outline-danger">Search</button> */}
          </div>
        </div>

        <div className="table-responsive">
          <BootstrapTable
            keyField="id"
            data={products}
            columns={columns}
            pagination={paginationFactory()}
          />
        </div>
      </div>
    </div>
  );
}

export default MainBody;
