import React, { useCallback, useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import "./Profile.css";
import { CiMail } from "react-icons/ci";
import { BiArrowBack } from "react-icons/bi";
import { BsPerson } from "react-icons/bs";
import { BsPhone } from "react-icons/bs";
import { AiFillCamera } from "react-icons/ai";
import requestInstance from "../../services/request";
import { API_ENDPOINT } from "../../services/Api";
import Navbar_Dash from "../dashboard/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { userProfile } from "../../redux/slices/user/UserSlice";

const validate = (values) => {
  const errors = {};

  if (!values.first_name) {
    errors.first_name = "Please Enter your Name";
  } else if (/^(?=.*?[0-9])/i.test(values.first_name)) {
    errors.first_name = "Must not contain number";
  } else if (/(?=.*?[#?!@$%^&*-])/i.test(values.first_name)) {
    errors.first_name = "Must not contain special characters";
  }
  if (!values.phone_number) {
    errors.phone_number = "Please enter your phone No";
  } else if (values.phone_number.length < 10) {
    errors.phone_number = "Must be of 10 digits";
  } else if (/^(?=.*?[a-z])/i.test(values.phone_number)) {
    errors.phone_number = "Must not contain alphabets";
  } else if (/^(?=.*?[A-Z])/i.test(values.phone_number)) {
    errors.phone_number = "Must not contain alphabets";
  } else if (/(?=.*?[#?!@$%^&*-])/i.test(values.phone_number)) {
    errors.phone_number = "Must not contain special characters";
  }

  return errors;
};

function EditProfile() {
  const [profileuser, setprofileuser] = useState("");
  const [word, setWord] = useState("");
  const [loading, setLoading] = useState(false);
  // const [buttonDisabled, setButtonDisabled] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [imageData, setImageData] = useState("");
  const [rawImage, setRawImage] = useState();
  const dispatch = useDispatch();
  const userProfileData = useSelector((state)=>state.user.value);
  

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
    },
    validate,

    onSubmit: (values) => {
      setLoading(true);
      // setButtonDisabled(true);
      UpdateProfile(values);
    },
  });

  // To get User Details API
  const profile = useCallback(() => {
    requestInstance
      .get(API_ENDPOINT.USER_PROFILE)
      .then((res) => {
        dispatch(userProfile(res?.data?.data));
        setprofileuser(userProfileData);
        setWord(res?.data?.data?.first_name.charAt(0));
        localStorage.setItem(
          "firstword",
          res?.data?.data?.first_name.charAt(0)
        );
        formik.setValues({
          first_name: res?.data?.data.first_name,
          last_name: res?.data?.data.last_name,
          email: res?.data?.data.email,
          phone_number: res?.data?.data.phone_number,
        });

        //console.log(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [formik]);

  useEffect(() => {
    profile();
  }, []);


  // For Update Profile API
  const UpdateProfile = (values) => {
    if(rawImage){
      UpdateProfilePic();
    }
    setLoading(true);
    requestInstance
      .put(API_ENDPOINT.EDIT_PROFILE, values)
      .then((res) => {
        toast.success(res?.data?.message);
        dispatch(userProfile(res?.data?.data));
        setprofileuser(res?.data?.data);
        setWord(res?.data?.data?.first_name.charAt(0));
        setLoading(false);
        // setButtonDisabled(false);
        setIsEditMode(false);
      })
      .catch((err) => {
        toast.error("Error updating profile");
        console.log(err);
        setLoading(false);
        // setButtonDisabled(false);
      });
  };

  // For Edit Toggle
  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
    profile();
  };
  //  const letter = localStorage.getItem("firstword");

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setRawImage(file);
    const reader = new FileReader();

    reader.onload = (event) => {
      setImageData(event.target.result);
      // console.log(event.target.result);
    };

    reader.readAsDataURL(file);
  };

  const handleCancelClick = () => {
    formik.setTouched({});
    formik.setErrors({});
    setIsEditMode(false);
  };

  // For Update Profile API
  const UpdateProfilePic = () => {
    const formData = new FormData();
    formData.append("profile_picture", rawImage);
    requestInstance
      .post(API_ENDPOINT.EDIT_PROFILE_PIC, formData)
      .then((res) => {
        profile();
        toast.success(res?.data?.message);
        setLoading(false);
        setIsEditMode(false);
      })
      .catch((err) => {
        toast.error("Error updating profile");
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div>
      <Navbar_Dash />
      <div className="edit_start p-5">
        {/* <h1 className=" my-5">Edit Profile</h1> */}
        <div className="container rounded bg-white border border-2">
          <div className="row">
            <div className="col-md-4 border-right">
              <div className="d-flex flex-column align-items-center text-center p-3 py-5">
                
                <div id="circle" className="text-center mx-auto mb-4">
                  <span
                    className="profile_letter"
                    style={{ display: imageData ? "none" : "block" }}
                  >
                    {profileuser?.profile_pic ? <img src={profileuser?.profile_pic} alt="profile" className="profilePic" /> :  word}
                  </span>
                  {imageData && (
                    <img src={imageData} alt="avatar" className="imageData" />
                  )}
                  {/* <input type="file" onChange={handleImageChange} /> */}
                  {isEditMode && (
                    <label htmlFor="upload-input" className="image_icon">
                      <AiFillCamera className="fs-4 d-none camera" />
                      <input
                        type="file"
                        id="upload-input"
                        onChange={handleImageChange}
                        style={{ display: "none" }}
                      />
                    </label>
                  )}
                </div>
                <span className="fw-medium">{profileuser?.full_name}</span>
                <span className="text-black-50 fw-medium">
                  {profileuser.email}
                </span>
              </div>
            </div>

            <div className="col-md-8">
              <div className="p-3 py-5">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div className="d-flexflex-row align-items-center ">
                    <h6 className="d-none ">
                      {" "}
                      <a
                        href="/dashboard"
                        className="text-black text-decoration-none back"
                      >
                        <BiArrowBack />
                        Back to home
                      </a>
                    </h6>
                  </div>
                  {isEditMode ? (
                    <h6 className="text-right">Edit Profile</h6>
                  ) : (
                    <button
                      className="btn btn-danger"
                      onClick={toggleEditMode}
                    >
                      Edit
                    </button>
                  )}
                </div>
                <Form
                  className=" fw-semibold row mt-2"
                  onSubmit={formik.handleSubmit}
                >
                  {/* ---FirstName-- */}
                  <div className="col-md-6">
                    <div className="form-label">
                      <label>
                        Firstname <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text login_icon">
                          <BsPerson />
                        </span>
                        {isEditMode ? (
                          <input
                            type="text"
                            id="first_name"
                            name="first_name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                            className="form-control"
                            placeholder="Enter your Firstname"
                          />
                        ) : (
                          <input
                            type="text"
                            id="first_name"
                            name="first_name"
                            value={profileuser.first_name || " "}
                            className="form-control"
                            readOnly
                          />
                        )}
                      </div>
                      {formik.touched.first_name && formik.errors.first_name ? (
                        <div className="edit_error">
                          {formik.errors.first_name}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {/* ---LastName--- */}
                  <div className="col-md-6">
                    <div className="form-label">
                      <label>Lastname </label>
                      <div className="input-group">
                        <span className="input-group-text login_icon">
                          <BsPerson />
                        </span>
                        {isEditMode ? (
                          <input
                            type="text"
                            id="last_name"
                            name="last_name"
                            onChange={formik.handleChange}
                            value={formik.values.last_name}
                            className="form-control"
                            placeholder="Enter your Lastname"
                          />
                        ) : (
                          <input
                            type="text"
                            id="lastname"
                            name="lastname"
                            value={profileuser.last_name || " "}
                            className="form-control"
                            readOnly
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {/* ---Email--- */}
                  <div className="form-label col-md-6 my-3">
                    <label>
                      Email <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text login_icon">
                        <CiMail />
                      </span>
                      {isEditMode ? (
                        <input
                          type="email"
                          id="email"
                          name="email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={profileuser.email || " "}
                          className="form-control"
                          placeholder="name@example.com"
                          readOnly
                        />
                      ) : (
                        <input
                          type="text"
                          id="email"
                          name="email"
                          value={profileuser.email || " "}
                          className="form-control"
                          readOnly
                        />
                      )}
                    </div>
                  </div>

                  {/* ---Phone--- */}
                  <div className="form-label col-md-6 my-3">
                    <label>
                      Phone Number <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text login_icon">
                        <BsPhone />
                      </span>
                      {isEditMode ? (
                        <input
                          type="phone"
                          id="phone_number"
                          name="phone_number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.phone}
                          className="form-control"
                          placeholder="9999999999"
                        />
                      ) : (
                        <input
                          type="text"
                          id="PhoneNumber"
                          name="PhoneNumber"
                          value={profileuser.phone_number || " "}
                          className="form-control"
                          readOnly
                        />
                      )}
                    </div>
                    {formik.touched.phone_number &&
                    formik.errors.phone_number ? (
                      <div className="edit_error">
                        {formik.errors.phone_number}
                      </div>
                    ) : null}
                  </div>
                  <div>
                    {isEditMode && (
                      <div className="d-flex justify-content-end">
                        <button
                          className="btn profile-button text-white fw-semibold col-md-2 mb-5 mx-3 rounded-pill"
                          onClick={handleCancelClick}
                        >
                          {" "}
                          Cancel
                        </button>
                        <button
                          className="btn profile-button text-white fw-semibold col-md-2 mb-5 mx-3 rounded-pill  "
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? "Loading" : "Update"}
                        </button>
                      </div>
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditProfile;
