import { useState } from 'react';
import { MdArrowBack } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import requestInstance from '../../services/request';
import { API_ENDPOINT } from '../../services/Api';
import { toast } from 'react-toastify';

const Invite = () => {
  const navigate = useNavigate();
  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleInviteFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await requestInstance.post(API_ENDPOINT.TEAM_INVITE, {
        'first_name': e.target.firstName.value,
        'last_name': e.target.lastName.value,
        'phone_number': e.target.phoneNumber.value?.replaceAll(' ', ''),
        'email': e.target.email.value,
        'role': role,
        'country_code': '+91',
      });

      toast.success(response.data.message);
      navigate('/settings/team');
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className='px-4 py-3'>
      <h3 className='mb-4'>
        <MdArrowBack className='cursorPointer me-2' onClick={() => navigate('/settings/team')} />
        Invite new Member</h3>
      <form onSubmit={handleInviteFormSubmit}>
        <div className="form-group">
          <label htmlFor="firstName">First name</label>
          <input type="text" className="form-control w-25 mt-1" id="firstName" name='firstName' />
        </div>
        <div className="form-group my-3">
          <label htmlFor="lastName">Last name</label>
          <input type="text" className="form-control w-25 mt-1" id="lastName" name='lastName' />
        </div>
        <div className="form-group my-3">
          <label htmlFor="phoneNumber" className='d-block'>Phone number</label>
          <div className="d-flex">
            <input type="text" className='form-control mt-1 me-3' value={'+91'} disabled style={{ width: '55px' }} />
            <input type="text" className='w-25 form-control mt-1' name='phoneNumber' id="phoneNumber" />
          </div>
        </div>
        <div className="form-group my-3">
          <label htmlFor="email" className='d-block'>Email address <span className='text-danger'>*</span></label>
          <input type='email' id='email' name='email' className='w-50 form-control mt-1' required />
        </div>
        <div className='my-4 form-group'>
          <label className='mb-2' htmlFor="role">Role <span className='text-danger'>*</span></label>
          <div>
            <input type="radio" name="role" id="member" value="Member" onChange={(e) => setRole(e.target.value)} required />
            <label htmlFor="member" className='ms-2'>Member</label>
          </div>
          <div>
            <input type="radio" name="role" id="admin" value="Admin" onChange={(e) => setRole(e.target.value)} required />
            <label htmlFor="admin" className='ms-2'>Admin</label>
          </div>
        </div>
        <button disabled={loading} type='submit' className={`btn btn-danger ${loading ? 'px-5' : 'px-4 py-2'}`}>{loading ? <div className="inline-loader"></div> : 'Send Invite'}</button>
      </form>
    </div>
  )
}

export default Invite