import React, { useRef, useState } from 'react';
import './BulkNew.css';
import { useNavigate } from "react-router-dom";
import Navbar_Dash from '../dashboard/Navbar';
import axios from "axios";
import { toast } from 'react-toastify';
import { RiFileExcel2Line } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { bulkFinderTableData } from '../../redux/slices/user/UserSlice';
import requestInstance from '../../services/request';
import { API_ENDPOINT } from '../../services/Api';


const BulkNewFinder = (props) => {
  const [listName, setListName] = useState();
  const [selectedFile, setSelectedFile] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);



  const handleFileChange = () => {
    const newFile = fileInputRef?.current?.files[0];
    if (newFile) {
      setSelectedFile(newFile);
    } else {
      setSelectedFile('No file selected');
    }
  };

  function handleExportToFile() {
    
    const data = [
      { firstName: "Firstname", lastName: "Lastname", domain: "domain" },
      { firstName: "John", lastName: "Doe", domain: "example.com" },
      { firstName: "Jane", lastName: "Smith", domain: "test.com" }
    ];

    // Convert data to CSV format
    const csvContent = "data:text/csv;charset=utf-8," +
      data.map(row => Object.values(row).join(",")).join("\n");

    // Create a link element to trigger the download
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "bulk-finder-format.csv");
    document.body.appendChild(link); // Required for Firefox
    link.click();
  }


  const handleSubmit = () => {
    setLoading(true)
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("data_enter_type", 'csv_import');
    formData.append("list_name", listName);

    requestInstance
      .post(API_ENDPOINT.BULK_EMAIL_FINDER_CONTENT, formData)
      .then((res) => {
        dispatch(bulkFinderTableData(res?.data?.data));
        toast.success(res.data.message);
        setLoading(false);
        navigate(`/bulk-tasks-finder/bulk-finder-config/${res?.data?.bulk_data_info?.id}`);
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }


  return (
    <>
      <Navbar_Dash />

      <div className="bulkTaskNew_main mt_6rem">
        <div className="bulkTaskNew_card bg-white">
          <div className="p-4">
            <div className="mt-3">
              <div className="d-flex gap-3 align-items-baseline">
                <div onClick={() => { navigate('/bulk-tasks-finder/bulk-email-finder') }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-left-circle cursorPointer" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                  </svg>
                </div>
                <div>
                  <h6 className='fw-bold'>
                    {props?.pageRoute.replaceAll('-', ' ').toUpperCase()}
                  </h6>
                </div>
              </div>
            </div>

            <div className='mt-5'>
              <div>
                <div>
                  <h5 className='fw-bold my-2'>List name</h5>
                </div>
                <div class="input-group mb-3">
                  <input type="text" class="form-control" placeholder="List name" aria-label="Username" aria-describedby="basic-addon1" onChange={(e) => setListName(e.target.value)} />
                </div>
              </div>

              <div className='hrLine'></div>

              <div className='two mt-3'>
                <div>
                  <h5 className='fw-bold my-4'>Upload a file (CSV)</h5>
                  
                </div>
                <div className='four d-flex my-4 align-items-center'>
                  <div className='px-5'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16">
                      <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                    </svg>
                  </div>
                  <div className='text-secondary pt-3'>
                    <p onClick={handleExportToFile}>Checkout our format -  <span className='text-success cursorPointer'><RiFileExcel2Line /></span></p>
                    <p className='mb-0'>Your file must use commas or semicolons as column delimiters.</p>
                    <p className='mb-0'>To be valid, your rows must contain at first name, last name and domain.</p>
                    <p>Some special or unexpected characters may be deleted in the file.</p>
                  </div>
                </div>
              </div>

              <div className='three d-flex flex-column'>
                <div className='uploadBox d-flex justify-content-center w-100 py-4' onClick={() => {
                  if (fileInputRef.current.value) {
                    setSelectedFile('')
                    fileInputRef.current.value = null
                  }
                  fileInputRef.current.click()
                }
                }
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-folder2-open" viewBox="0 0 16 16">
                    <path d="M1 3.5A1.5 1.5 0 0 1 2.5 2h2.764c.958 0 1.76.56 2.311 1.184C7.985 3.648 8.48 4 9 4h4.5A1.5 1.5 0 0 1 15 5.5v.64c.57.265.94.876.856 1.546l-.64 5.124A2.5 2.5 0 0 1 12.733 15H3.266a2.5 2.5 0 0 1-2.481-2.19l-.64-5.124A1.5 1.5 0 0 1 1 6.14V3.5zM2 6h12v-.5a.5.5 0 0 0-.5-.5H9c-.964 0-1.71-.629-2.174-1.154C6.374 3.334 5.82 3 5.264 3H2.5a.5.5 0 0 0-.5.5V6zm-.367 1a.5.5 0 0 0-.496.562l.64 5.124A1.5 1.5 0 0 0 3.266 14h9.468a1.5 1.5 0 0 0 1.489-1.314l.64-5.124A.5.5 0 0 0 14.367 7H1.633z" />
                  </svg>
                  <p className='px-2 m-0'>Select a file </p>
                  <input type="file" accept='.csv' ref={fileInputRef} onChange={handleFileChange} style={{ display: 'none' }} />
                </div>
                {selectedFile !== '' ?
                  <div className='mt-2 bg-success-subtle fw-bold d-flex justify-content-between p-3'>
                    <div>
                      <p>{selectedFile?.name}</p>
                    </div>
                    <div className='cursorPointer' onClick={() => setSelectedFile('')}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                      </svg>
                    </div>
                  </div> : null}
              </div>

              {/* ---Button--- */}
              <div className="my-4">
                <button
                  className=" btn btn-disco col-12"
                  disabled={loading || listName === undefined || listName?.length === 0}
                  onClick={handleSubmit}
                  type="submit"
                >
                  {loading ? <div class="spinner-border text-light" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div> : 'Upload'}
                </button>
              </div>
            </div>


          </div>
        </div>
      </div>
    </>
  )
}

export default BulkNewFinder