import React from "react";

export default function FaqArea({
  description,
  name,
  location,
  phoneNumber,
  url,
  staffCount,
  industries,
  company_type,
}) {
  return (
    <div className="col-md-12 mt-3 mb-4">
      <h3 className="text-secondary mb-5">
        Frequently Asked Questions regarding {name}
      </h3>

      <div className="mt-3 bg-white accordion frqAskedQusAccordion" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              Where is {name} located?
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="text-white accordion-body">
              <strong>{name} headquarters</strong> are located at{" "}
              {location?.line1} {location?.line2} {location?.city}{" "}
              {location?.geographicArea} {location?.postalCode}{" "}
              {location.country}
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              What is {name} phone number?
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="text-white accordion-body">
              {phoneNumber ? (
                <>
                  {" "}
                  {name} phone number is <strong>{phoneNumber}</strong>
                </>
              ) : (
                "Not found"
              )}
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              What is {name} official website?
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div className="text-white accordion-body">
              {url ? (
                <>
                  {name} official website is <strong>{url}</strong>
                </>
              ) : (
                "Not found"
              )}
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFour">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              How many employees does {name} have?
            </button>
          </h2>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionExample"
          >
            <div className="text-white accordion-body">
              {staffCount ? (
                <span>
                  {" "}
                  <strong>
                    {name} has {staffCount}
                  </strong>{" "}
                  employees
                </span>
              ) : (
                "Not found"
              )}
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFive">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              What industry does {name} belong to?
            </button>
          </h2>
          <div
            id="collapseFive"
            className="accordion-collapse collapse"
            aria-labelledby="headingFive"
            data-bs-parent="#accordionExample"
          >
            <div className="text-white accordion-body">
              {name} is in the industry of:{" "}
              <strong>
                {industries.map((industry, index) => (
                  <span key={index}>{industry}</span>
                ))}
              </strong>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="headingSix">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSix"
              aria-expanded="false"
              aria-controls="collapseSix"
            >
              Is {name} a public company?
            </button>
          </h2>
          <div
            id="collapseSix"
            className="accordion-collapse collapse"
            aria-labelledby="headingSix"
            data-bs-parent="#accordionExample"
          >
            <div className="text-white accordion-body">
              <strong>
                {name} is {company_type}
              </strong>
              .
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header" id="headingSeven">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSeven"
              aria-expanded="false"
              aria-controls="collapseSeven"
            >
              What does {name} do?
            </button>
          </h2>
          <div
            id="collapseSeven"
            className="accordion-collapse collapse"
            aria-labelledby="headingSeven"
            data-bs-parent="#accordionExample"
          >
            <div className="text-white accordion-body">{description}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
