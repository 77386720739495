import React from 'react';
import { Link } from 'react-router-dom';
import './DashboardNeo.css';
// import GmailIcon from './newUiassets/gmailIcon.png';
import { Card, Row, Col } from 'react-bootstrap';

const FeatureCard = (props) => {
  return (
    <div className="">
      <Row>
        {props?.featuresData?.map((ele, index) => (
          <Col 
            key={index} 
            xs={12} sm={12} md={4} lg={4} 
            className="mb-4"
          >
            <Card className="shadow-sm rounded-2">
              <Card.Body className="d-flex">
                <div className="w-80">
                  <Card.Title>{ele.heading}</Card.Title>
                  <div style={{ height: '5rem' }}>
                    <Card.Text>{ele.caption}</Card.Text>
                  </div>
                  <button className="border-0 bg-white">
                    <Link className="pathLink fs-6" to={ele.path}>Start now</Link>
                  </button>
                </div>
                <div className="w-20 text-right">
                  <img src={ele?.icon} alt="Gmail Icon" />
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default FeatureCard;
