import React, { useEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  ArcElement,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
} from "chart.js";
import { Doughnut, Line } from "react-chartjs-2";
import { optionsDoughnutCab, options } from "./ChartComponentData";
import CommonNoDataFound from "components/CommonNoDataFound/CommonNoDataFound";
import "./Chart.css";
import { lineChartData,doutnutData } from "./DummyData";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement
);

const ChartComponent = (props) => {
  const { dashboardLineChartData, dashboardBarChartData } = props;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (dashboardLineChartData && dashboardBarChartData) {
      setIsLoading(false);
    }
  }, [dashboardLineChartData, dashboardBarChartData]);

  const dashboardBarChart = dashboardBarChartData?.doutnutdata
  const lineChartData = dashboardLineChartData?.['monthly-leads-data']

  return (
    <div className="container-fluid">
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <div className="row gx-3">
          <div className="col-12 col-sm-6 col-md-4" style={{ marginTop: '10px' }}>
            <div className="card p-3 h-100">
              {dashboardBarChart?.labels?.length > 0 ? (
                <Doughnut
                  id="doughnutChartCar"
                  data={dashboardBarChart}
                  options={optionsDoughnutCab}
                  // ref={dashboardRef}
                  plugins={[
                    {
                      beforeDraw(chart) {
                        const { ctx } = chart;
                        const { top, bottom, left, right } = chart.chartArea;
                        ctx.save();
                        const fontSize = 28;
                        ctx.font = `bolder ${fontSize}px sans-serif`;
                        ctx.textAlign = "center";
                        ctx.textBaseline = "end";
                        ctx.fillStyle = "#282828";
                        const text = dashboardBarChart?.datasets[0]?.data?.reduce(
                          (partialSum, a) => partialSum + a, 0
                        );
                        const textX = Math.round((left + right) / 2);
                        const textY = Math.round((top + bottom) / 2);
                        ctx.fillText(text, textX, textY - 15);

                        ctx.font = `10px sans-serif`;
                        const text2 = "Count";
                        ctx.fillStyle = "#969696";
                        ctx.fillText(text2, textX, textY + 15);
                      },
                    },
                  ]}
                />
              ) : (
                <CommonNoDataFound />
              )}
            </div>
          </div>
          <div className="col-12 col-md-8" style={{ marginTop: '10px' }}>
            <div className="card p-3">
              {lineChartData?.labels?.length > 0 ? (
                <Line
                  id="barChart"
                  options={options}
                  data={lineChartData}
                  // ref={dashboardRef}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <CommonNoDataFound />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChartComponent;
