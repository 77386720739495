import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CardTwoSection from "components/commonCardUi/CardTwoSection";
import Navbarlatest from "../../newDashboardUI/Navbarlatest";
import ChartComponent from "components/commonChart/ChartComponent";
import TableDashboard from "./TableDashboard";
import {
  fetchDashboardCardData,
  fetchDashboardLineChartData,
  fetchDashboardBarChartData,
} from "../../../redux/slices/dashboardCardAndChart/DashboardCardSlice";
import ExportSheet from "./ExportSheet";

function DashbaordNewUi() {
  const dispatch = useDispatch();
  const {
    dashboardCardData,
    dashboardLineChartData,
    dashboardBarChartData,
    loading,
    error,
  } = useSelector((state) => state?.dashboard);

  useEffect(() => {
    dispatch(fetchDashboardCardData());
    dispatch(fetchDashboardLineChartData());
    dispatch(fetchDashboardBarChartData());
  }, []);

  return (
    <div>
      {/* <Navbarlatest /> */}
      <CardTwoSection dashboardCardData={dashboardCardData} />
      <div className="row w-100">
        <div className=" col-md-9 col-lg-9 col-sm-12 order-2 order-md-1">
          <ChartComponent
            dashboardLineChartData={dashboardLineChartData}
            dashboardBarChartData={dashboardBarChartData}
          />
        </div>
        <div className=" col-md-2 col-lg-3 col-sm-12 order-1 order-md-2 bg-secondary-subtle">
          <div className="padding23px">
          <ExportSheet />
          </div>
        </div>
      </div>
      <TableDashboard />
    </div>
  );
}

export default DashbaordNewUi;
