import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { API_ENDPOINT } from "services/Api";
import requestInstance from "services/request";
import useDebounce from "hooks/useDebounce";
import Navbarlatest from "pages/newDashboardUI/Navbarlatest";
import WelcomePage from "./WelcomePage";
import Loader from "pages/loader/Loader";
import DashbaordNewUi from "pages/dashboard/newUiDashboard/DashbaordNewUi";
import { planStatus, userProfile, totalUserRows } from "../../../redux/slices/user/UserSlice";
import "./DashboardNeo.css";

const DashboardNeo = () => {
  const mail = localStorage.getItem("email");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const search = location.search;
  const searchParams = new URLSearchParams(search);
  const sessionId = searchParams.get("session_id");
  const userTotalRows = useSelector((state) => state?.campaign?.totalUserRows);
  const [tablelist, setTablelist] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [companyContactInfo, setCompanyContactInfo] = useState([]);
  const [companyInfo, setCompanyInfo] = useState([]);
  const [isCompanyInfoLoading, setIsCompanyInfoLoading] = useState(true);
  const [isCompanyError, setIsCompanyError] = useState(false);
  
  const [compPhoneNum, setcompPhoneNum] = useState("");
  const [currIndex, setcurrIndex] = useState();
  
  const [forPhone, setForPhone] = useState({ company: null, id: null });
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [emails, setEmails] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const debouncedSearchValue = useDebounce(searchTerm, 900);

  useEffect(() => {
    let ignore = false;

    const getTableData = async () => {
      try {
        setPending(true);
        setTableLoader(true);
        const response = await requestInstance.get(
          `${API_ENDPOINT.GET_ALL_SCRAPPED_DATA_API}?limit=${perPage}&offset=${
            (currentPage - 1) * perPage
          }&search=${debouncedSearchValue}`
        );
        if (!ignore) {
          setTablelist(response.data.results["linkedin details"]);
          setTotalRows(response.data.count);
          dispatch(totalUserRows(response.data.count))
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
        setTableLoader(false);
        setPending(false);
      }
    };

    getTableData();

    return () => {
      ignore = true;
    };
  }, [debouncedSearchValue, currentPage, perPage]);

  const handleStripePayment = () => {
    setLoading(true);
    const body = {
      session_id: sessionId,
    };
    requestInstance
      .post(API_ENDPOINT.STRIPE_PAYMENT_HANDLE, body)
      .then((res) => {
        toast.success(res.data.message);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (sessionId?.length > 0) {
      handleStripePayment();
    }
  }, [sessionId]);

  const fetchCompanyContact = (company) => {
    setcompPhoneNum("");

    setIsCompanyError(false);
    setCompanyContactInfo([]);
    setCompanyInfo([]);
    requestInstance
      .post(API_ENDPOINT.COMPANY_INFO, { company })
      .then((res) => {
        if (res.data.status === "success") {
          setCompanyInfo(res.data.data);
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        setIsCompanyInfoLoading(false);
      })
      .catch(() => {
        setIsCompanyInfoLoading(false);
      });
  };

  useEffect(() => {
    if (forPhone.id) {
      requestInstance
        .post(`${API_ENDPOINT.COMPANY_PHONE_INFO}${forPhone.id}`, {
          company: forPhone.company,
        })
        .then((res) => {
          if (res.data.status === "success") {
            setPhoneNumbers(res.data.data.company_numbers);
            setEmails(res.data.data.company_emails);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch(() => {
          console.log("err");
        });
    }
  }, [forPhone]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const profileResponse = await requestInstance.get(
          API_ENDPOINT.USER_PROFILE
        );
        dispatch(userProfile(profileResponse?.data?.data));
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    const planApi = () => {
      requestInstance
        .get(API_ENDPOINT.ACTIVE_PLAN_STATUS)
        .then((res) => {
          dispatch(planStatus(res?.data?.data));
        })
        .catch((err) => {
          console.log(err);
        });
    };

    fetchProfile();
    planApi();
  }, []);

  useEffect(() => {
    setFilteredData(
      tablelist.filter((data) =>
        Object.values(data)
          .join("")
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
    );
  }, [tablelist]);

  return (
    <div className="">
      <Helmet>
        <title>
          Discovemail-dashboard | The Best Online E-mail Checker To Verify
          E-mail Addresses For Free.
        </title>
        <meta
          name="description"
          content="Discovemail Is The Free Online E-mail Checker To Verify Your E-mail Addresses Ensuring Accurate And Valid Contact And Email Information For Successful Communication."
        />
      </Helmet>

      <Navbarlatest />
      <div className="row w-100">
        <div className=" col-md-12 col-lg-12 col-12 order-2 order-md-1">
          {tableLoader ? (
            <div className="h-100 d-flex align-items-center justify-content-center">
              <Loader />
            </div>
          ) : tablelist.length === 0 ? (
            <WelcomePage /> // Show WelcomePage if no data
          ) : (
            <DashbaordNewUi /> // Show DashbaordNewUi if data exists
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardNeo;
