import React, { useState } from "react";
import NavbarDash from "../pages/dashboard/Navbar";
import { priceCardData } from "../resource/data/priceCardData";
import requestInstance from "../services/request";
import { API_ENDPOINT } from "../services/Api";
import { loadStripe } from '@stripe/stripe-js';
import { toast } from "react-toastify";
import { Navigate, redirect, useNavigate } from "react-router-dom";
import Navbar from "./Navbar_1";
import EmailCards from "../home_revamp/EmailCards";
import Faq from "../home_revamp/Faq";
import NewFooter from "../home_revamp/NewFooter";
import NewPricePlan from "../home_revamp/NewPricePlan";
import Elevate from "../home_revamp/Elevate";

function NewPrice() {
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem('find_email');
  const navigate = useNavigate();

  const handlePayment = async (val) => {
    if (token?.length > 0) {
      setLoading(true);
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

      const body = {
        "plan_type": val,
      }

      requestInstance
        .post(API_ENDPOINT.STRIPE_PAYMENT_CHECKOUT, body)
        .then((res) => {
          const session = res?.data?.data;
          const result = stripe.redirectToCheckout({ sessionId: session.sessionId })
          if (result.error) {
            console.log(result.error);
          }
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err);
          setLoading(false);
        });
    } else {
      navigate("/signin");
    }
  }


  var settings = {

    infinite: true,
    slidesToShow: 4,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  const [hover, setHover] = useState({
    id: priceCardData[0].id,
  });

  const MouseOver = (e, ele) => {
    setHover({
      id: ele.id,
    });
  };

  const MouseOut = (e, ele) => {
    setHover({
      id: ele.id,
    });
  };

  const handleRedirect = (type) => {
    window.open(window.location.origin + `/select-plan?plan=${type}`, "_blank");
  };

  const user = JSON.parse(localStorage.getItem("find_email"));
  return (
    <div className="">
      {token?.length > 0 ? <NavbarDash /> : <Navbar/>}
      <div className="pricingHero heroTopPadding pb-xxl-5 ">
        <h1 className="text-white py-xxl-5 text-center fs-1 ">Choose the perfect plan to fit your needs and budget.</h1>
      </div>
      <EmailCards />
      <NewPricePlan />
      <Elevate />
      <Faq />
      <NewFooter />
    </div>
  );
}

export default NewPrice;
