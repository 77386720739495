import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import requestInstance from '../../../services/request';
import { API_ENDPOINT } from '../../../services/Api';

const weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];


export default function CampaignSettings() {
  const campaignSummaryId = useSelector(
    (state) => state.campaign.campaignSummaryId
  );
  const [selectedDays, setSelectedDays] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [isLoading, setIsLoading] = useState();


  const getScheduledData = () => {
    setIsLoading(true)
    requestInstance
      .get(API_ENDPOINT.CAMPAIGN_SCHEDULED_FOLLOW_UP + `?campaign_id=${campaignSummaryId}`)
      .then((res) => {
        setSelectedDays(res?.data?.data?.crontab?.day_of_week)
        setStartTime(`${res?.data?.data?.crontab?.hour}:${String(res?.data?.data?.crontab?.minute).padStart(2, '0')}`)
        toast.success(res.data.message);
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getScheduledData();
  }, [])

  return (
    <div className='container'>

      <div className='px-3'>
        <h6 className='fw-bold text-dark fs-5 pt-3'>Settings</h6>
        {/* <small>Manage your settings for this campaign. You can overwrite your default settings if needed.</small> */}
      </div>

      <div className='px-3'>
        <h6 className='fw-semibold text-dark pt-3'>Sending window for this campaign</h6>
        <div className='d-flex gap-3'>
          {weekDays.map((day) => (
            <label key={day} className={`${selectedDays.includes(day) ? "border-primary" : ""} border border-2 p-2 text-dark fw-bold`}>
              <input
                type="checkbox"
                value={day}
                checked={selectedDays.includes(day)}
                disabled
                className='mx-2'
              />
              {day}
            </label>
          ))}
        </div>

        <div className='mt-4'>
          <h6 className='fw-semibold text-dark '>Sending time</h6>
          <div className='d-flex align-items-baseline'>
            <div class="col-2">
              <input type="time" id="startTime" className='form-control' value={startTime} disabled />
            </div>
            <div className='fw-bold mx-3'>(IST - Asia / Kolkata)</div>
          </div>
        </div>

      </div>

    </div>
  )
}

