import React, { useEffect, useState } from "react";
import "./overview.css";
import { useNavigate } from "react-router-dom";

import requestInstance from "../../services/request";
import { API_ENDPOINT } from "../../services/Api";
import { useParams } from "react-router-dom";
import Spinner from "../loader/SimpleLoader";

import OverViewCard from "./utilities/OverViewCard";
import { ImLocation2 } from "react-icons/im";
import { FiPhoneCall } from "react-icons/fi";
import { BsGlobe, BsLinkedin } from "react-icons/bs";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { FaIndustry } from "react-icons/fa";

import FaqArea from "./FaqArea";
import DummyCompany from "./assets/dummy_company.jpg";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import Navbar from "../../new_home/Navbar_1";
import Footer from "../../new_home/Footer";
import Navbar_Dash from "../dashboard/Navbar";

export default function Template() {
  const { company } = useParams();

  const [infoLoading, setInfoLoading] = useState(true);
  const [companyData, setCompanyData] = useState([]);
  const [isTrunkated, setIsTrunkated] = useState(false);

  const navigate = useNavigate();

  const token = localStorage.getItem('find_email');

  const getCompanyData = () => {
    setInfoLoading(true);

    requestInstance
      .post(API_ENDPOINT.COMPANY_INFO, { company })
      .then((res) => {
        setCompanyData([res.data.data]);
        setInfoLoading(false);
      })
      .catch((err) => {
        setInfoLoading(false);
        navigate(-1);
      });
  };

  useEffect(() => {
    getCompanyData();
  }, []);

  const RenderData = () => {
    return companyData.map((company, index) => {
      const CompanyName =
        company?.universal_name?.charAt(0).toUpperCase() +
        company?.universal_name?.slice(1).toLowerCase().replace(/-/g, " ");

      const currencyData = company?.funding_data?.moneyRaised;

      const CurrencyFormatter = () => {
        // Check if currencyData object is provided and not empty
        if (!currencyData || Object.keys(currencyData).length === 0) {
          return <span>Money raised data not available</span>;
        }

        // Check if amount and currencyCode properties are provided in the currencyData object
        if (!currencyData.amount || !currencyData.currencyCode) {
          return <span>Not found</span>;
        }

        const { amount, currencyCode } = currencyData;
        const formattedAmount = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: currencyCode,
        }).format(amount);

        return <span>{formattedAmount}</span>;
      };

      const description = !isTrunkated
        ? company?.description.slice(0, 505)
        : company?.description;

      return (
        <>
          <section className="companyDetailSection pt-4 mt-lg-5 mt-4">
            <div className="container-fluid aboutCompanySection pb-5 d-flex align-items-center">
              <div className="row justify-content-center align-items-center">
                <div className="col-11 col-md-10">
                  <div className="row pt-5 mt-3">
                    <div className="companyLogo col-lg-2 col-12 mb-3 mb-lg-0">
                      <a href={company?.company_page_url ? company?.company_page_url : ''} target={company?.company_page_url ? '_blank' : ''} className="text-decoration-none">
                        <img
                          src={
                            company?.company_logo === "None"
                              ? DummyCompany
                              : company?.company_logo
                          }
                          alt="Gloify API Search Company info"
                          width="160"
                          className="companyLogoImg rounded-3 text-center img-fluid"
                        />
                      </a>
                    </div>
                    <div className="aboutCompany col-12 col-lg-10">
                      <h3 className="title-overview">{CompanyName}</h3>
                      <h6 className="secondary-text">
                        {company.industries[0]} · {company?.headquarter.city + ", "}
                        {company?.headquarter.country}.{" "}
                        {company?.staff_count
                          ? company?.staff_count.toLocaleString()
                          : null}{" "}
                        Employees
                      </h6>
                      {company?.description ? (
                        <p className="text-secondary cutText">
                          {company?.description ? (
                            <>
                              {description}
                              {company?.description.length > 500 ? (
                                <span
                                  className="btn-trunkate"
                                  onClick={() => setIsTrunkated(!isTrunkated)}
                                >
                                  {!isTrunkated ? "...readmore" : "readless"}
                                </span>
                              ) : null}
                            </>
                          ) : (
                            "Not found"
                          )}
                        </p>
                      ) : (
                        "Not Found "
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row mb-5 companyDetailSection">
                <div className="col-lg-8 col-12 mt-md-3 mt-lg-0 mb-5 mb-lg-0">
                  <div className="rounded-bg p-0 overflow-hidden">
                    <h2 className="pt-3 ps-3">Who is {CompanyName}?</h2>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item d-flex xs-flex-column align-items-baseline">
                        <span className="icon-heading margin-right-1rm">
                          <ImLocation2 /> Headquarters
                        </span>
                        <span className="max-text-show">
                          {company?.headquarter ? (
                            <>
                              {company?.headquarter.line1
                                ? company?.headquarter.line1 + ", "
                                : null}
                              {company?.headquarter.line2
                                ? company?.headquarter.line2 + ", "
                                : null}
                              {company?.headquarter.city + ", "}
                              {company?.headquarter.country + ", "}
                              {company?.headquarter.postalCode + " "}
                            </>
                          ) : (
                            "Not Found"
                          )}
                        </span>
                      </li>

                      <li className="list-group-item d-flex xs-flex-column align-items-baseline">
                        <span className="icon-heading margin-right-1rm">
                          <FiPhoneCall /> Phone Number
                        </span>
                        <span className="icon-content">
                          {company?.phone_number
                            ? company.phone_number
                            : "Not found"}
                        </span>
                      </li>

                      <li className="list-group-item d-flex xs-flex-column align-items-baseline">
                        <span className="icon-heading margin-right-1rm">
                          <BsGlobe /> Website
                        </span>
                        <span className="icon-content">
                          {company?.company_page_url ? (
                            <a
                              href={company.company_page_url}
                              target="_blank"
                              className="text-decoration-none"
                            >
                              {company.company_page_url}
                            </a>
                          ) : (
                            "Not found"
                          )}
                        </span>
                      </li>

                      <li className="list-group-item d-flex xs-flex-column align-items-baseline">
                        <span className="icon-heading margin-right-1rm">
                          <RiMoneyDollarCircleLine /> Funding Data
                        </span>
                        <span className="icon-content">
                          <CurrencyFormatter />
                        </span>
                      </li>

                      <li className="list-group-item align-items-baseline">
                        <div className="icon-heading margin-right-1rm">
                          <FaIndustry /> Industry{" "}
                          <span
                            className="icon-content"
                            style={{ marginLeft: "25px" }}
                          >
                            {company?.industries
                              ? company.industries.map((industry, index) => {
                                return (
                                  <span
                                    className="badge rounded-pill text-bg-primary"
                                    key={index}
                                  >
                                    {industry}
                                  </span>
                                );
                              })
                              : "Not found"}
                          </span>
                        </div>
                      </li>

                      <li className="list-group-item align-items-baseline">
                        <span className="icon-heading margin-right-1rm">
                          {CompanyName} Social Media :
                        </span>
                        <a
                          href={
                            company?.linkedin_url ? company.linkedin_url : "#"
                          }
                          target="_blank"
                          className="icon-img"
                        >
                          <BsLinkedin />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <div className="rounded-bg">
                    <h3>Specialities</h3>
                    <div className="list-group specialities__height">
                      {company?.specialities.length > 0
                        ? company.specialities.map((specality, index) => (
                          <li className="list-group-item" key={index}>
                            {specality}
                          </li>
                        ))
                        : "Not found"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                  <FaqArea
                    name={CompanyName}
                    location={company?.headquarter ? company.headquarter : {}}
                    phoneNumber={company?.phone_number}
                    url={company?.company_page_url}
                    staffCount={company?.staff_count}
                    industries={company?.industries}
                    company_type={company?.company_type}
                    description={company?.description}
                  />
              </div>
            </div>
          </section>
        </>
      );
    });
  };

  return (
    <>
      {infoLoading ? (
        <Spinner />
      ) : (
        <>
          {/* <button
            onClick={() => navigate(-1)}
            className="btn btn-primary gobackBtn"
          >
            Go Back
          </button> */}
          <Helmet>
            <title>Get any Company Details | Discovemail</title>

            <meta
              name="description"
              content="Discovemail is an extension through which we can get details of linkedin users through profile section
          and can contact for further reference. 
          Find verified email addresses for your target audience and improve your outreach efforts today."
            />

            <meta
              name="keywords"
              content=" LinkedIn Email Finder,verified email addresses,discovemail,email,linkedin,emailfinder,get email,search email,location finder,linkedin details,
          linkedin user details,linkedin id "
            />
          </Helmet>

          {token?.length > 0 ?  <Navbar_Dash/> : <Navbar />}
            <RenderData />
            {token?.length > 0 ? null : <Footer/>}
        </>
      )}
    </>
  );
}

