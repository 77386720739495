import React from "react";
import email from "assets/IconEmail.svg";
import people from 'assets/IconPeople.svg';
import speaker from 'assets/IconSpeak.svg';
import web from 'assets/IconWeb.svg';

function CardTwoSection(props) {
  const { dashboardCardData } = props;

  const cardData = [
    { icon: email, title: `${dashboardCardData?.verified_email_count || 0}+`, text: "Total email verified" },
    { icon: web, title: `${dashboardCardData?.user_leads_count || 0}+`, text: "Total Leads" },
    { icon: people, title: `${dashboardCardData?.total_campaigns_count || 0}+`, text: "Total campaigns" },
    { icon: speaker, title: `${dashboardCardData?.running_campaigns || 0}+`, text: "On going campaigns" }
  ];

  return (
    <div className="paddingTop8rem">
      <div className="container-fluid">
        <div className="row justify-content-center">
          {cardData.map((card, index) => (
            <div className="col-md-3 col-sm-6 mb-3" key={index}>
              <div className="d-flex align-items-center border rounded shadow-sm p-3">
                <img src={card.icon} alt={`Icon ${index + 1}`} />
                <div className="text-start ms-5">
                  <h5 className="card-title">{card.title}</h5>
                  <p className="card-text font13px">{card.text}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CardTwoSection;
