import React, { useEffect, useState } from "react";
import requestInstance from "../../services/request";
import { API_ENDPOINT } from "../../services/Api";
import './ConnectWithGoogle.css';
import { Google } from "react-bootstrap-icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import CampaignImage from "../../assets/EmailMarketingCampaign_coverimage.png";
import GoogleBtn from '../../assets/btn_google_signin_dark_normal_web.png';
import GoogleBtnPressed from '../../assets/btn_google_signin_dark_pressed_web.png';
import Spinner from "../loader/SimpleLoader";
import { useDispatch } from "react-redux";
import { googleAuth, googleSign } from "../../redux/slices/googleAuth/GoogleAuthSlice";

export default function ConnectWithGoogle() {
  const [authUrl, setAuthUrl] = useState("");
  const [currentImage, setCurrentImage] = useState(GoogleBtn);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [isLodging, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  console.log(searchParams.get("code"));

  const handleGoogleLogin = () => {
    setCurrentImage(GoogleBtnPressed);
    requestInstance.get(API_ENDPOINT.CAMPAIGN).then((res) => {
      // localStorage.setItem('authStatus', true);
      setAuthUrl(res.data.data)
    })
    .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (searchParams.get("code") !== null) {
      requestInstance
        .post(API_ENDPOINT.CALLBACK_CAMPAIGN_API, {
          code: searchParams.get("code"),
        })
        .then((res) => {
            if(res.status === 200){
              navigate("/campaign/all-campaigns");
            }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  useEffect(() => {
    verifyUser();
    if (authUrl !== "") {

      window.location.href = authUrl;
    } else {
      setAuthUrl("");
    }
  }, [authUrl]);


  const verifyUser = () => {
    requestInstance
      .get(API_ENDPOINT.CAMPAIGN_SIGNATURE_API)
      .then((res) => {
        if(res.data.status !== 'fail'){
          dispatch(googleAuth(true));
          dispatch(googleSign(res?.data?.data));
          navigate('/campaign/all-campaigns');
          setIsLoading(false);
        }else{
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      })
  }

  return (
    <>
      {isLodging ? <Spinner/> :
      <div className="container">
        <div className="row justify-content-center align-items-center campaign">
          <div className="col-md-10 col-lg-10">
            <div className="campaign__with__google">
              <h3 className="campain__subtitle">Campaigns</h3>
              <h1>
                More replies, less <span className="red-flag">hassle</span>.
              </h1>
              <h2>Cold email for everyone.</h2>
            </div>

            <div onClick={handleGoogleLogin} className="cursorPointer">
              <img src={currentImage} alt="Sign in with Google" className="google_signin_btn" />
            </div>
          </div>
        </div>

        <div className="row campaign-image">
          <img src={CampaignImage} alt="Discovemail Email Campaign cover" className="campaign-img" />
        </div>
      </div>
      }
    </>
  );
}
