import React, { useEffect } from 'react';
import DataTable from './TableDashboard';
import { useDispatch, useSelector } from "react-redux";
import Navbarlatest from 'pages/newDashboardUI/Navbarlatest';
import { fetchDashboardTableData } from '../../../redux/slices/dashboardTable/DashboardTableSlice';

function NewTableData() {
    const dispatch = useDispatch();
    const { dashboardTableData } = useSelector((state) => state.dashboardTable);

    useEffect(() => {
    dispatch(fetchDashboardTableData());
    },[])
  return (
    <div>
      <Navbarlatest />
      <div className="mt-5 pt-2">
        <DataTable name={'newTableData'}/>
      </div>
    </div>
  )
}

export default NewTableData
