export const priceCardData = [
  {
    id: 1,
    title: "Free",
    price: "0",
    btn:"Get Started",
    credit: "100 Credits for LinkedIn contact emails",
    namecredit: "30 credits for each of the Email Finder, Email Verifier, and Domain Verifier feature. ",
    api: "API ACCESS",
    csv: "CSV Download",
    support: "NA",
  },
  {
    id: 2,
    title: "Basic",
    price: "12",
    btn:"Get Started",
    credit: "300 Credits for LinkedIn contact emails",
    namecredit: "90 credits for each of the Email Finder, Email Verifier, and Domain Verifier feature. ",
    api: "API ACCESS",
    csv: "CSV Download",
    support: "Email",
  },
  {
    id: 3,
    title: "Standard",
    price: "25",
    btn:"Get Started",
    credit: "1000 Credits for LinkedIn contact emails",
    namecredit: "270 credits for each of the Email Finder, Email Verifier, and Domain Verifier feature. ",
    api: "API ACCESS",
    csv: "CSV Download",
    support: "Email + Chat + Phone",
  },
  {
    id: 4,
    title: "Premium",
    price: "50",
    btn:"Get Started",
    credit: "3000 Credits for LinkedIn contact emails",
    namecredit: "810 credits for each of the Email Finder, Email Verifier, and Domain Verifier feature.",
    api: "API ACCESS",
    csv: "CSV Download",
    support: "Email + Chat + Phone",
  },
];
