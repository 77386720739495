import React, { useEffect, useState } from 'react';
import './BulkSummary.css';
import Navbar_Dash from '../dashboard/Navbar';
import Spinner from "../../pages/loader/SimpleLoader";
import { useNavigate, useParams } from 'react-router-dom';
import requestInstance from '../../services/request';
import { API_ENDPOINT } from '../../services/Api';
import editIcon from '../../assets/edit.png';
import paginationFactory from "react-bootstrap-table2-paginator";
import { toast } from 'react-toastify';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import axios from "axios";
import DataTable from 'react-data-table-component';

ChartJS.register(ArcElement, Tooltip, Legend);

const BulkSummary = () => {
    const [loading, setLoading] = useState(true);
    const [pending, setPending] = useState(false);
    const [allData, setAllData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastIndex, setLastIndex] = useState(0);
    const [nextData, setNextData] = useState();
    const [prevData, setPrevData] = useState();
  const [emailFilter, setEmailFilter] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editValue, setEditValue] = useState("");


    const navigate = useNavigate();
    const params = useParams();

    const dataPie = {
      datasets: [
        {
          data: [allData?.verified_emails_percentage?.toFixed(), (allData?.invalid_emails_count/allData?.total_count*100)?.toFixed()],
          backgroundColor: [
            "rgba(75, 192, 192, 0.2)",
            "rgba(255, 99, 132, 0.2)",
          ],
          borderColor: [
            "rgba(75, 192, 192, 1)",
            "rgba(255, 99, 132, 1)",
          ],
          borderWidth: 1,
        },
      ],
    };

    const columns = [
      {
        name: 'Email',
        selector: row => row?.email,
        sortable: true,
      },
      {
          name: 'Status',
          selector: row => row?.email_status,
          sortable: true,
          conditionalCellStyles: [
            {
              when: row => row.email_status === 'valid',
              style: {
                color: 'green',
              },
            },
            {
              when: row => row.email_status === 'invalid',
              style: {
                color: 'red',
              },
            },
            {
              when: row => row.email_status === 'unknown',
              style: {
                color: 'yellow',
              },
            },
          ],
      },
      {
          name: 'Score',
          selector: row => row?.valid_score,
          sortable: true,
          style:{
            color:'gray'
          }
      },
  ];

  const handleTool = ()=> {
    const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));

  tooltipTriggerList.forEach((tooltipTriggerEl) => {
    const tooltip = new window.bootstrap.Tooltip(tooltipTriggerEl);

    // Manually show the tooltip when the element is clicked.
    tooltipTriggerEl.addEventListener('click', () => {
      tooltip.show();
    });

    // Add an event listener to destroy the tooltip when the page is unloaded or reloaded.
    window.addEventListener("beforeunload", () => {
      tooltip.dispose();
    });
  });
  }


  const fetchUsers = async (page) => {
    setLoading(true);
    try {
      const apiPath = `${API_ENDPOINT.BULK_EMAIL_VERIFIER_SUMMARY_LAST}/${params?.id}?&limit=${perPage}&offset=${(page - 1) * perPage}`;
      const response = await requestInstance.get(apiPath);
      setAllData(response.data.results);
      setEditValue(response.data.results?.data[0]?.bulk_email_list_name?.bulk_email_list_name)
      setTotalRows(response.data.count);
      setCurrentPage(page);
      setLastIndex((page - 1) * perPage);
      setNextData(response.data.next || null);
      setPrevData(response.data.previous || null);
      setLoading(false);
      setTimeout(() => {
        handleTool();
      }, 1000);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const handlePageChange = async (page) => {
    try {
      setPending(true);
      const apiPath = `${API_ENDPOINT.BULK_EMAIL_VERIFIER_SUMMARY_LAST}/${params?.id}?&limit=${perPage}&offset=${(page - 1) * perPage}`;
      const response = await requestInstance.get(apiPath);
      setAllData(response.data.results);
      setCurrentPage(page); 
      setLastIndex((page - 1) * perPage);
      setTotalRows(response.data.count);
      setLoading(false);
      setPending(false);
    } catch (error) {
      setPending(false);
      console.error("Error fetching data:", error);
    }
  };
  
  
  const handlePerRowsChange = async (newPerPage) => {
    
      setLoading(true);
    const offset = (currentPage - 1) * perPage;
        const apiPath = `${API_ENDPOINT.BULK_EMAIL_VERIFIER_SUMMARY_LAST}/${params?.id}?&limit=${newPerPage}&offset=${offset}`;
        const response = await requestInstance.get(apiPath);
        setAllData(response.data.results);
        setPerPage(newPerPage);
        setLastIndex((currentPage - 1) * newPerPage);
        setTotalRows(response.data.count);
        setLoading(false);
  };

    const getLastSummaryDataRefresh = async(page) =>{
      const apiPath = `${API_ENDPOINT.BULK_EMAIL_VERIFIER_SUMMARY_LAST}/${params?.id}?&limit=${perPage}&offset=${(page - 1) * perPage}`;
      const response = await requestInstance.get(apiPath);
      setAllData(response.data.results);
      toast.success(response.data.result)
  }
  paginationFactory({
    sizePerPage: 10,
    hideSizePerPage: true,
    hidePageListOnlyOnePage: true,
  });

  const handleFilterChange = (event) => {
    setEmailFilter(event.target.value === 'validOnly');    
  };

  const handleDownload = () => {
    requestInstance
      .get(API_ENDPOINT.BULK_EMAIL_VERIFIER_INDIVIDUAL_DOWNLOAD + `/${params?.id}?is_valid=${emailFilter}`)
        .then((response) => {
          console.log(response); // Log the response for debugging
          const contentType = response.headers['content-type']; // Get the content type from headers
    
          if (contentType === 'text/csv' || contentType === 'application/csv') {
            const href = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = href;
    
            toast.success("Download successful");
            link.setAttribute("download", "Discovemail.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
          } else {
            // Handle the case where the server responded with unexpected content type
            toast.error("Unexpected content type: " + contentType);
          }
        })
        .catch((err) => {
          if (err?.response?.status == 401) {
            navigate("/signin");
            toast.error(err?.response?.statusText);
          } else {
            // Handle other errors
            console.error(err);
          }
        });
    }

    const colorPallet = {
      "In-Progress":"text-warning",
      "Completed":"text-success",
    }

    useEffect(() => {
      fetchUsers(1);
    }, [params])


    useEffect(() => {
      const checkStatusAndGetData = () => {
        if (allData?.user_bulk_email_list_obj?.bulk_email_status === 'Completed') {
          clearInterval(intervalId);
        } else {
          getLastSummaryDataRefresh(1);
        }
      };
  
      const intervalId = setInterval(checkStatusAndGetData, 3000); 
      return () => clearInterval(intervalId);
    }, [allData]);
  
  const handleEmailVerifierRename = () => {
    const newBody = {
      'new_name': editValue,
    }
    requestInstance
      .put(API_ENDPOINT.BULK_EMAIL_VERIFIER_RENAME + `/${params?.id}`, newBody)
      .then((res) => {
        fetchUsers(1);
        setEdit(false);
        navigate('/bulk-tasks/bulk-email-verifier');
        toast.success(res.data.message);
      })
      .catch((error) => {
        console.error('Error sending data:', error);
      });
  };

  const handleEditChange = (event) => {
    setEditValue(event.target.value);
  };
  


  return (
    <>
    <Navbar_Dash />


    {loading ? <Spinner /> : 
    <div className="bulkSummary_main py-5 mt_6rem">
      <div className="bulkSummary_card bg-white">
        <div className="p-4"> 
          <div className="mt-3">
            <div className='d-flex justify-content-between container align-items-baseline'>
                <div className="d-flex gap-3 align-items-baseline">
                    <div onClick={()=>{navigate('/bulk-tasks/bulk-email-verifier')}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-arrow-left-circle cursorPointer" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
                    </svg>
                    </div>
                    <div>
                      <h6 className='fw-bold'>
                        {edit ? (
                          <div>
                            <input
                              className='py-1 fs-6 ms-2'
                              type="text"
                              value={editValue}
                              onChange={handleEditChange}
                              placeholder="Enter something..."
                            />
                            <button className=' ms-2 btn btn-danger btn-sm' onClick={handleEmailVerifierRename}>Update</button>
                            <button className='ms-2 btn btn-secondary btn-sm' onClick={() => { setEdit(false); setEditValue(allData?.data[0]?.bulk_email_list_name?.bulk_email_list_name) }}>Cancel</button>
                          </div>) :
                          <div>
                            <span className='fs-6 fw-bold ms-2'>{editValue} <img src={editIcon} alt='img not found' width='20px' className='img-fluid cursorPointer' onClick={() => setEdit(true)} /></span>
                          </div>
                        }
                          
                      </h6>
                      <small className='text-secondary'>
                        {allData?.verified_emails_count} email addresses verify
                      </small>
                    </div>
                </div>
            </div>
            <div className='hrLine my-4'></div>
            {/* DOWNLOAD SECTION */}
            <div className='d-flex justify-content-between align-items-baseline'>
              <div>
                <h6 className='fw-bold'>Download in CSV</h6>
              </div>
              <div>
                    <button className='btn btn-danger' data-bs-toggle="modal" data-bs-target="#exampleModal">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download mx-2" viewBox="0 0 16 16">
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                    </svg>Download
                  </button>
              </div>
            </div>
            <div className='hrLine my-4'></div>
            {/* CHART SECTION */}
            <div className='d-flex justify-content-around align-items-center'>
              <div>
                <div>
                  <Pie data={dataPie} /> 
                </div>
                <div className='mt-5'>
                  <h6 className='fw-bold'>Includes sources: <span className='fw-normal'>No</span></h6>
                  <small className='text-secondary'>This used {allData?.total_count} Verification credit. </small>
                </div>

              </div>
              <div>
                <h6 className='fw-bold'>Verification statuses - <span className={colorPallet[allData?.user_bulk_email_list_obj?.bulk_email_status]}>{allData?.user_bulk_email_list_obj?.bulk_email_status}</span> 
                  {/* <span data-bs-toggle="tooltip" data-bs-title="Refresh the page until the status changes to 'Complete' in order to download the CSV." data-bs-trigger="manual"> */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#e75a5a" className="bi bi-arrow-clockwise cursorPointer mx-1 fw-bold" onClick={fetchUsers} viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                    <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                    </svg>
                  {/* </span> */}
                </h6>
                <ul>
                  <li className='text-success'><span className='text-dark fw-semibold'>Valid({allData?.verified_emails_percentage?.toFixed()}% <span>&#183;</span> {allData?.verified_emails_count} out of {allData?.total_count} email address</span>)</li>
                </ul>
              </div>
            </div>
            <div className='hrLine my-4'></div>
            {/* Bulk List  */}
            <div>
              <h6 className='fw-bold'>Preview</h6>
              <div>
              <div className='col-12 minHeight84vh'>
                  <div className='row'>
                    <div className='col-12 bulkSummaryTable overflow-auto p-4'>
                      <DataTable
                        columns={columns}
                        data={allData?.data}
                        progressPending={pending}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        paginationPerPage={perPage}
                        paginationDefaultPage={currentPage}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>}
      
      {/* -------Download modal------- */}
      
      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">Download the results in CSV</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className='my-4'>
                <h5>Valid {allData?.verified_emails_count} out of {allData?.total_count} email address</h5>
                <div className='form-check form-check-inline'>
                  <input type='radio' className='form-check-input' id='allListEmailsRadio' name='emailFilter' value='alllist' checked={!emailFilter} onChange={handleFilterChange} />
                  <label className='form-check-label' htmlFor='allListEmailsRadio'>All list</label>
                </div>
                <div className='form-check form-check-inline'>
                  <input type='radio' className='form-check-input' id='validEmailsRadio' name='emailFilter' value='validOnly' checked={emailFilter} onChange={handleFilterChange} />
                  <label className='form-check-label' htmlFor='validEmailsRadio'>Valid only</label>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={handleDownload} disabled={allData?.user_bulk_email_list_obj?.bulk_email_status !== 'Completed'}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download mx-2" viewBox="0 0 16 16">
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                </svg>Download</button>
            </div>
          </div>
        </div>
      </div>
  </>
  )
}

export default BulkSummary;