import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import requestInstance from '../../services/request';
import { API_ENDPOINT } from '../../services/Api';
import { toast } from 'react-toastify';

const EditLeadModal = ({ data, fetchUsers }) => {
  const [leadId, setLeadId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [buttonState, setButtonState] = useState(true);

  const formik = useFormik({
    initialValues: {
      email: '',
      first_name: '',
      last_name: '',
      phone_number: '',
      company_name: '',
      designation: '',
      location: '',
      profile_url: '',
      company_website: '',
      company_numbers: '',
      company_emails: ''
    },
    onSubmit: (values) => {
      // setButtonState(false);
    },
    validate: (values) => {
      const errors = {};
      // validation logic
      return errors;
    }
  });

  useEffect(() => {
    if (data) {
      formik.setValues(data);
      setLeadId(data?.id)
    }
  }, [data]);

  const importPostData = () => {
    let postData = {
      "first_name": formik.values.first_name,
      "last_name": formik.values.last_name,
      "email": formik.values.email.toLowerCase(),
      "phone": formik.values.phone_number,
      "company_name": formik.values.company_name,
      "designation": formik.values.designation,
      "location": formik.values.location,
      "profile_url": formik.values.profile_url,
      "company_website": formik.values.company_website,
      "company_numbers": formik.values.company_numbers,
      "company_emails": formik.values.company_emails
    }
    console.log(postData, "edit update data")
    console.log(leadId, "lead id")
    requestInstance
      .put(API_ENDPOINT.LEAD_UPDATE + `/${leadId}`, postData)
      .then((res) => {
        formik.resetForm();
        fetchUsers();
        toast.success(res.data.message);
        setButtonState(true);
      })
      .catch((err) => {        
        setButtonState(true);        
        toast.error(err);        
      });
  };

  const handleNextSubmit = () => {
    importPostData(); 
  };

  return (
    <div className="modal fade" id="editLeadModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">Edit lead</h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <form onSubmit={formik.handleSubmit}>
              <div className='container'>
                <div className='row'>

                  <div class="col-md-6 mb-3">
                    <label for="exampleInputEmail1" class="form-label">Email address <sup className='text-danger'>*</sup></label>
                    <input type="email" id="exampleInputEmail1" aria-describedby="emailHelp" required name="email"
                      placeholder='Enter your email'
                      className='form-control'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik?.values?.email} />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="errormsg">{formik.errors.email}</div>
                    ) : null}
                  </div>


                  <div class="col-md-6 mb-3">
                    <label for="first_name" class="form-label">First name</label>
                    <input type="text" class="form-control" id="first_name"
                      placeholder='Enter first name'
                      name="first_name"
                      className='form-control'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.first_name} />
                    {formik.touched.first_name && formik.errors.first_name ? (
                      <div className="errormsg">{formik.errors.first_name}</div>
                    ) : null}
                  </div>

                  <div class="col-md-6 mb-3">
                    <label for="last_name" class="form-label">Last name</label>
                    <input type="text" class="form-control" id="last_name" placeholder='Enter last name'
                      name="last_name"
                      className='form-control'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.last_name} />
                    {formik.touched.last_name && formik.errors.last_name ? (
                      <div className="errormsg">{formik.errors.last_name}</div>
                    ) : null}
                  </div>

                  <div class="col-md-6 mb-3">
                    <label for="Phone" class="form-label">Phone number</label>
                    <input type="tel" class="form-control" id="Phone" placeholder='Enter phone number' name="phone_number"
                      className='form-control'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phone_number} />
                    {formik.touched.phone_number && formik.errors.phone_number ? (
                      <div className="errormsg">{formik.errors.phone_number}</div>
                    ) : null}
                  </div>

                  <div class="col-md-6 mb-3">
                    <label for="company_name" class="form-label">Company name</label>
                    <input type="text" class="form-control" id="company_name" placeholder='Enter company name'
                      name='company_name'
                      className='form-control'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.company_name} />
                    {formik.touched.company_name && formik.errors.company_name ? (
                      <div className="errormsg">{formik.errors.company_name}</div>
                    ) : null}
                  </div>

                  <div class="col-md-6 mb-3">
                    <label for="designation" class="form-label">Designation</label>
                    <input type="text" class="form-control" id="designation" placeholder='Enter designation'
                      name='designation'
                      className='form-control'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.designation} />
                    {formik.touched.designation && formik.errors.designation ? (
                      <div className="errormsg">{formik.errors.designation}</div>
                    ) : null}
                  </div>

                  <div class="col-md-6 mb-3">
                    <label for="location" class="form-label">Location</label>
                    <input type="text" class="form-control" id="location" placeholder='Enter location'
                      name='location'
                      className='form-control'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.location} />
                    {formik.touched.location && formik.errors.location ? (
                      <div className="errormsg">{formik.errors.location}</div>
                    ) : null}
                  </div>

                  <div class="col-md-6 mb-3">
                    <label for="profile_url" class="form-label">LinkedIn url</label>
                    <input type="url" class="form-control" id="profile_url" placeholder='Enter LinkedIn url' name='profile_url'
                      className='form-control'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.profile_url} />
                    {formik.touched.profile_url && formik.errors.profile_url ? (
                      <div className="errormsg">{formik.errors.profile_url}</div>
                    ) : null}
                  </div>

                  <div class="col-md-6 mb-3">
                    <label for="company_website" class="form-label">Company website</label>
                    <input type="url" class="form-control" id="company_website" placeholder='Enter company details' name='company_website'
                      className='form-control'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.company_website} />
                    {formik.touched.company_website && formik.errors.company_website ? (
                      <div className="errormsg">{formik.errors.company_website}</div>
                    ) : null}
                  </div>

                  <div class="col-md-6 mb-3">
                    <label for="company_numbers" class="form-label">Company numbers</label>
                    <input type="tel" class="form-control" id="company_numbers" placeholder='Enter company number'
                      name='company_numbers'
                      className='form-control'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.company_numbers} />
                    {formik.touched.company_numbers && formik.errors.company_numbers ? (
                      <div className="errormsg">{formik.errors.company_numbers}</div>
                    ) : null}
                  </div>

                  <div class="col-md-6 mb-3">
                    <label for="company_emails" class="form-label">Company emails</label>
                    <input type="email" class="form-control" id="company_emails" placeholder='Enter company email' name='company_emails'
                      className='form-control'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.company_emails} />
                    {formik.touched.company_emails && formik.errors.company_emails ? (
                      <div className="errormsg">{formik.errors.company_emails}</div>
                    ) : null}
                  </div>
                  <div className='d-flex justify-content-end gap-3'>
                    <div>
                      <button type="submit" className="btn btn-danger" data-bs-dismiss="modal" onClick={handleNextSubmit} disabled={buttonState === false || !formik.values.email.length > 0}>Update</button>
                    </div>
                    <div>
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditLeadModal;
