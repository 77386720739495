import React from 'react';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import Lottie from 'react-lottie';  
import NoData from './noDataFound.json'

function CommonNoDataFound() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: NoData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <div>
      <Player
        autoplay
        loop
        src={NoData}
        style={{ height: '300px', width: '120px' }}
      >
        <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
      </Player>
      <div>
    </div>
    </div>
  )
}

export default CommonNoDataFound