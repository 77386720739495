import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  leadNameData:'',
  leadTableData:null,
  leadSummaryData: null,
  leadListData:null
}

export const leadSlice = createSlice({
  name: 'lead',
  initialState,
  reducers: {
    leadNameData: (state, action) => {
        state.leadNameData = action.payload;
    },
    leadTableData: (state, action) => {
        state.leadTableData = action.payload;
    },
    leadSummaryData: (state, action) => {
        state.leadSummaryData = action.payload;
      },
      leadListData: (state, action) => {
      state.leadListData = action.payload;
    },
  },
})

export const { 
    leadNameData,
    leadTableData,
    leadSummaryData,
    leadListData,

  } = leadSlice.actions

export default leadSlice.reducer