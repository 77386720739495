import React from 'react';
import './LeadSummary.css'
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const LeadSummary = () => {
  const navigate = useNavigate();
  const tableSummaryList = useSelector((state)=>state?.lead?.leadSummaryData);
  const leadName = useSelector((state)=>state.lead.leadNameData);


  return (
    <div className='container'>
      <div className='row mt-4'>
          <div className='col-9 d-flex'>
              <div>
                  <span className='fs-5 fw-semibold'>{tableSummaryList?.extra_data?.total_data_rows} leads imported</span>
              </div>
            </div>

            <div className='col-9 d-flex justify-content-between mt-5'>
              <div>
                <h6 className='fw-bold'>Import complete with errors</h6>
                <p className='text-secondary'>A significant number of leads couldn't be imported because of errors.</p>
              </div>
              <div>
                <button className='btn btn-danger' onClick={()=>navigate('/leads/all-leads')}>Open the destination list</button>
              </div>
            </div>

            <div className='col-9 mt-5 summaryList border-0 p-4'>
                <div className='d-flex justify-content-between'>
                    <div>
                      <p className='fw-bold'>Rows</p>
                    </div>
                    <div>
                      <p className='text-secondary'>{tableSummaryList?.extra_data?.total_data_rows}</p>
                    </div>
                </div>
                <hr />
                <div className='d-flex justify-content-between'>
                    <div>
                      <p className='fw-bold'>New leads</p>
                    </div>
                    <div>
                      <p className='text-secondary'>{tableSummaryList?.extra_data?.new_leads_created}</p>
                    </div>
                </div>
                <hr />
                <div className='d-flex justify-content-between'>
                    <div>
                      <p className='fw-bold'>Updated leads</p>
                    </div>
                    <div>
                      <p className='text-secondary'>{tableSummaryList?.extra_data?.existing_leads_updated}</p>
                    </div>
                </div>
                <hr />
                <div className='d-flex justify-content-between'>
                    <div>
                      <p className='fw-bold'>Not Imported Leads</p>
                    </div>
                    <div>
                      <p className='text-secondary'>{tableSummaryList?.extra_data?.total_not_imported_data}</p>
                    </div>
                </div>
                <hr />
                <div className='d-flex justify-content-between'>
                    <div>
                      <p className='fw-bold'>Destination list</p>
                    </div>
                    <div>
                      <p className='text-secondary'><span className='text-secondary'>{leadName}</span></p>
                    </div>
                </div>
                <hr />
                <div className='d-flex justify-content-between'>
                    <div>
                      <p className='fw-bold'>Remarks</p>
                    </div>
                    <div>
                      <p className='fw-bold'>Row 1:</p>
                      <p className='text-secondary'>A valid email address is required.</p>
                      <p className='fw-bold'>Row 2:</p>
                      <p className='text-secondary'>The email address is required.</p>
                    </div>
                </div>
            </div>

            <div className='col-9 my-5'>
                <p className='text-secondary'>Leads import created at {Date(tableSummaryList?.data[0]?.leads_name?.created_at)}.</p>
            </div>
        </div>
    </div>
  )
}

export default LeadSummary;