import React from 'react'

const TeamCards = (props) => {
  return (
    <>
        <div className="row d-flex justify-content-evenly">
        {
            props?.teamData?.map((elem, index)=>(
                <div className="col-lg-4 text-center" key={index}>
                    <img src={elem.imgSrc} alt="image not found" className='img-fluid' />
                    <div className='px-md-2'>
                    <h4 className='mt-3' >{elem.name}</h4>
                    <p className='text-secondary fs-5 fw-medium'>{elem.designation}</p>
                    <p className='fs-6'>{elem.text1}</p>
                    </div>
                    
                </div>
            ))
        }
        </div>
    </>
  )
}

export default TeamCards