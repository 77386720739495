
import React, { useEffect, useState, useRef } from "react";
import requestInstance from "../../../services/request";
import { API_ENDPOINT } from "../../../services/Api";
import "../review/CampaignReview.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineStop } from "react-icons/ai";
import {
  campaignName,
  campaignSummaryId,
} from "../../../redux/slices/campaign/CampaignSlice";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';


const CampaignDetailEmail = () => {
    const [summaryContent, setSummaryContent] = useState();
    const [followUpContent, setFollowUpContent] = useState(null);
    const [showFollowupList, setshowFollowupList] = useState(false);
    const [followupListData, setfollowupListData] = useState(null);
    const [followupMailId, setfollwupMailId] = useState("");
    const [selectedIndex, setselectedIndex] = useState("");
    const { id } = useParams();
    const dispatch = useDispatch();
    const campaignSummaryId = useSelector(
      (state) => state.campaign.campaignSummaryId
    );
    const followUpRef = useRef(null);


    const campaignSummaryContent = async () => {
        await requestInstance
          .get(API_ENDPOINT.CAMPAIGN_SUMMARY_CONTENT + `/${id}`)
          .then((res) => {
            setSummaryContent(res?.data?.data?.email_audience_content_data);
            dispatch(
              campaignName(
                res?.data?.data?.campaign_email_content_data?.campaign_name
              )
            );
          })
          .catch((err) => {
            console.log(err);
          });
      };
    
      const colorPallet = {
        Error: "text-danger",
        Scheduled: "text-warning",
        "Email sent": "text-success",
        Opened: "text-info",
        "In-Progress": "text-dark",
      };
    
      const handleFollowupClick = () => {
        setshowFollowupList((prev) => !prev);
      };
      useEffect(() => {
        campaignSummaryContent();
      }, []);
    
      useEffect(() => {
        requestInstance
          .get(API_ENDPOINT.FOLLOWUP_LISTING + `${campaignSummaryId}`)
          .then((res) => {
            setfollowupListData(res.data.data);
          });
      }, [showFollowupList]);
    
      useEffect(() => {
        if (followupMailId) {
          requestInstance
            .get(
              API_ENDPOINT.FOLLOWUP_MAIL_DISLAY + `?followup_id=${followupMailId}`
            )
            .then((res) => {
              setFollowUpContent(res.data.data);
            });
        }
      }, [followupMailId]);
    
      // console.log(followUpContent);

      const handleFollowUpstop =(stopId)=>{
        const stopFollowUpId = {followup_email_id: stopId}
        requestInstance
        .put(`${API_ENDPOINT.FOLLOWUP_STOP}${campaignSummaryId}`, JSON.stringify(stopFollowUpId))
        .then((res)=>{
          console.log(res);
          toast.success("Follow-Up Stopped Successfully")
        })
        .catch((error) => {
          console.error('Error sending data:', error);
        });
      }
    
      const handleFollowupSelect = (e) => {
        setfollwupMailId(e.target.value);
      };
    

  return (
    <div className="CampaignReview col-12 pb-3 px-3" >

    {/* .........FOLLOW UP lIST DROPDOWN HERE......  */}
    <div className="mt-4">
        <button className="followupListBtn" onClick={handleFollowupClick}>
          Follow up List
        </button>
        {showFollowupList ? (
          followupListData.length >= 1 ? (
            <div>
              <select
                className="followupSelect"
                id=""
                onChange={(e) => {
                  handleFollowupSelect(e);
                  setselectedIndex(e.target.selectedIndex);
                  followUpRef.current.scrollIntoView();
                }}
              >
                <option value="">Please select an option</option>
                {followupListData.map((elem, index) => (
                  <option value={elem.id}>Follow Up - {index + 1}</option>
                ))}
              </select>
            </div>
          ) : (
            <p>
              <i>No follow-up records to display</i>
            </p>
          )
        ) : (
          <p></p>
        )}
      </div>

      <div className="gap-4 mb-5 justify-content-between row">
        {summaryContent?.map((val) => (
          <div class="card col-3 cursorPointer">
            <div class="card-body d-flex flex-column justify-content-between">
              <div>
                <h6 class="card-title fw-bold">{val?.user_lead?.email}</h6>
                <p class="card-subtitle mb-2 text-body-secondary">
                  {val?.email_subject}
                </p>
                <small
                  class="card-text"
                  dangerouslySetInnerHTML={{ __html: val?.email_msg_body }}
                ></small>
              </div>
              <div className={colorPallet[val?.sending_status]}>
                <small>{val?.sending_status}</small>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* .........HERE IS THE FOLLOW UP MAIL LIST DISPLAY.......... */}
      <div id="xyz">
        {followUpContent && selectedIndex > 0 ? (
          <div className="followUpmailsHeader" id="followUpmailsHeader">
            <h2>Campaign Follow-Up Summary</h2>
            <h5>Follow Up- {selectedIndex} Details</h5>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className="gap-4 justify-content-between row" ref={followUpRef}>
        {followUpContent &&
          selectedIndex > 0 &&
          followUpContent?.map((val) => (
            <div class="card col-3 cursorPointer">
              <div class="card-body d-flex flex-column justify-content-between">
                <div>
                <div className="senderAndRemove">
                  <h6 class="card-title fw-bold">
                    {val?.user_lead_details?.email}
                  </h6>
                  <button className="followUpStopBtn" data-bs-toggle="tooltip" data-bs-placement="top" title="Stop FollowUps" onClick={()=>handleFollowUpstop(val?.id)}>
                    <AiOutlineStop />
                  </button> 
                </div>
                  <p class="card-subtitle mb-2 text-body-secondary">
                    {val?.followup_email_subject}
                  </p>
                  <small
                    class="card-text"
                    dangerouslySetInnerHTML={{
                      __html: val?.followup_email_msg_body,
                    }}
                  ></small>
                </div>
                <div className={colorPallet[val?.followup_content_status]}>
                  <small>{val?.followup_content_status}</small>
                </div>
              </div>
            </div>
          ))}
      </div>



    </div>
  )
}

export default CampaignDetailEmail