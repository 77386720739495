import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Campaign.css';

const CampaignSideBar = () => {
    const [latestPath, setLatestPath] = useState('all-campaigns');
    const location = useLocation();

    useEffect(() => {
        setLatestPath(location?.pathname?.split('/')[2]);
    }, [location?.pathname])
    


  return (
        <div className="vl">
            <div className='listItems'>
                <div className="accordion text-start" id="accordionPanelsStayOpenExample">
                  <div className={latestPath !== 'setting' && latestPath !== 'archive' && latestPath !== 'reporting' && latestPath !== 'all-template' && latestPath !== 'create-template' && latestPath !== 'template' && latestPath !== 'global-templates' ? 'accordion-body padding23px cursorPointer activeLink' : 'accordion-body'}>
                        <Link to={`/campaign/all-campaigns`}>
                            <div className='text-secondary fw-bold px-3 py-2'>Campaigns</div>
                        </Link>
                    </div>
                  <div className={latestPath === 'setting' ? 'accordion-body padding23px cursorPointer activeLink' : 'accordion-body'}>
                        <Link to={`/campaign/setting`} >
                            <div className='text-secondary fw-bold px-3 py-2'>Settings</div>
                        </Link>
                  </div>
                  <div className={latestPath === 'archive' ? 'accordion-body padding23px cursorPointer activeLink' : 'accordion-body'}>
                      <Link to={`/campaign/archive`} >
                          <div className='text-secondary fw-bold px-3 py-2'>Archive</div>
                      </Link>
                  </div>
                  <div className={latestPath === 'all-template' || latestPath === 'template' || latestPath === 'create-template' || latestPath === 'global-templates' ? 'accordion-body padding23px cursorPointer activeLink' : 'accordion-body'}>
                      <Link to={`/campaign/all-template`}>
                          <div className='text-secondary fw-bold px-3 py-2'>Templates</div>
                      </Link>
                  </div>
                  <div className={latestPath === 'reporting' ? 'accordion-body padding23px cursorPointer activeLink' : 'accordion-body'}>
                      <Link to={`/campaign/reporting`} >
                          <div className='text-secondary fw-bold px-3 py-2'>Reporting</div>
                      </Link>
                  </div>
                </div>
            </div>
            </div>
  )
}

export default CampaignSideBar