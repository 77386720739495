import React, { useState } from "react";
import { useFormik } from "formik";
import { Typewriter, Cursor } from "react-simple-typewriter";
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import requestInstance from "../../services/request";
import { API_ENDPOINT } from "../../services/Api";
import { setCurrentUser } from "../../utils/utils";
import Navbar from "new_home/Navbar_1";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../loader/Loader";
import { useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import signInImg from "../../assets/revampHomeassets/signInIng.svg";
import "./Password.css";

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "Please enter email";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.password) {
    errors.password = "Please Enter a new Password";
  } else if (!/^(?=.*?[a-z])/i.test(values.password)) {
    errors.password = "Must contain lowercase";
  } else if (!/^(?=.*?[0-9])/i.test(values.password)) {
    errors.password = "Must contain number";
  } else if (!/.{4,}/i.test(values.password)) {
    errors.password = "Must have 4 characters";
  } else if (!values.confirm) {
    errors.confirm = "Please Enter Confirm Password";
  } else if (values.password !== values.confirm) {
    errors.confirm = "Password Does not match";
  }

  return errors;
};

function Password() {
  const user = localStorage.getItem("ID");

  const navigate = useNavigate();
  const [hidePassword, setHidePassword] = useState(true);
  const [values, setValues] = useState("");
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  // Function for Hide and Show Password
  function togglePasswordVisibility() {
    setHidePassword(!hidePassword);
  }

  const formik = useFormik({
    initialValues: {
      email: user ? user : "",
      password: "",
      confirm: "",
    },
    validate,
    onSubmit: (values) => {
      setLoading(true);
      setButtonDisabled(true);
      Change(values);
    },
  });

  const Change = (values) => {
    let data = JSON.stringify({
      email: values.email,
      password: values.password,
    });
    requestInstance
      .post(API_ENDPOINT.PASSWORD_RESET_API, data)
      .then((res) => {
        if (res.status == 200) {
          setCurrentUser(res.data.access);
          toast.success(res.data.message);
          setLoading(false);
          setButtonDisabled(false);
          navigate("/signin");
        }
      })
      .catch((err) => {
        setLoading(false);
        setButtonDisabled(false);
      });
  };

  const tabHead = "Discovemail-reset"
  return (
    <div className="">
      <Helmet>
        <title>{tabHead}</title>
      </Helmet>


      <div className="container-fluid loginPageBg mx-0 px-0">
      <Navbar />
          <div className="row pt-4 height100vh px-0 mx-0">
          <div className="col-lg-6 d-flex flex-column gap-1 pt-5 px-md-5">
            <h1 className="px-lg-5 mt-md-5 fw600 fs50 lh-1 hexbabfc2">
              Your all-in-one lead generation platform{" "}
            </h1>
            <h1 className="px-lg-5 fw600 fs50 ">
              <span className="gradient-text">
                <Typewriter
                  words={[
                    " Finder",
                    " Verifier",
                    "Discover Companies",
                    "Find People by Domain",
                    "Campaigns",
                  ]}
                  loop={true}
                  cursor3
                  cursorStyle="|"
                  typeSpeed={70}
                  deleteSpeed={50}
                  delaySpeed={1000}
                />
              </span>
              <span style={{ color: "#bc20b5" }}>
                <Cursor />
              </span>
            </h1>
            <img src={signInImg} className="px-lg-5" alt="image not found" />
          </div>
            {/* ---Part2--- */}
            <div className="align-items-center col-lg-6 d-flex loginRightBg pt-lg-4">
              <div className="justify-content-center px-md-5 py-5 row w-100">
                <div className=" col-10">
                  <h1 className="loginTitle text-center my-3"> Reset Password</h1>
                  <div>
                    <form onSubmit={formik.handleSubmit} className="">
                      <div className="form-label mb-lg-4 mb-3">
                        <div className="input-group">
                          <input
                            type="email"
                            id="email"
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            className="form-control py-3"
                            placeholder="Email address"
                          />
                        </div>

                        {formik.touched.email && formik.errors.email ? (
                          <div className="errormsg">{formik.errors.email}</div>
                        ) : null}
                      </div>

                      <div className="form-label mb-lg-4 mt-3 mb-3">
                        <div className="input-group ">
                          <input
                            type="password"
                            id="password"
                            name="password"
                            title="Password must contain atleast 4 characters and atleast 1 number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            className="form-control  py-3"
                            placeholder="New Password"
                          />
                        </div>
                        {formik.touched.password && formik.errors.password ? (
                          <div className="errormsg">{formik.errors.password}</div>
                        ) : null}
                      </div>

                      <div className="input-group">
                        <input
                          type={hidePassword ? "password" : "text"}
                          id="confirm"
                          name="confirm"
                          title="Password must contain atleast 4 characters and atleast 1 number"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.confirm}
                          className="form-control password py-3"
                          placeholder="Confirm Password"
                        />
                        <span
                          className="input-group-text login_icon"
                          onClick={togglePasswordVisibility}
                        >
                          {hidePassword ? (
                            <AiOutlineEye />
                          ) : (
                            <AiOutlineEyeInvisible />
                          )}
                        </span>
                      </div>
                      {formik.touched.confirm && formik.errors.confirm ? (
                        <div className="errormsg">{formik.errors.confirm}</div>
                      ) : null}

                      <div >
                        <button
                          type="submit"
                          className="btn btn-disco rounded-2 w-100 py-3 mb-3 mt-4"
                          disabled={loading}
                        >
                          {loading ? <Loader /> : <>Reset Password</>}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
}

export default Password;
