import React from 'react'

const Company = () => {
  return (
    <div className='heroTopPadding companyDiv bgcover bgcenter pb-5 px-5'>
        <h1 className='letterspace text-white fw-normal fs-1 lh-base mt-3 ms-xl-5'>Discover Your Ideal Audience </h1>
        <h1 className='letterspace text-white fw-normal fs-1 lh-base mb-0 ms-xl-5'>Streamline Your Outreach with Discovemail</h1>
        <p className='fs-6 fw-normal mt-3 col-lg-8 col-12 ms-xl-5' >Our email validator removes bounces, spam complaints, spam traps, and bots from your list, enhancing deliverability and boosting click rates. Reconnect with subscribers effectively.</p>
        <a href="/signup" className="btn btn-primary btn-lg serviceBtn custom-gradient col-sm-4 col-12 mt-3 bg-transparent ms-xl-5" role="button" aria-disabled="true" tabindex="-1">Get Started</a>
        <p className='text-secondary font14 mt-4 mb-4 ms-xl-5'>Gain 100 monthly credits with a complimentary account. </p>
    </div>
  )
}

export default Company