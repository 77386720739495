import React, { useEffect, useState } from "react";
import Video from "../components/Video";
import HowItWorks from "./Components/HowItWorks";
import NewPrice from "./NewPrice";
import FeedbackCard from "./FeedbackCard";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import HeroBanner from "./Components/hero-banner";
import AboutChromeExtenstion from "./ChromeExtenstion";
import ChromExtenstionBanner from "./Components/ChromeExtensionBanner/ChromExtenstionBanner";
import ColdEmail from "./Components/ColdEmail/ColdEmail";
import ContactUsSection from "../components/contact/ContactUsSection";
import NewHomeHero from "../home_revamp/NewHomeHero";
import EmailMarketing from "../home_revamp/EmailMarketing";
import Faq from "../home_revamp/Faq";
import ToolkitPage from "../home_revamp/ToolkitPage";
import NewPricePlan from "../home_revamp/NewPricePlan";
import Elevate from "../home_revamp/Elevate";
import NewFooter from "../home_revamp/NewFooter";
import Testimonials from "../home_revamp/Testimonials";


function Body_1() {
  const [params, setParams] = useState();
  let navigate = useNavigate();

  useEffect(() => {
    const data = window.location.search;
    const parm = data?.split("=")[1];
    setParams(data?.split("=")[1]);
    const user = JSON.parse(localStorage.getItem("find_email"));
    if (user) {
      if (parm === "Price") {
        navigate("/dashboard/price1");
      } else {
        navigate("/dashboard");
      }
    } else {
      if (parm === "home") {
        document.getElementById("homeControl").click();
      } else if (parm === "Price") {
        document.getElementById("priceControl").click();
      } else if (parm === "Work") {
        document.getElementById("workControl").click();
      } else if (parm === "Contact") {
        document.getElementById("contactControl").click();
      }
    }
  }, [navigate]);
  
  // const canonicalUrl = `https://www.discovemail.com?query=${params}`;
  return (
    <>
      <Helmet>
        <title>Discovemail - Find Free Verified Emails To Connect With People.</title>
        <link rel="canonical" href='https://www.discovemail.com'></link>
        <meta
          name="description"
          content="Find Free Verified Email Addresses To Connect With People Ensuring Accurate And Valid Contact Information And Email Addresses For Successful Communication."
        />
        </Helmet>

      {/* ...........NEW COMPONENS ADDED HERE ........... */}
      <NewHomeHero />
      <EmailMarketing />
      <ToolkitPage />
      <Elevate />
      <NewPricePlan />
      <Testimonials />
      <Faq />
      <NewFooter />
      
      






      {/* ..........THESE ARE THE OLD COMPONENTS..........  */}
      {/* <HeroBanner />
      <HowItWorks />
      <ColdEmail />
      <FeedbackCard />
      <Footer />
       */}
      


      {/* .......BELOW TWO COMPONENTS WERE DISCARDED...... */}
      {/* <AboutChromeExtenstion /> */}
      {/* <ChromExtenstionBanner /> */}
    </>
  );
}

export default Body_1;
