import React from "react";
// import { useDispatch } from "react-redux";
// import { userAction } from "../redux/userDetails/userActions";
import "./App.css";
import AppRoutes from "./AppRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
    <div className="">
      <AppRoutes />
      <ToastContainer autoClose={1500} />
    </div>
  );
}

export default App;
