import React, { useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../loader/Loader";
import Navbar from "new_home/Navbar_1";
import requestInstance from "../../services/request";
import { API_ENDPOINT } from "../../services/Api";
import { setCurrentUser } from "../../utils/utils";
import { Typewriter, Cursor } from "react-simple-typewriter";
import signInImg from "../../assets/revampHomeassets/signInIng.svg";
import "../sigin/NewSignIn.css";

// Form Validation
const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "Please enter the email address";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  return errors;
};



function EmailLink() {
  const navigate = useNavigate();
  const [values, setValues] = useState("");
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleClickSignIn = () => {
    navigate("/signin");
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate,
    onSubmit: (values) => {
      setLoading(true);
      setButtonDisabled(true);
      Change(values);
    },
  });

  const Change = (values) => {
    let data = JSON.stringify({
      email: values.email,
    });

    // API for Password Reset
    requestInstance
      .post(API_ENDPOINT.SEND_RESET_LINK, data)
      .then((res) => {
        if (res.status == 201) {
          setCurrentUser(res.data.access);
          toast.success(res.data.message);
          navigate("/signin");
          localStorage.setItem("ID", res.data.data.email);

        }
      })
      .catch((err) => {
        setLoading(false);
        setButtonDisabled(false);
      });
  };

  const tabHead = "Discovemail-forget"

  return (
    <>
      <Helmet>
        <title>{tabHead}</title>
      </Helmet>
      <section className="reset_main loginPageBg mx-0 px-0">
      <Navbar />
          <div className="row pt-4 height100vh px-0 mx-0">
          <div className="col-lg-6 d-flex flex-column gap-1 pt-5 px-md-5">
            <h1 className="px-lg-5 mt-md-5 fw600 fs50 lh-1 hexbabfc2">
              Your all-in-one lead generation platform{" "}
            </h1>
            <h1 className="px-lg-5 fw600 fs50 ">
              <span className="gradient-text">
                <Typewriter
                  words={[
                    " Finder",
                    " Verifier",
                    "Discover Companies",
                    "Find People by Domain",
                    "Campaigns",
                  ]}
                  loop={true}
                  cursor3
                  cursorStyle="|"
                  typeSpeed={70}
                  deleteSpeed={50}
                  delaySpeed={1000}
                />
              </span>
              <span style={{ color: "#bc20b5" }}>
                <Cursor />
              </span>
            </h1>
            <img src={signInImg} className="px-lg-5" alt="image not found" />
          </div>
            <div className="align-items-center col-lg-6 d-flex loginRightBg pt-lg-4">
              <div className="justify-content-center px-md-5 py-5 row w-100">
                <div className="col-lg-10 col-11">
                  <h1 className="loginTitle fs2 my-3">Reset your password</h1>
                  <div className="">
                    <form onSubmit={formik.handleSubmit} className="">
                      <label htmlFor="email">Email</label>
                      <div className="form-label mb-lg-4 mb-3">
                        <div className="input-group">
                          <input
                            type="email"
                            id="email"
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            className="form-control loginPlaceholder py11"
                            placeholder="Example@email.com"
                          />
                        </div>
                        {formik.touched.email && formik.errors.email ? (
                          <div className="errormsg">{formik.errors.email}</div>
                        ) : null}
                      </div>
                      <div>
                        <button
                          className="btn btn-disco fs-6 w-100 py12 mb-3 rounded-2"
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? <Loader /> : <>Send Reset Link</>}
                        </button>
                      </div>
                      
                      <p className="text-center signUpTxt">
                      Already have an account ?
                      <span
                        onClick={handleClickSignIn}
                        role="button"
                        className="gradient-text login_navigation ms-2"
                      >
                        Login
                      </span>
                    </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </section>
    </>
  );
}

export default EmailLink;
