import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Sidebar.css';
import requestInstance from '../../services/request';
import { API_ENDPOINT } from '../../services/Api';
import { leadListData } from '../../redux/slices/lead/LeadSlice';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from '../../hooks/useDebounce';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { HiOutlineChevronDown, HiOutlineChevronUp } from "react-icons/hi2";


const Sidebar = () => {
    const [showAll, setShowAll] = useState(false);
    const location = useLocation();
    const currentPath = location.pathname.split('/')[2];
    const initialPath = location.pathname.split('/')[1];
    const [path, setPath] = useState(initialPath);
    const [searchLeadList, setSearchLeadList] = useState('');
    const [startDate, setStartDate] = useState(null);  // Keep as Date object
    const [endDate, setEndDate] = useState(null);      // Keep as Date object
    const [apiEndDateString, setApiEndDateString] = useState('');
    const dispatch = useDispatch();
    const leadList = useSelector((state) => state?.lead?.leadListData);
    const debouncedSearchLeads = useDebounce(searchLeadList, 1000);

    const getCurrentDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Add 1 because months are zero-indexed
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const maxDate = getCurrentDate();

    // Format the date to the required string format for API calls
    const formatDateForAPI = (date) => {
        if (!date) return '';
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(date.getDate()).padStart(2, '0');
        return `${day}-${month}-${year}`;  // yyyy-MM-dd format
    };

    // API call for leads list data
    const leadsListData = () => {
        const formattedStartDate = formatDateForAPI(startDate);
        const formattedEndDate = formatDateForAPI(endDate);
        requestInstance
            .get(API_ENDPOINT.LEAD_NAME_LIST + `?search=${searchLeadList}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`)
            .then((res) => {
                dispatch(leadListData(res.data));
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const toggleShowAll = () =>{
        setShowAll(!showAll);
    }
    
    useEffect(() => {
        leadsListData();
        setPath(currentPath);
    }, [currentPath, startDate, endDate, debouncedSearchLeads]);


  return (
    <div className="vl px-1">
      <div className="listItems">
        <div
          className="accordion text-start"
          id="accordionPanelsStayOpenExample"
        >
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className="accordion-button fw-bold"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseOne"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseOne"
              >
                List ({leadList?.data.length})
              </button>
            </h2>
            <div
              id="panelsStayOpen-collapseOne"
              className="accordion-collapse collapse show"
            >
              {/* ADDING SEARCH OPTIONS HERE ........ */}
              <div className="leadsListSearchDiv">
                <div className="searchDivs">
                  <label htmlFor="#startDate">Start Date:</label>
                  <DatePicker
                                        className="form-control"
                                        dateFormat="dd/MM/yyyy"
                                        selected={startDate}
                                        maxDate={new Date(maxDate)}
                                        onChange={(date) => setStartDate(date)}  // Set as Date object
                                    />
                </div>
                <div className="searchDivs">
                  <label htmlFor="#endDate">End Date:</label>
                  <DatePicker
                                        className="form-control"
                                        dateFormat="dd/MM/yyyy"
                                        selected={endDate}
                                        maxDate={new Date(maxDate)}
                                        onChange={(date) => setEndDate(date)}  // Set as Date object
                                    />
                </div>
                <div className="searchDivs">
                  <label htmlFor="#searchLeadList">Search List Name:</label>
                  <input
                    className="form-control"
                    type="search"
                    name=""
                    id="searchLeadList"
                    onChange={(e) => setSearchLeadList(e.target.value)}
                  />
                </div>
              </div>

              <div
                className={
                  path === "all-leads"
                    ? "accordion-body cursorPointer activeLink"
                    : "accordion-body"
                }
              >
                <Link to={"/leads/all-leads"}>
                  <div className="d-flex justify-content-between font13px fw-semibold">
                    <span>All Leads</span>
                    <span>{leadList?.total_count}</span>
                  </div>
                </Link>
              </div>
              {showAll
                ? leadList?.data?.map((lead, index) => (
                    <div
                      key={index}
                      className={
                        path.replace("-", " ") === lead?.leads_name
                          ? "accordion-body cursorPointer activeLink"
                          : "accordion-body"
                      }
                    >
                      <Link
                        to={"/leads/" + (lead?.leads_name).replace(/ /g, "-")}
                      >
                        <div className="d-flex justify-content-between font13px fw-semibold">
                          <span>{lead?.leads_name}</span>
                          <span>{lead?.lead_count}</span>
                        </div>
                      </Link>
                    </div>
                  ))
                : leadList?.data?.slice(0, 3).map((lead, index) => (
                    <div
                      key={index}
                      className={
                        path.replace("-", " ") === lead?.leads_name
                          ? "accordion-body cursorPointer activeLink"
                          : "accordion-body"
                      }
                    >
                      <Link
                        to={"/leads/" + (lead?.leads_name).replace(/ /g, "-")}
                      >
                        <div className="d-flex justify-content-between font13px fw-semibold">
                          <span>{lead?.leads_name}</span>
                          <span>{lead?.lead_count}</span>
                        </div>
                      </Link>
                    </div>
                  ))}
            </div>
          </div>
          <div>
            <small
              className="text-secondary fw-bold cursorPointer px-3"
              onClick={toggleShowAll}
            >
              {showAll ? (
                <div className="d-flex justify-content-between">
                  <span className="px-3">Show Less</span>{" "}
                  <span className="px-3">
                    <HiOutlineChevronUp />
                  </span>
                </div>
              ) : (
                <div className="d-flex justify-content-between">
                  <span className="px-3">View All Lists</span>{" "}
                  <span className="px-3">
                    <HiOutlineChevronDown />
                  </span>
                </div>
              )}
            </small>
            <div className="borderBottom mb-4"></div>
          </div>
          <div
            className={
              path === "import-leads"
                ? "accordion-body cursorPointer activeLink"
                : "accordion-body"
            }
          >
            <Link to={`/lead/import-leads`}>
              <div className="text-secondary fw-bold ">Import leads</div>
            </Link>
          </div>
          <div
            className={
              path === "settings-leads"
                ? "accordion-body cursorPointer activeLink"
                : "accordion-body"
            }
          >
            <Link to={`#`}>
              <div className="text-secondary fw-bold px-3 py-2 d-none">
                Settings
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;