import React from 'react';
import { visionData } from 'home_revamp/HomeData';
import VisionImg from "../assets/about/VisionImg.svg"
import VisionCard from './VisionCard';

const Vision = () => {
  return (
    <div className='visionDiv py-5'>
    <div className="container">
    <div className=' text-center'>
            <h1 className='blueGradient mb-3' >Our Vision</h1>
            <p className='fs-5' >Discovemail: Born from our frustrations with email deliverability and unreliable data, we built a platform that priorities accuracy, speed, affordability, and exceptional support.</p>
        </div>

        <div className="row pt-4">
            <div className="col-lg-6 d-flex justify-content-center">
                <img className='img-fluid' src={VisionImg} alt="img not found" />
            </div>
            <div className="col-lg-6 ps-lg-5">
                <VisionCard visionData ={visionData} />
            </div>
        </div>
    </div>
        
    </div>
  )
}

export default Vision