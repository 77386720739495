import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { heroImgData, featuresData } from "./NewUiData";
import FeatureCard from "./FeatureCard";
import ExportSheet from "./ExportSheet";

const WelcomePage = () => {
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    arrows: false
  };
  return (
    <div className="px-5 mt-1 bg-white paddingTop8rem">
      <div className="sliderContainerLeft py-4 ">
        <div className="row w-100">
          <div className=" col-md-9 col-lg-9 col-sm-12 order-2 order-md-1">
            <Slider {...settings}>
              {heroImgData.map((ele) => (
                <img src={ele.pic} className="img-fluid" alt={ele.pic} />
              ))}
            </Slider>
          </div>
          <div className=" col-md-2 col-lg-3 col-sm-12 order-1 order-md-2 bg-secondary-subtle p-2 rounded-1">
            <div >
              <ExportSheet />
            </div>
          </div>
        </div>
      </div>

      <div className="welcomeFooter">
        <h3>Get started with our</h3>
        <div>
          <FeatureCard featuresData={featuresData} />
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
