import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import { MdClear } from "react-icons/md";
import { FaRegCalendarAlt } from "react-icons/fa";
import "./CommonDateRangePicker.css";

export default function DateTab(props) {
  const location = useLocation();
  const tentureDate = new Date(props?.minDate);
  tentureDate.setDate(tentureDate?.getDate() + 1);
  const [value, setValue] = useState(
    props?.dateFrom ? [props?.dateFrom, props?.dateTo] : [null, null]
  );

  const [dateValue, setDatevalue] = useState(
    props?.minDate ? [ tentureDate,tentureDate] : [null, null]
  );
  useEffect(() => {
    if (location.pathname && props?.dateFrom && props?.dateTo) {
      setValue([new Date(props?.dateFrom), new Date(props?.dateTo)]);
    }
  }, [location.pathname]);
  const handleDateChange = (newValue) => {
    if (newValue === null) {
      setValue([null, null]);
      setDatevalue([null, null]);
      props?.onSelectDateRange(0);
    } else {
      setValue(newValue);
      setDatevalue(props?.minDate ? newValue : [null,null]);
      props?.onSelectDateRange(newValue);
    }
  };
  return (
    <div>
      <div>
        <DateRangePicker
          onChange={handleDateChange}
          value={value}
          format="d/MM/yy"
          className={`datePickerStyle ${!value?.[0] && "inActiveDatePickerStyle"}`}
          monthPlaceholder="MM"
          dayPlaceholder="DD"
          yearPlaceholder="YYYY"
          minDate={dateValue[0]}
          maxDate={props?.maxDate}
          showLeadingZeros={false}
          clearIcon={value[0] && value[1] ? <MdClear /> : null}
          calendarIcon={<FaRegCalendarAlt />}
        />    
      </div>
    </div>
  );
}
