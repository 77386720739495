import React, { useEffect, useState } from "react";
import PageNavbar from "../sigin/PageNavbar";
import "./selectPlan.css";
import { useFormik } from "formik";
import Loader from "../loader/Loader";
import requestInstance from "../../services/request";
import { API_ENDPOINT } from "../../services/Api";
import { formatMobileNumber } from "../../utils/utils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MdEmail } from "react-icons/md";
import { HiLocationMarker } from "react-icons/hi";
import { useLocation } from "react-router-dom";
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import Navbar from "../../new_home/Navbar_1";
import ContactUsSection from "../../components/contact/ContactUsSection";
import Footer from "../../new_home/Footer";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const validate = (values) => {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = "Please Enter your Name";
  } else if (values.firstName[0].includes(" ")) {
    errors.firstName = "Must not include space at start";
  } else if (/^(?=.*?[0-9])/i.test(values.firstName)) {
    errors.firstName = "Must not contain number";
  } else if (/(?=.*?[#?!@$%^&*-])/i.test(values.firstName)) {
    errors.firstName = "Must not contain special characters";
  }
  if (!values.email) {
    errors.email = "Please enter email address";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = "Please enter your Phone No";
  } else if (values.phoneNumber.length < 10) {
    errors.phoneNumber = "Must be of 10 digits";
  }
  if (!values.message) {
    errors.message = "Please write a message";
  } else if (values.message[0].includes(" ")) {
    errors.message = "Must not include space at start";
  }

  return errors;
};

function NewSelectPlan() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      message: "",
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      setButtonDisabled(true);
      handleContactUs(values, { resetForm });
    },
  });

  const handleSubmit = () => {
    // Successful form submission logic
    if (plan == "Free") {
      setLoading(false);
      setButtonDisabled(false);
      navigate("/signup");
    } else {
      setTimeout(() => {
        setLoading(false);
        setButtonDisabled(false);
        navigate("/");
      }, 2000);
    }
  };

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  // const user = JSON.parse(localStorage.getItem("find_email"));
  // useEffect(() => {
  //   const data = window.location.search;
  //   const parm = data?.split("?")[1];
  //   if ({user} && parm == "contactus") {
  //     document.getElementById("plan").style.display = "none";
  //     document.getElementById("workControl").style.display = "none";
  //     document.getElementById("priceControl").style.display = "none";
  //     document.getElementById("contactControl").style.display = "none";
  //     document.getElementById("protectedprice").style.display = "block";
  //   }
  // if (user) {
  //   document.getElementById("contact").style.display = "none";
  // }
  // else{
  //    document.getElementById("pagecontact").style.display = 'none';
  //  }
  // });

  let query = useQuery();
  console.log(query.get("plan"))
  let plan = query.get("plan");

  const [formData, setformData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    selectPlan: plan,
    query: "",
  });

  const handleFormDataChange = (prop) => (event) => {
    setformData({ ...formData, [prop]: event.target.value });
  };

  const handleContactUs = (values, { resetForm }) => {
    let data = JSON.stringify({
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      phone_number: values.phoneNumber,
      message: values.message,
      data_type: "contact_message",
    });
    requestInstance
      .post(API_ENDPOINT.CONTACT_API, data)
      .then((res) => {
        if (res.status == 200) {
          toast.success(res.data.status);
          setLoading(false);
          resetForm();
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>Discovemail-Enquiry | Contact Us For Checking Email Addresses.</title>
        <meta
          name="description"
          content="Get In Touch For Checking Email Addresses With Discovemail's Contact Page. Connect, Communicate, And Verify Email Addresses Seamlessly. Reach Out With Confidence On Discovemail.com."
        />
      </Helmet>
      <PageNavbar />
      <section className="contactSection container-fluid px-0 mx-0 fontInter">
        <div className="row px-0 mx-0 h-100">
          <div className="col-12 col-md-6 col-lg-6">
            <div className="row justify-content-center py-5">
              <div className="col-lg-10 col-11">
                <h1 className="loginTitle mb-5">Contact Us</h1>
                <div className="">
                  <form onSubmit={formik.handleSubmit} className="">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-label mb-lg-4 mb-3">
                          <label className="mb-lg-3 mb-2">Firstname <span className="text-danger">*</span></label>
                          <div className="input-group">
                            <input
                              type="text"
                              id="firstName"
                              name="firstName"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.firstName}
                              className="form-control py-3"
                              placeholder="Enter your Firstname"
                            />
                          </div>
                          {formik.touched.firstName && formik.errors.firstName ? (
                            <div className="errormsg">
                              {formik.errors.firstName}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-label mb-lg-4 mb-3">
                          <label className="mb-lg-3 mb-2">Lastname</label>
                          <div className="input-group">
                            <input
                              type="text"
                              id="lastName"
                              name="lastName"
                              onChange={formik.handleChange}
                              value={formik.values.lastName}
                              className="form-control py-3"
                              placeholder="Enter your Lastname"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-label mb-lg-4 mb-3">
                          <label className="mb-lg-3 mb-2">Email <span className="text-danger">*</span></label>
                          <div className="input-group">
                            <input
                              type="text"
                              id="email"
                              name="email"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.email}
                              className="form-control py-3"
                              placeholder="Enter your Email"
                            />
                          </div>
                          {formik.touched.email && formik.errors.email ? (
                            <div className="errormsg">{formik.errors.email}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-label">
                          <label className="mb-lg-3 mb-2">Phone Number <span className="text-danger">*</span></label>
                          <div className="input-group w-100">
                            <PhoneInput
                              inputProps={{
                                name: "phoneNumber",
                                required: true,
                                placeholder: "Enter your Phone Number",
                                className: "form-control py-3",
                              }}
                              country={"in"}
                              name="phoneNumber"
                              enableSearch={true}
                              autoFormat={false}
                              value={formik.values.phoneNumber}
                              onChange={(value, data, event, formattedValue) => formik.setFieldValue('phoneNumber',formatMobileNumber(formattedValue, data))}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                          {formik.touched.phoneNumber &&
                            formik.errors.phoneNumber ? (
                            <div className="errormsg">
                              {formik.errors.phoneNumber}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="form-label mb-lg-4 mb-3">
                      <label className="mb-lg-3 mb-2">Message <span className="text-danger">*</span></label>
                      <div className="input-group">
                        <textarea
                          type="text"
                          className="form-control"
                          id="message"
                          name="message"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.message}
                        ></textarea>
                      </div>
                      {formik.touched.message && formik.errors.message ? (
                        <div className="errormsg">
                          {formik.errors.message}
                        </div>
                      ) : null}
                    </div>

                    <div>
                      <button
                        className="btn btn-primary w-100 py-3 border-0"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? <Loader /> : <>Submit</>}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 d-none loginInfoSection d-md-block d-lg-block">
            <div className="row justify-content-center align-items-center h-100">
              <div className="col-10 ">
                <div>
                  <h1 className="loginTitle mb-5 pt-4">Contact Information</h1>
                  <p className="loginInfoText mb-4">Fill up the form and our team will get back to you within 24
                    hours.</p>
                  <div className="d-flex align-items-center my-2 fs-5 mb-4">
                    <HiLocationMarker className="mx-1 fs-4 me-3" />
                    <p className="loginInfoText mb-0 w-75">
                      #265, 2nd Floor, 6th Main 4th Cross, 2nd Stage, BTM Layout,
                      Bengaluru 560076
                    </p>
                  </div>
                  <div className="d-flex align-items-center text-md-start text-sm-center">
                    <MdEmail className="contact_email_icon fs-5 me-3 ms-2" />
                    <p className="loginInfoText mb-0"> contact@discovemail.com</p>
                  </div>
                </div>
                <div className="text-center">
                  <img src="./pic/contact_img.png" alt="img not found" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ContactUsSection />
      <Footer />
    </>
  );
}

export default NewSelectPlan;
