import React, { useState } from "react";
import CircleArrow from "../assets/about/CircleArrow.svg";
import "../home_revamp/Homeravamp.css";

const HorizontalAccordion = (props) => {
  console.log(props?.accordionData);
  const [activeIndex, setActiveIndex] = useState(0);

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };
  return (
    <div className="d-flex justify-content-center">
      <div className="accordion-horizontal d-lg-flex d-none p-5 gap-4">
        {props?.accordionData?.map((item, index) => (
          <div
            key={index}
            className={`accordion1  flex-column justify-content-between p-5 ${
              activeIndex === index ? "activenow" : ""
            }`}
            onClick={() => toggleAccordion(index)}
            style={{ backgroundColor: item.color }}
          >
            <div className="accordion-header1">
              <h5 className="fs-4">{item.heading}</h5>
              <div
                className={`accordion-content1 fw-normal fs-6 ${
                  activeIndex === index ? "d-block" : "d-none"
                }`}
              >
                <p>{item.text1}</p>
              </div>
            </div>

            <div
              className={`accordion-button1 ${
                activeIndex !== index ? "d-block" : "d-none"
              }`}
            >
              <img src={CircleArrow} alt="image not found" />
            </div>
          </div>
        ))}
      </div>

      <div className=" d-lg-none d-block row">
        {props?.accordionData?.map((item, index) => (
          <div
            key={index}
            className="col-lg-6 p-3 mb-3 rounded-2"
            style={{ backgroundColor: item.color }}
          >
            <div>
              <h5 className="fs-4">{item.heading}</h5>
              <div className="fw-normal fs-6">
                <p>{item.text1}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HorizontalAccordion;
