import React from "react";
import { IoCheckmark } from "react-icons/io5";

const ServiceCards = ({ toolkitdata }) => {

  return (
    <div className="row">
      {toolkitdata?.map((item, index) => (
        <div className="col-12 col-md-6 col-lg-4 mb-4" key={index}>
          <div className="card h-100 text-start pb-2 border-0">
            <div className="card-body d-flex flex-column">
              <div
                className="d-flex justify-content-center w-100 mb-3 py-4 serviceImgDiv rounded-2"
              >
                <img
                  src={item.imgSrc}
                  alt="Card Image"
                  className="img-fluid mb-2"
                  style={{ maxWidth: "50%" }}
                />
              </div>
              <h5 className="card-title text-start pb-2 lh-base">
                {item.heading}
              </h5>
              <p className="card-text text-secondary pb-2 lh-base">
                <IoCheckmark /> {item.text1}
              </p>
              <p className="card-text text-secondary pb-2 lh-base">
                <IoCheckmark /> {item.text2}
              </p>
              {/* <div className="row "></div>  */}
                <a
                  href={item.btnPath}
                  className="btn btn-primary btn-lg serviceBtn custom-gradient col-xl-6"
                  role="button"
                  aria-disabled="true"
                  tabIndex="-1"
                >
                  Get Started
                </a>
              
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ServiceCards;
