

import EmailVerifyPic from '../assets/revampHomeassets/eVerify.svg';
import DomainVerifyPic from '../assets/revampHomeassets/dVerify.svg';
import FindContactPic from '../assets/revampHomeassets/findContact.svg';
import CampaignImg from '../assets/revampHomeassets/CampaignImg.svg';
import DiscoverImg from '../assets/revampHomeassets/DiscoverImg.svg';
import searchcompImg from '../assets/revampHomeassets/searchcompImg.svg';
import vision_pic1 from '../assets/about/vision_pic1.svg';
import vision_pic2 from '../assets/about/vision_pic2.svg';
import vision_pic3 from '../assets/about/vision_pic3.svg';
import Hvs from '../assets/about/Hvs.svg';
import Nk from '../assets/about/Nk.svg';

export const toolkitData =[
    {
        imgSrc: EmailVerifyPic,
        heading: "Email verification that's easy-to-use",
        text1: "Effortless List Uploading: Directly upload your email lists from your computer.",
        text2: "Quick Identification of Invalid Addresses: Easily spot and remove outdated or incorrect emails",
        btnPath: "/email-verifier",
    },
    {
        imgSrc: DomainVerifyPic,
        heading: "Verify Your Domain With Confidence",
        text1: "Instant Search: Locate publicly accessible email addresses in seconds.",
        text2: "Advanced Filters: Refine your search by industry and reliability scores.",
        btnPath: "trailarea/domain-verifier",
    },
    {
        imgSrc: FindContactPic,
        heading: "Find Contacts by Name & Company",
        text1: "Comprehensive Data: Access email formats, web addresses, and verification status.",
        text2: "Streamlined Outreach: Save time and effort with accurate contact information.",
        btnPath: "trailarea/email-from-name-and-domain",
    },
    {
        imgSrc: searchcompImg,
        heading: "Search the company of your interest",
        text1: "Unlock valuable insights about companies of interest with our powerful tool.",
        text2: "Access comprehensive data to make informed decisions and drive business growth.",
        btnPath: "/search-company",
    },
    {
        imgSrc: CampaignImg,
        heading: "Run Campaigns with Us",
        text1: "Boost your response rates with DiscoveMail for targeted campaigns.",
        text2: "Unlock the potential of DiscoveMail Campaigns to optimize your efforts, increasing chances of eliciting higher responses.",
        btnPath: "/signup",
    },
    {
        imgSrc: DiscoverImg,
        heading: "Discover companies suiting your choice",
        text1: "Find organizations based on their names,headquarter location, Industry type and company strength.",
        text2: "Discovering vast arrays of companies meeting your criteria in your desired location.",
        btnPath: "/signup",
    },
]


export const visionData =[
 {
    imgSrc: vision_pic1,
        heading: "Customer Success",
        text1: "Empowering customers to achieve their goals and realize successful outcomes through our dedicated support and tailored solutions."
 },
 {
    imgSrc: vision_pic2,
        heading: "Inclusive Growth",
        text1: "Inclusivity is a cornerstone of our company's growth and scale, guiding our decisions and actions at every step.",
 },
 {
    imgSrc: vision_pic3,
        heading: "Care about our users' experience",
        text1: "We prioritize the experience of our users and ensure that any issues are promptly addressed and resolved.",
 },
]

export const teamData = [
    {
            imgSrc: Hvs,
            name: "Harsh Vardhan Singh",
            designation:"CEO | Founder",
            text1: "A visionary leader and the Founder of Vikgol, where he combines his technical expertise with a passion for innovation. With a B.Tech degree, Harsh has always seen programming and DevOps as an art form, where each line of code reflects creativity and precision."
     },
     {
        imgSrc: Nk,
        name: "Naveen Kumar",
        designation:"Advisor",
        text1: "A dynamic leader with a B.Tech degree from IIT Bombay, Naveen brings his technical expertise, innovation, and forward-thinking approach to Discovemail. He drives success through his strategic vision and commitment to building a user-centric platform."
 },
]

export const accordionData=[
    {
            heading: "Our Story: From Vision to Reality",
            text1: "Founded in 2018 by visionary leader Harsh Vardhan Singh, Discovemail emerged from a simple yet powerful idea: to streamline email outreach and empower businesses to connect with their target audience effectively. What began as a platform to find email addresses quickly evolved into a comprehensive solution for email marketing needs.",
            color:"#e3f2fd"
     },
     {
            heading: "A Commitment to Innovation",
            text1: "Driven by a passion for innovation and a deep understanding of the challenges faced by businesses, the Discovemail team has continuously worked to enhance the platform's capabilities. From advanced search features to robust email verification tools, Discovemail has consistently delivered value to its users.",
            color:"#d3eafc"
     },
     {      
            heading: "Empowering Businesses Worldwide",
            text1: "Discovemail has empowered businesses of all sizes to connect with potential customers, partners, and collaborators. With its user-friendly interface and powerful features, the platform has become a trusted tool for professionals seeking to optimize their email outreach efforts.",
            color:"#c1e1fc"
     },
     {
            heading: "The Future of Discovemail",
            text1: "As we look ahead, Discovemail remains committed to innovation and excellence. We will continue to invest in research and development to bring new features and functionalities to our users. Our goal is to be the leading provider of email outreach solutions, empowering businesses to achieve their goals and drive growth.",
            color:"#aed9fa"
     },
]