import React, { useEffect, useState } from 'react'
import '../allCampaigns/AllCampaigns.css';
import { useNavigate } from 'react-router-dom';
import { API_ENDPOINT } from '../../../services/Api';
import requestInstance from '../../../services/request';
import { toast } from 'react-toastify';
import Spinner from "../../loader/SimpleLoader";
import { useDispatch, useSelector } from 'react-redux';
import { campaignName, campaignStatus } from '../../../redux/slices/campaign/CampaignSlice';

export default function Archive({ handleCampaignId, handleCampaignIndicator }) {
  const [campaign, setCampaign] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const email = useSelector((state) => state?.gAuth?.signValue?.email);



  const campaignArchiveList = () => {
    setIsLoading(true);
    requestInstance.get(API_ENDPOINT.CAMPAIGN_ARCHIVE_LIST)
      .then((res) => {
        setCampaign(res?.data?.data);
        setIsLoading(false)        
      })
      .catch((err) => {
        setIsLoading(false)
        console.log(err);
      });
  };

  const handleReviewData = (res) => {
    console.log(res,'resid...')
    setIsLoading(true);
    const newBody = {
      'user_campaign_create_id': res?.campaign_create_id,
    }
    requestInstance
      .post(API_ENDPOINT.CAMPAIGN_REVIEW_DATA, newBody)
      .then((response) => {
        dispatch(campaignName(res?.campaign_name));
        dispatch(campaignStatus(response?.data?.data[0]));
        navigate(`/campaign/summary/${res?.id}`)
        setIsLoading(false)
        console.log(response, "res...")
      })
      .catch((error) => {
        console.error('Error sending data:', error);
        setIsLoading(false)
      });
  };

  const handleCampaignArchive = (name) => {
    const newBody = {
      'campaign_name': name,
    }
    requestInstance
      .post(API_ENDPOINT.CAMPAIGN_UNARCHIVE, newBody)
      .then((res) => {
        campaignArchiveList();
        toast.success(res.data.message);
      })
      .catch((error) => {
        console.error('Error sending data:', error);
      });
  };

  const calculateRelativeTime = (dateStr) => {
    const currentDate = new Date();
    const inputDate = new Date(dateStr);

    const timeDifferenceInSeconds = Math.floor((currentDate - inputDate) / 1000);

    if (timeDifferenceInSeconds < 60) {
      return 'Just now';
    } else if (timeDifferenceInSeconds < 3600) {
      const minutes = Math.floor(timeDifferenceInSeconds / 60);
      return `About ${minutes} ${minutes === 1 ? 'minute' : 'minutes'} ago`;
    } else if (timeDifferenceInSeconds < 86400) {
      const hours = Math.floor(timeDifferenceInSeconds / 3600);
      return `About ${hours} ${hours === 1 ? 'hour' : 'hours'} ago`;
    } else {
      const days = Math.floor(timeDifferenceInSeconds / 86400);
      return `${days} ${days === 1 ? 'day' : 'days'} ago`;
    }
  };

  const colorPallet = {
    "Draft": 'text-secondary',
    "In-Progress": "text-warning",
    "Completed": "text-success",
    "Scheduled": "text-info"
  }

  useEffect(() => {
    campaignArchiveList();
  }, [])


  return (
    <>
      {isLoading ? <Spinner /> :
        <div className='col-12 minHeight84vh'>
          <div className='d-flex align-items-center justify-content-between p-4 flex-column flex-md-row gap-3 gap-md-0'>
            <h5 className='text-dark fw-bold'>Campaigns Archive</h5>
          </div>

          <div className='row'>
            <div className='col-12 overflow-auto p-4'>
              <table className="campaignTable table">
                {campaign?.length > 0 ? <thead>
                  <tr>
                    <th scope="col" className='px-3'>NAME </th>
                    <th scope="col" className='px-3'>STATUS</th>
                    <th scope="col" className='px-3'>CREATED</th>
                    {/* <th scope="col" className='px-3'>ACTION</th> */}
                  </tr>
                </thead> : <div className='d-flex justify-content-center align-items-center'>There are no records to display</div>}
                <tbody>
                  {
                    campaign?.length > 0 &&
                    campaign.map((res, index) => (
                      <tr className='cursorPointer bgBlueHover' onClick={() => handleReviewData(res)}>
                        <td className='px-3 py-4'>
                          <div className=''>
                            <p className='campaignTitle mb-2'>{res?.campaign_name}</p>
                            <p className='campaignRecipients m-0'>{res?.lead_count} recipients {email}</p>
                          </div>
                        </td>
                        <td className='px-3 py-4'>
                          <div className={colorPallet[res?.campaign_summary?.campaign_status]}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-circle mx-2" viewBox="0 0 16 16">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                              <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                            </svg> Archived
                          </div>
                        </td>
                        <td className='px-3 py-4'>{calculateRelativeTime(res?.created_at)}</td>
                        
                        {/* <td className=''>
                          <button className='btn btn-danger' onClick={(e) => {
                            handleCampaignArchive(res?.campaign_name)
                          }} >UnArchive</button>
                        </td> */}
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>}
    </>
  )
}
