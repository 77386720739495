import React from 'react'
import EmailCards from './EmailCards';

const EmailMarketing = () => {
  return (
    <div>
        <div className="emailMarketingDiv container-fluid text-center py-5">
        <h1 className='blueGradient my-4 fw-normal'>Your all-in-one email marketing platform</h1>
        <p className='fs-5 lg-px-5 px-0 mx-5 text-secondary' >Discover the power of Discovemail and streamline your email marketing efforts. Our comprehensive platform offers a suite of tools to optimize your campaigns and achieve maximum results.</p>
        </div>
        <EmailCards />
    
    </div>
  )
}

export default EmailMarketing